import {Map, Record} from 'immutable';


export interface UserProfileState extends Map<string, any> {

    changePassword: any;
    changePasswordResponse: boolean;
    changePasswordRequestLoading: boolean;
    changePasswordRequestLoaded: boolean;
    changePasswordRequestFailed: boolean;

}

export const UserProfileRecordState = Record({

    changePassword: {},
    changePasswordResponse: false,
    changePasswordRequestLoading: false,
    changePasswordRequestLoaded: false,
    changePasswordRequestFailed: false,

});
