import { CommonState, commonStateRecord } from './common.state';
import * as actions from '../actions/common.action';

export const initialState: CommonState = (new commonStateRecord() as unknown) as CommonState;

export function reducer(
  state = initialState,
  { type, payload }: any
): CommonState {
  if (!type) {
    return state;
  }

  switch (type) {
    /**
     * get school detail reducer case function
     */
    case actions.ActionTypes.NOTIFICATION_COUNT:
      return Object.assign({}, state, {
        notificationCountLoading: true,
        notificationCountLoaded: false,
        notificationCountFailed: false,
      });
    case actions.ActionTypes.NOTIFICATION_COUNT_SUCCESS:
      console.log('NOTIFICATION_COUNT', payload)
      return Object.assign({}, state, {
        notificationCount: payload.data,
        notificationCountLoading: false,
        notificationCountLoaded: true,
        notificationCountFailed: false,
      });
    case actions.ActionTypes.NOTIFICATION_COUNT_FAIL:
      return Object.assign({}, state, {
        notificationCountLoading: false,
        notificationCountLoaded: false,
        notificationCountFailed: true,
      });

      case actions.ActionTypes.NOTIFICATION_LIST:
      return Object.assign({}, state, {
        notificationList: [],
        notificationListLoading: true,
        notificationListLoaded: false,
        notificationListFailed: false,
      });
    case actions.ActionTypes.NOTIFICATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        notificationList: payload.data,
        notificationListLoading: false,
        notificationListLoaded: true,
        notificationListFailed: false,
      });
    case actions.ActionTypes.NOTIFICATION_LIST_FAIL:
      return Object.assign({}, state, {
        notificationListLoading: false,
        notificationListLoaded: false,
        notificationListFailed: true,
      });
    default: {
      return state;
    }
  }
}

/**
 * export values
 */
export const notificationCount = (state: CommonState) => state.notificationCount;
export const notificationCountLoading = (state: CommonState) => state.notificationCountLoading;
export const notificationCountLoaded = (state: CommonState) => state.notificationCountLoaded;
export const notificationCountFailed = (state: CommonState) => state.notificationCountFailed;

export const notificationList = (state: CommonState) => state.notificationList;
export const notificationListLoading = (state: CommonState) => state.notificationListLoading;
export const notificationListLoaded = (state: CommonState) => state.notificationListLoaded;
export const notificationListFailed = (state: CommonState) => state.notificationListFailed;
