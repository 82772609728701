import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../../state.interface';
import * as reportActions from './action/report.action';
import {
    circularSmsCount,
    circularSmsList,
    circularSmsListLoading,
    deletedStudentReport,
    invalidRecordCount,
    invalidRecordList,
    invalidRecordListLoading,
    renewalSchool,
    temprenewalSchool,
    renewalSchoolCount,
    renewalSchoolLoading,
    renewalSchoolReport,
    reportList,
    reportListLoading,
    schoolHistoryCount,
    schoolHistoryList,
    schoolHistoryListLoading,
    schoolHistoryReport,
    smsCircularReport,
    smsCreditCount,
    smsCreditList,
    smsCreditListLoading,
    smsCreditReport,
    stoppedSchoolCount,
    stoppedSchoolEnable,
    stoppedSchoolList,
    stoppedSchoolListLoading,
    stoppedSchoolReport,
    voiceCreditCount,
    voiceCreditList,
    voiceCreditListLoading,
    schoolAppUsageListLoading,
    schoolAppUsageList,
    schoolAppUsageCountLoading,
    schoolAppUsageCount,
    circularVoiceList,
    circularVoiceListLoading,
    circularVoiceCount,
    circularVoiceCountLoading,
    exportCircularVoiceReport,
    exportCircularVoiceReportLoading,
    instituteVoiceUsageList,
    instituteVoiceUsageListLoading,
    instituteVoiceUsageCount,
    instituteVoiceUsageCountLoading,
    exportInstituteVoiceUsage,
    exportInstituteVoiceUsageLoading,
    getSchoolDetailsList,
    getSchoolDetailsLoaded,
    getSchoolDetailsFailed,
    SchoolProfileAcknowledge,
    SchoolProfileAcknowledgeLoaded,
    SchoolProfileAcknowledgeFailed,
    RedirectToSchoolProfile,
    RedirectToSchoolProfileLoaded,
    RedirectToSchoolProfileFailed, SchoolProfileChanges,
    SchoolProfileChangesLoaded, SchoolProfileChangesFailed,
    verifyRenewalForm,
    verifyRenewalFormLoading,
    subscribeInterestSchoolList,
    subscribeInterestSchoolListLoading,
    subscribeInterestSchoolCount,
    liveSchoolExport,
    liveSchoolExportLoading,
} from './reducer/report.selector';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as instituteActions from '../institute-mangements/action/institute-management.action';


@Injectable()

export class ReportSandbox {
    public reportList$ = this.appState.select(reportList);
    public reportListLoading$ = this.appState.select(reportListLoading);
    public smsCreditList$ = this.appState.select(smsCreditList);
    public smsCreditListLoading$ = this.appState.select(smsCreditListLoading);
    public invalidRecordList$ = this.appState.select(invalidRecordList);
    public invalidRecordListLoading$ = this.appState.select(invalidRecordListLoading);
    public invalidRecordCount$ = this.appState.select(invalidRecordCount);
    public smsCreditCount$ = this.appState.select(smsCreditCount);
    public schoolHistoryList$ = this.appState.select(schoolHistoryList);
    public schoolHistoryListLoading$ = this.appState.select(schoolHistoryListLoading);
    public schoolHistoryCount$ = this.appState.select(schoolHistoryCount);
    public stoppedSchoolList$ = this.appState.select(stoppedSchoolList);
    public stoppedSchoolListLoading$ = this.appState.select(stoppedSchoolListLoading);
    public stoppedSchoolCount$ = this.appState.select(stoppedSchoolCount);
    public stoppedSchoolEnable$ = this.appState.select(stoppedSchoolEnable);
    public circularSmsList$ = this.appState.select(circularSmsList);
    public circularSmsListLoading$ = this.appState.select(circularSmsListLoading);
    public circularSmsCount$ = this.appState.select(circularSmsCount);

    public renewalSchool$ = this.appState.select(renewalSchool);
    public temprenewalSchool$ = this.appState.select(temprenewalSchool);
    public renewalSchoolLoading$ = this.appState.select(renewalSchoolLoading);
    public renewalSchoolCount$ = this.appState.select(renewalSchoolCount);

    public deletedStudentReport$ = this.appState.select(deletedStudentReport);
    public schoolHistoryReport$ = this.appState.select(schoolHistoryReport);
    public smsCircularReport$ = this.appState.select(smsCircularReport);

    public smsCreditReport$ = this.appState.select(smsCreditReport);
    public renewalSchoolReport$ = this.appState.select(renewalSchoolReport);
    public stoppedSchoolReport$ = this.appState.select(stoppedSchoolReport);

    public voiceCreditList$ = this.appState.select(voiceCreditList);
    public voiceCreditListLoading$ = this.appState.select(voiceCreditListLoading);
    public voiceCreditCount$ = this.appState.select(voiceCreditCount);

    public schoolAppUsageListLoading$ = this.appState.select(schoolAppUsageListLoading);
    public schoolAppUsageList$ = this.appState.select(schoolAppUsageList);

    public schoolAppUsageCountLoading$ = this.appState.select(schoolAppUsageCountLoading);
    public schoolAppUsageCount$ = this.appState.select(schoolAppUsageCount);

    public circularVoiceList$ = this.appState.select(circularVoiceList);
    public circularVoiceListLoading$ = this.appState.select(circularVoiceListLoading);

    public circularVoiceCount$ = this.appState.select(circularVoiceCount);
    public circularVoiceCountLoading$ = this.appState.select(circularVoiceCountLoading);

    public exportCircularVoiceReport$ = this.appState.select(exportCircularVoiceReport);
    public exportCircularVoiceReportLoading$ = this.appState.select(exportCircularVoiceReportLoading);

    public instituteVoiceUsageList$ = this.appState.select(instituteVoiceUsageList);
    public instituteVoiceUsageListLoading$ = this.appState.select(instituteVoiceUsageListLoading);

    public instituteVoiceUsageCount$ = this.appState.select(instituteVoiceUsageCount);
    public instituteVoiceUsageCountLoading$ = this.appState.select(instituteVoiceUsageCountLoading);

    public exportInstituteVoiceUsage$ = this.appState.select(exportInstituteVoiceUsage);
    public exportInstituteVoiceUsageLoading$ = this.appState.select(exportInstituteVoiceUsageLoading);

    public getSchoolDetailsList$ = this.appState.select(getSchoolDetailsList);
    public getSchoolDetailsLoaded$ = this.appState.select(getSchoolDetailsLoaded);
    public getSchoolDetailsFailed$ = this.appState.select(getSchoolDetailsFailed);

    public SchoolProfileAcknowledge$ = this.appState.select(SchoolProfileAcknowledge);
    public SchoolProfileAcknowledgeLoaded$ = this.appState.select(SchoolProfileAcknowledgeLoaded);
    public SchoolProfileAcknowledgeFailed$ = this.appState.select(SchoolProfileAcknowledgeFailed);

    public RedirectToSchoolProfile$ = this.appState.select(RedirectToSchoolProfile);
    public RedirectToSchoolProfileLoaded$ = this.appState.select(RedirectToSchoolProfileLoaded);
    public RedirectToSchoolProfileFailed$ = this.appState.select(RedirectToSchoolProfileFailed);

    public SchoolProfileChanges$ = this.appState.select(SchoolProfileChanges);
    public SchoolProfileChangesLoaded$ = this.appState.select(SchoolProfileChangesLoaded);
    public SchoolProfileChangesFailed$ = this.appState.select(SchoolProfileChangesFailed);

    public verifyRenewalForm$ = this.appState.select(verifyRenewalForm);
    public verifyRenewalFormLoading$ = this.appState.select(verifyRenewalFormLoading);

    public subscribeInterestSchoolList$ = this.appState.select(subscribeInterestSchoolList);
    public subscribeInterestSchoolListLoading$ = this.appState.select(subscribeInterestSchoolListLoading);
    public subscribeInterestSchoolCount$ = this.appState.select(subscribeInterestSchoolCount);
   
    public liveSchoolExport$ = this.appState.select(liveSchoolExport);
    public liveSchoolExportLoading$ = this.appState.select(liveSchoolExportLoading);
    

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected appState: Store<store.AppState>,
        public router: Router) {
    }
    // Sms Credit List
    smsCreditList(value) {
        this.appState.dispatch(new reportActions.SmsCreditListAction(value));
    }
    smsCreditCount(value) {
        this.appState.dispatch(new reportActions.SmsCreditCountAction(value));
    }
    // invalid record List
    invalidRecordList(value) {
        this.appState.dispatch(new reportActions.InvalidRecordAction(value));
    }
    invalidRecordListCount(value) {
        this.appState.dispatch(new reportActions.InvalidRecordCountAction(value));
    }
    schoolHistoryList(value) {
        this.appState.dispatch(new reportActions.SchoolHistoryAction(value));
    }
    schoolHistoryCount(value) {
        this.appState.dispatch(new reportActions.SchoolHistoryCountAction(value));
    }
    stoppedSchoolList(value) {
        this.appState.dispatch(new reportActions.StoppedSchoolListAction(value));
    }
    StoppedSchoolCount(value) {
        this.appState.dispatch(new reportActions.StoppedSchoolCountAction(value));
    }
    StoppedSchoolEnable(value) {
        this.appState.dispatch(new reportActions.StoppedSchoolEnableAction(value));
    }
    circularSmsList(value) {
        this.appState.dispatch(new reportActions.CircularSmsListAction(value));
    }
    circularSmsCount(value) {
        this.appState.dispatch(new reportActions.CircularSmsCountAction(value));
    }
    renewalSchoolList(value) {
        this.appState.dispatch(new reportActions.RenewalSchoolAction(value));
    }
    searchRenewalSchoolList(value) {
        this.appState.dispatch(new reportActions.SearchRenewalSchoolAction(value));
    }
    renewalSchoolCount(value) {
        this.appState.dispatch(new reportActions.RenewalSchoolCountAction(value));
    }
    deletedStuExport(value) {
        this.appState.dispatch(new reportActions.DeletedSchoolExportAction(value));
    }
    schoolHistoryExport(value) {
        this.appState.dispatch(new reportActions.SchoolHistoryExportAction(value));
    }
    smsCircularReport(value) {
        this.appState.dispatch(new reportActions.SmsCircularReportAction(value));
    }
    schoolRenewExport(value) {
        this.appState.dispatch(new reportActions.RenewalSchoolReportAction(value));
    }
    smsCreditExport(value) {
        this.appState.dispatch(new reportActions.SmsCreditReportAction(value));
    }
    stoppedSchoolExport(value) {
        this.appState.dispatch(new reportActions.StoppedSchoolExportAction(value));
    }
    voiceCreditReport(value) {
        this.appState.dispatch(new reportActions.VoiceCreditExportAction(value));
    }
    redirectToInstitute(value) {
        this.appState.dispatch(new reportActions.RedirectToInstituteAction(value));
    }
    voiceCreditList(value) {
        this.appState.dispatch(new reportActions.VoiceCreditList(value));
    }
    voiceCreditCount(value) {
        this.appState.dispatch(new reportActions.VoiceCreditCountAction(value));
    }
    schoolAppUsageList(value) {
        this.appState.dispatch(new reportActions.SchoolAppUsageList(value));
    }
    schoolAppUsageCount(value) {
        this.appState.dispatch(new reportActions.SchoolAppUsageListCount(value));
    }
    circularVoiceList(value) {
        this.appState.dispatch(new reportActions.VoiceCircularList(value));
    }
    circularVoiceCount(value) {
        this.appState.dispatch(new reportActions.VoiceCircularCount(value));
    }
    voiceCircularReport(value) {
        this.appState.dispatch(new reportActions.VoiceCircularReport(value));
    }
    schoolAppUsageExport(value) {
        this.appState.dispatch(new reportActions.schoolAppUsageExportAction(value));
    }


    // circularVoiceList(value) {
    //     this.appState.dispatch(new reportActions.VoiceCircularList(value));
    // }
    InstituteVoiceUsageList(value) {
        this.appState.dispatch(new reportActions.InstituteVoiceUsageList(value));
    }
    // circularVoiceCount(value) {
    //     this.appState.dispatch(new reportActions.VoiceCircularCount(value));
    // }
    InstituteVoiceUsageCount(value) {
        this.appState.dispatch(new reportActions.InstituteVoiceUsageCount(value));
    }
    // voiceCircularReport(value) {
    //     this.appState.dispatch(new reportActions.VoiceCircularReport(value));
    // }
    ExportInstituteVoiceUsage(value) {
        this.appState.dispatch(new reportActions.ExportInstituteVoiceUsage(value));
    }

    schoolDetailsList(params) {
        this.appState.dispatch(new reportActions.getSchoolDetailList(params));
    }

    schoolProfileExport(params) {
        this.appState.dispatch(new reportActions.schoolProfileExport(params));
    }

    SchoolProfileAcknowledge(params) {
        this.appState.dispatch(new reportActions.SchoolProfileIsAcknowledge(params));
    }

    RedirectToSchoolProfile(params) {
        this.appState.dispatch(new reportActions.RedirectToSchoolProfileAction(params));
    }

    SchoolProfileChangesPopup(params) {
        this.appState.dispatch(new reportActions.SchoolProfileChangesPopup(params));
    }

    verifyRenewalList(value) {
        this.appState.dispatch(new reportActions.VerifyRenewalAction(value));
    }

    subscribeInterestSchoolList(value) {
        this.appState.dispatch(new reportActions.subscribeInterestSchoolList(value));
    }
    subscribeInterestSchoolListCount(value) {
        this.appState.dispatch(new reportActions.subscribeInterestSchoolCount(value));
    }
    clearInterestSchoolList() {
        this.appState.dispatch(new reportActions.clearInterestSchoolList());
    }
    liveSchoolExport(value) {
        this.appState.dispatch(new reportActions.liveSchoolExportAction(value));
    }

}
