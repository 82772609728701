import { createSelector } from 'reselect';
import { AppState } from '../../../state.interface';
import * as fromReport from './report.reducer';

export const getReportState = (state: AppState) => state.reports;

export const reportList = createSelector(getReportState, fromReport.reportList);
export const reportListLoading = createSelector(getReportState, fromReport.reportListLoading);
export const reportListLoaded = createSelector(getReportState, fromReport.reportListLoaded);
export const reportListFailed = createSelector(getReportState, fromReport.reportListFailed);
// sms credit list
export const smsCreditList = createSelector(getReportState, fromReport.smsCreditList);
export const smsCreditListLoading = createSelector(getReportState, fromReport.smsCreditListLoading);
export const smsCreditListLoaded = createSelector(getReportState, fromReport.smsCreditListLoaded);
export const smsCreditListFailed = createSelector(getReportState, fromReport.smsCreditListFailed);
// invalid Record list
export const invalidRecordList = createSelector(getReportState, fromReport.invalidRecordList);
export const invalidRecordListLoading = createSelector(getReportState, fromReport.invalidRecordListLoading);
export const invalidRecordListLoaded = createSelector(getReportState, fromReport.invalidRecordListLoaded);
export const invalidRecordListFailed = createSelector(getReportState, fromReport.invalidRecordListFailed);
// count
export const invalidRecordCount = createSelector(getReportState, fromReport.invalidRecordCount);
export const smsCreditCount = createSelector(getReportState, fromReport.smsCreditCount);
// school history list
export const schoolHistoryList = createSelector(getReportState, fromReport.schoolHistoryList);
export const schoolHistoryListLoading = createSelector(getReportState, fromReport.schoolHistoryListLoading);
export const schoolHistoryListLoaded = createSelector(getReportState, fromReport.schoolHistoryListLoaded);
export const schoolHistoryListFailed = createSelector(getReportState, fromReport.schoolHistoryListFailed);
// stopped school enabled
export const stoppedSchoolEnable = createSelector(getReportState, fromReport.stoppedSchoolEnable);
export const stoppedSchoolEnableLoading = createSelector(getReportState, fromReport.stoppedSchoolEnableLoading);
export const stoppedSchoolEnableLoaded = createSelector(getReportState, fromReport.stoppedSchoolEnableLoaded);
export const stoppedSchoolEnableFailed = createSelector(getReportState, fromReport.stoppedSchoolEnableFailed);
// school history count
export const schoolHistoryCount = createSelector(getReportState, fromReport.schoolHistoryCount);
// stopped school list
export const stoppedSchoolList = createSelector(getReportState, fromReport.stoppedSchoolList);
export const stoppedSchoolListLoading = createSelector(getReportState, fromReport.stoppedSchoolListLoading);
export const stoppedSchoolListLoaded = createSelector(getReportState, fromReport.stoppedSchoolListLoaded);
export const stoppedSchoolListFailed = createSelector(getReportState, fromReport.stoppedSchoolListFailed);
// stopped school count
export const stoppedSchoolCount = createSelector(getReportState, fromReport.stoppedSchoolCount);
// circular sms list
export const circularSmsList = createSelector(getReportState, fromReport.circularSmsList);
export const circularSmsListLoading = createSelector(getReportState, fromReport.circularSmsListLoading);
export const circularSmsListLoaded = createSelector(getReportState, fromReport.circularSmsListLoaded);
export const circularSmsListFailed = createSelector(getReportState, fromReport.circularSmsListFailed);
// circular sms count
export const circularSmsCount = createSelector(getReportState, fromReport.circularSmsCount);
// renewal school list
export const renewalSchool = createSelector(getReportState, fromReport.renewalSchool);
export const temprenewalSchool = createSelector(getReportState, fromReport.temprenewalSchool);
export const renewalSchoolLoading = createSelector(getReportState, fromReport.renewalSchoolLoading);
export const renewalSchoolLoaded = createSelector(getReportState, fromReport.renewalSchoolLoaded);
export const renewalSchoolFailed = createSelector(getReportState, fromReport.renewalSchoolFailed);
// renewal school count
export const renewalSchoolCount = createSelector(getReportState, fromReport.renewalSchoolCount);

export const deletedStudentReport = createSelector(getReportState, fromReport.deletedStudentReport);
export const deletedStudentReportLoading = createSelector(getReportState, fromReport.deletedStudentReportLoading);

export const schoolHistoryReport = createSelector(getReportState, fromReport.schoolHistoryReport);
export const schoolHistoryReportLoading = createSelector(getReportState, fromReport.schoolHistoryReportLoading);

export const smsCircularReport = createSelector(getReportState, fromReport.smsCircularReport);
export const smsCircularReportLoading = createSelector(getReportState, fromReport.smsCircularReportLoading);

export const renewalSchoolReport = createSelector(getReportState, fromReport.renewalSchoolReport);
export const renewalSchoolReportLoading = createSelector(getReportState, fromReport.renewalSchoolReportLoading);

export const smsCreditReport = createSelector(getReportState, fromReport.smsCreditReport);
export const smsCreditReportLoading = createSelector(getReportState, fromReport.smsCreditReportLoading);

export const stoppedSchoolReport = createSelector(getReportState, fromReport.stoppedSchoolReport);
export const stoppedSchoolReportLoading = createSelector(getReportState, fromReport.stoppedSchoolReportLoading);

export const voiceCreditList = createSelector(getReportState, fromReport.voiceCreditList);
export const voiceCreditListLoading = createSelector(getReportState, fromReport.voiceCreditListLoading);

export const voiceCreditCount = createSelector(getReportState, fromReport.voiceCreditCount);
export const voiceCreditCountLoading = createSelector(getReportState, fromReport.voiceCreditCountLoading);

export const schoolAppUsageList = createSelector(getReportState, fromReport.schoolAppUsageList);
export const schoolAppUsageListLoading = createSelector(getReportState, fromReport.schoolAppUsageListLoading);

export const schoolAppUsageCount = createSelector(getReportState, fromReport.schoolAppUsageCount);
export const schoolAppUsageCountLoading = createSelector(getReportState, fromReport.schoolAppUsageCountLoading);

export const circularVoiceList = createSelector(getReportState, fromReport.circularVoiceList);
export const circularVoiceListLoading = createSelector(getReportState, fromReport.circularVoiceListLoading);

export const circularVoiceCount = createSelector(getReportState, fromReport.circularVoiceCount);
export const circularVoiceCountLoading = createSelector(getReportState, fromReport.circularVoiceCountLoading);

export const exportCircularVoiceReport = createSelector(getReportState, fromReport.exportCircularVoiceReport);
export const exportCircularVoiceReportLoading = createSelector(getReportState, fromReport.exportCircularVoiceReportLoading);

export const instituteVoiceUsageList = createSelector(getReportState, fromReport.instituteVoiceUsageList);
export const instituteVoiceUsageListLoading = createSelector(getReportState, fromReport.instituteVoiceUsageListLoading);

export const instituteVoiceUsageCount = createSelector(getReportState, fromReport.instituteVoiceUsageCount);
export const instituteVoiceUsageCountLoading = createSelector(getReportState, fromReport.instituteVoiceUsageCountLoading);

export const exportInstituteVoiceUsage = createSelector(getReportState, fromReport.exportInstituteVoiceUsage);
export const exportInstituteVoiceUsageLoading = createSelector(getReportState, fromReport.exportInstituteVoiceUsageLoading);


export const SchoolAppUsageReport = createSelector(getReportState, fromReport.SchoolAppUsageReport);
export const SchoolAppUsageReportLoading = createSelector(getReportState, fromReport.SchoolAppUsageReportLoading);

export const getSchoolDetailsList = createSelector(getReportState, fromReport.getSchoolDetails);
export const getSchoolDetailsLoaded = createSelector(getReportState, fromReport.getSchoolDetailsLoaded);
export const getSchoolDetailsFailed = createSelector(getReportState, fromReport.getSchoolDetailsFailed);


export const SchoolProfileAcknowledge = createSelector(getReportState, fromReport.SchoolProfileAcknowledge);
export const SchoolProfileAcknowledgeLoaded = createSelector(getReportState, fromReport.schoolProfileAcknowledgeSuccess);
export const SchoolProfileAcknowledgeFailed = createSelector(getReportState, fromReport.schoolProfileAcknowledgeFailed);

export const RedirectToSchoolProfile = createSelector(getReportState, fromReport.RedirectToSchoolProfile);
export const RedirectToSchoolProfileLoaded = createSelector(getReportState, fromReport.RedirectToSchoolProfileLoaded);
export const RedirectToSchoolProfileFailed = createSelector(getReportState, fromReport.RedirectToSchoolProfileFailed);

export const SchoolProfileChanges = createSelector(getReportState, fromReport.SchoolProfileChanges);
export const SchoolProfileChangesLoaded = createSelector(getReportState, fromReport.SchoolProfileChangesLoaded);
export const SchoolProfileChangesFailed = createSelector(getReportState, fromReport.SchoolProfileChangesFailed)

export const verifyRenewalForm = createSelector(getReportState, fromReport.verifyRenewalForm)
export const verifyRenewalFormLoading = createSelector(getReportState, fromReport.verifyRenewalFormLoading)

export const subscribeInterestSchoolList = createSelector(getReportState, fromReport.subscribeInterestSchoolList);
export const subscribeInterestSchoolListLoading = createSelector(getReportState, fromReport.subscribeInterestSchoolListLoading);
export const subscribeInterestSchoolListLoaded = createSelector(getReportState, fromReport.subscribeInterestSchoolListLoaded);
export const subscribeInterestSchoolListFailed = createSelector(getReportState, fromReport.subscribeInterestSchoolListFailed);

export const subscribeInterestSchoolCount = createSelector(getReportState, fromReport.subscribeInterestSchoolCount);

export const liveSchoolExport = createSelector(getReportState, fromReport.liveSchoolExport);
export const liveSchoolExportLoading = createSelector(getReportState, fromReport.liveSchoolExportLoading);
export const liveSchoolExportLoaded = createSelector(getReportState, fromReport.liveSchoolExportLoaded);
export const liveSchoolExportFailed = createSelector(getReportState, fromReport.liveSchoolExportFailed);
