import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {InstituteManagementSandbox} from '../../../../pages/institute-mangements/institute-management.sandbox';
import * as $ from 'jquery';
import {get, find} from 'lodash';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit, OnDestroy {
  public erpLists = [
    {label: 'Basic Settings', id: 'basic_settings', isChecked: false},
    {label: 'Certificates', id: 'certificates', isChecked: false},
    {label: 'Dashboard', id: 'dashboard', isChecked: false},
    {label: 'Exam Management', id: 'exam_management', isChecked: false},
    {label: 'Extra-Curricular Manage', id: 'extra_curricular_manage', isChecked: false},
    {label: 'Fee Management', id: 'fee_management', isChecked: false},
    {label: 'Fees Reports', id: 'fees_reports', isChecked: false},
    {label: 'Finance', id: 'finance', isChecked: false},
    {label: 'Hostel Management System', id: 'hostel_management_system', isChecked: false},
    {label: 'ID Card Generator', id: 'id_card_generator', isChecked: false},
    {label: 'Inventory', id: 'inventory', isChecked: false},
    {label: 'Library Management', id: 'library_management', isChecked: false},
    {label: 'Library Management System', id: 'library_management_system', isChecked: false},
    {label: 'Main Admissions', id: 'main_admissions', isChecked: false},
    {label: 'Other Staff', id: 'other_staff', isChecked: false},
    {label: 'Parents', id: 'parents', isChecked: false},
    {label: 'Payroll Management System', id: 'payroll_management_system', isChecked: false},
    {label: 'Reports / Export Datas', id: 'reports_export_datas', isChecked: false},
    {label: 'Sports Management', id: 'sports_management', isChecked: false},
    {label: 'Staff Management', id: 'staff_management', isChecked: false},
    {label: 'Standard and Section Management', id: 'standard_and_section_management', isChecked: false},
    {label: 'Students', id: 'students', isChecked: false},
    {label: 'Students Promotion', id: 'students_promotion', isChecked: false},
    {label: 'Today Birthday', id: 'today_birthday', isChecked: false},
    {label: 'User Permission', id: 'user_permission', isChecked: false},
    {label: 'Vehicle Manage Report', id: 'vehicle_manage_report', isChecked: false},
    {label: 'Vehicle management', id: 'vehicle_management', isChecked: false},
    {label: 'Progress Card Generator', id: 'progress_card_generator', isChecked: false},
    {label: 'Payment Gateway', id: 'manage_bank_master', isChecked: false},
  ];
  // form Variable
  public schoolApprovalForm: FormGroup;
  // approval model variable
  public erpValue = 'enable_additional_modules';
  public voiceValue = 'enable_voice';
  public smsValue = 'enable_sms';
  public unlimitedsmsValue = 'unlimited_sms';
  public perSmsValue = 'per_sms_cost';
  public costValue = 'cost';
  public noOfCallsValue = 'unlimited_calls';
  public minuteValues = 'number_of_minutes';
  public minuteCostValue = 'minutes_cost';
  numberOfsmsValue = 'number_of_sms';
  numberSmsValue = 'number_sms_cost';
  public settingCheckList: any = [];
  public settingUncheckList: any = [];
  public settingsArray: any = [];
  public enableVoiceValue = false;
  public enableSmsValue = false;
  public unlimitedMinuteValue: any;
  public unlimitedCallsValue: any;
  public perCallCostValue: any;
  public unlimitedSmsesValue: any;
  public perSmsesCostValue: any;
  public prepaidPostpaidValue = 1;
  public enableErpValue = false;
  public approvalId: any;
  public endDate: any;
  public goLiveDate: any;
  public perminuteCostValue: any;
  isUnlimitedCallValue = false;
  isUnlimitedSmsValues = false;
  numberOfSmscostValue: string;
  numberOfSMSValue: string;
  enableUnlimitSms = false;
  enableunlimitedNoOfCalls = false;
  unlimitedSmsName: any;
  userDetailsData: any = {};
  isEnabledVoiceSms = false;
  usedVoiceCount: any;
  usedSMSCount: any;

  private subscriptions: Array<Subscription> = [];

  constructor(public activeModal: NgbActiveModal,
              public instituteSandbox: InstituteManagementSandbox,
              public fb: FormBuilder,
              public modals: NgbModal) { }

  ngOnInit() {
    this.instituteSandbox.clearInstituteList();
    this.userDetailsData = JSON.parse(localStorage.getItem('userdetails'));
    this.UploadApprovalForm();
    if (this.approvalId) {
      this.instituteSandbox.instituteDetail(this.approvalId);
      // setTimeout(() => {
      //   this.instituteSandbox.instituteDetail(this.approvalId);
      // }, 1000);
      this.editApprovalForm();
    }
  }

  // intial approvalForm
  public UploadApprovalForm() {
    this.schoolApprovalForm = this.fb.group({
      pocLive: [],
      prepaidPostPaid: [],
      validFrom: ['', Validators.required],
      validTo: ['', Validators.required],
      remarks: [],
      noOfMinutes: [''],
      noOfSMs: [''],
      unlimitedcall: [''],
      unlimitedSms: [''],
      goLiveDate: [''],
    });
    this.subscriptions.push(this.schoolApprovalForm.controls['validFrom'].valueChanges.subscribe(fromDate => {
      this.endDate = fromDate;
    }));
    this.subscriptions.push(this.schoolApprovalForm.controls['prepaidPostPaid'].valueChanges.subscribe(data => {
      this.prepaidPostpaidValue = data;
      if (this.prepaidPostpaidValue === 2) {
         this.schoolApprovalForm.controls['noOfMinutes'].disable();
         this.schoolApprovalForm.controls['noOfSMs'].disable();
        this.schoolApprovalForm.updateValueAndValidity();
        this.schoolApprovalForm.controls['unlimitedcall'].enable();
        this.schoolApprovalForm.controls['unlimitedSms'].enable();
      }
      if (this.prepaidPostpaidValue === 1) {
        console.log('dddddd')
        this.schoolApprovalForm.controls['noOfMinutes'].enable();
        this.schoolApprovalForm.controls['noOfSMs'].enable();

        this.schoolApprovalForm.controls['unlimitedcall'].disable();
        this.schoolApprovalForm.controls['unlimitedSms'].disable();
        this.schoolApprovalForm.updateValueAndValidity();
      }
    }));
    this.schoolApprovalForm.updateValueAndValidity();
  }

  // comPaire two date validation
  comparetwoDates(fromDate: string, toDate: string) {
    return (group: FormGroup) => {
      const statingDate = group.controls[fromDate];
      const endingDate = group.controls[toDate];
      if (statingDate.value > endingDate.value) {
        return endingDate.setErrors({dateError: true});
      }
    };
  }
  // submit the approved form
  public onSubmitApprove() {
    console.log('this.schoolApprovalForm', this.schoolApprovalForm);
    this.isEnabledVoiceSms = false;
    if (this.prepaidPostpaidValue === 2) {
      this.schoolApprovalForm.controls['noOfMinutes'].setValidators([]);
      this.schoolApprovalForm.get('noOfMinutes').updateValueAndValidity();
      this.schoolApprovalForm.controls['noOfSMs'].setValidators([]);
      this.schoolApprovalForm.get('noOfSMs').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableVoiceValue === true ) {
      this.schoolApprovalForm.controls['noOfMinutes'].setValidators([Validators.required]);
      this.schoolApprovalForm.controls['noOfMinutes'].enable();
      this.schoolApprovalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolApprovalForm.controls['unlimitedSms'].setValidators([]);
      this.schoolApprovalForm.get('noOfMinutes').updateValueAndValidity();
      this.schoolApprovalForm.get('unlimitedcall').updateValueAndValidity();
      this.schoolApprovalForm.get('unlimitedSms').updateValueAndValidity();
      // this.schoolApprovalForm.updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableVoiceValue === false) {
      this.schoolApprovalForm.controls['noOfMinutes'].setValidators([]);
      this.schoolApprovalForm.controls['noOfMinutes'].disable();
      this.schoolApprovalForm.get('noOfMinutes').updateValueAndValidity();
      // this.schoolApprovalForm.removeControl('noOfMinutes');
    }
    if (this.prepaidPostpaidValue === 1 && this.enableSmsValue === true ) {
      this.schoolApprovalForm.controls['noOfSMs'].setValidators([Validators.required]);
      this.schoolApprovalForm.controls['noOfSMs'].enable();
      this.schoolApprovalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolApprovalForm.controls['unlimitedSms'].setValidators([]);
      // this.schoolApprovalForm.updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableSmsValue === false) {
      this.schoolApprovalForm.controls['noOfSMs'].setValidators([]);
      this.schoolApprovalForm.controls['noOfSMs'].disable();
      this.schoolApprovalForm.get('noOfSMs').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableVoiceValue === true && this.isUnlimitedCallValue ===  false ) {
      this.schoolApprovalForm.controls['unlimitedcall'].setValidators([Validators.required]);
      this.schoolApprovalForm.controls['unlimitedcall'].enable();
      this.schoolApprovalForm.get('unlimitedcall').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableVoiceValue === true && this.isUnlimitedCallValue ===  true ) {
      this.schoolApprovalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolApprovalForm.controls['unlimitedcall'].disable();
      this.schoolApprovalForm.get('unlimitedcall').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableSmsValue === true && this.isUnlimitedSmsValues === false) {
      this.schoolApprovalForm.controls['unlimitedSms'].setValidators([Validators.required]);
      this.schoolApprovalForm.controls['unlimitedSms'].enable();
      this.schoolApprovalForm.get('unlimitedSms').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableSmsValue === true && this.isUnlimitedSmsValues === true) {
      this.schoolApprovalForm.controls['unlimitedSms'].setValidators([]);
      this.schoolApprovalForm.controls['unlimitedSms'].disable();
      this.schoolApprovalForm.get('unlimitedSms').updateValueAndValidity();
    }
    if (!this.schoolApprovalForm.valid) {
      this.validateAllFormFields(this.schoolApprovalForm);
            let target;
            let target1;
            let target2;
            target = $('input.ng-invalid').first();
            target1 = $('ngx-select-dropdown.ng-invalid').first();
            target2 = $('select.ng-invalid').first();
            if (target['length'] === 1) {
                target = $('input.ng-invalid').first();
                $('ngb-modal-window').animate(
                    { scrollTop: $(target).offset().top},
                    'slow',
                    () => {
                        target.focus();
                    }
                );
            } else if (target1['length'] === 1) {
                target = $('ngx-select-dropdown.ng-invalid').first();
                if (target) {
                    $('ngb-modal-window').animate(
                        { scrollTop: $(target).offset().top},
                        'slow',
                        () => {
                            target.focus();
                        }
                    );
                }
            } else if (target2['length'] === 1) {
                target = $('select.ng-invalid').first();
                if (target) {
                    $('ngb-modal-window').animate(
                        {scrollTop: $(target).offset().top},
                        'slow',
                        () => {
                            target.focus();
                        }
                    );
                }
            }
      if (+this.schoolApprovalForm.value.prepaidPostPaid === 1 ) {
        if (this.enableVoiceValue === true || this.enableSmsValue === true) {
          this.isEnabledVoiceSms = false;
        } else {
          this.isEnabledVoiceSms = true;
          let target3;
          target3 = $('.enableCondition');
          $('ngb-modal-window').animate(
              { scrollTop: $(target3).offset().top - 310 },
              'slow',
              () => {
                target3.focus();
              }
          );
        }
      }

      return;
    }
    if (+this.schoolApprovalForm.value.prepaidPostPaid === 1 ) {
      if (this.enableVoiceValue === true || this.enableSmsValue === true) {
        this.isEnabledVoiceSms = false;
      } else {
        this.isEnabledVoiceSms = true;
        let target;
        target = $('.enableCondition');
        $('ngb-modal-window').animate(
            { scrollTop: $(target).offset().top - 310 },
            'slow',
            () => {
              target.focus();
            }
        );
        return;
      }
    }
    // if (this.schoolApprovalForm.valid) {
    this.selectedPocLive();
    // this.isEnabledVoiceSms = false;
      const params: any = {};
      if ( this.userDetailsData.user.roleSlugName === 'super_admin') {
        params.verifyOrApprove = 2;
      }
      params.instituteId = this.approvalId;
      params.pocorlive = +this.schoolApprovalForm.value.pocLive;
      params.prepaidorpostpaid = this.schoolApprovalForm.value.prepaidPostPaid;
      params.validFrom = this.schoolApprovalForm.value.validFrom;
      params.validTo = this.schoolApprovalForm.value.validTo;
      params.settings = this.settingCheckList;
      params.remarks = this.schoolApprovalForm.value.remarks;
      params.goLiveDate = this.schoolApprovalForm.value.goLiveDate;
      console.log('paramsApproved', params);
      this.instituteSandbox.approveInstitute(params);
      this.closePopup();
    // }
  }
  selectFromDate(event) {
    this.schoolApprovalForm.controls['goLiveDate'].setValue('');
    this.schoolApprovalForm.controls['validTo'].setValue('');
    this.goLiveDate = event.value;
    this.endDate = event.value;
  }
  selectGoLiveDate(event) {
    this.schoolApprovalForm.controls['validTo'].setValue('');
    this.endDate = event.value;
  }
  selectedPocLive() {

    debugger;
    if (+this.prepaidPostpaidValue === 1) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.unlimitedsmsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.perSmsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
      });
    } else {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
      });
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
 // subscribe the approve status and close popup
  closePopup() {
    this.subscriptions.push(this.instituteSandbox.instituteApproval$.subscribe(data => {
      if (data && data.status === 1) {
        this.activeModal.close();
      }
    }));
  }

  // setting schoolRenewalForm value
  public editApprovalForm() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.subscriptions.push(this.instituteSandbox.instituteDetail$.subscribe((details) => {
      if (details) {
        console.log('details', details)
        this.settingCheckList = [];
        details.settingsData.forEach(data => {
          this.settingCheckList.push({settingName: data.settings_name, settingValue: data.settings_value});
        });
        this.schoolApprovalForm.controls['pocLive'].setValue(details.instituteData.institutePocLive);
        this.schoolApprovalForm.controls['prepaidPostPaid'].setValue(details.instituteData.institutePrepaidOrPostpaid);
        this.prepaidPostpaidValue = (details.instituteData && details.instituteData.institutePrepaidOrPostpaid) ? details.instituteData.institutePrepaidOrPostpaid : 1;
        this.schoolApprovalForm.controls['validFrom'].setValue(details.instituteData.subscriptionFrom);
        this.schoolApprovalForm.controls['validTo'].setValue(details.instituteData.subscriptionTo);
        this.schoolApprovalForm.controls['remarks'].setValue(details.instituteData.remarks);
        console.log('UsedVoiceCount',details.instituteData.used_voice_count)
        console.log('UsedSMSCount',details.instituteData.used_sms_count)
        this.usedVoiceCount= details.instituteData.used_voice_count;
        this.usedSMSCount= details.instituteData.used_sms_count;
         this.endDate = new Date(details.instituteData.subscriptionFrom);
         debugger ;
        details.settingsData.forEach(settingsData => {
          this.settingsArray = settingsData;
          if (this.settingsArray.settings_name === this.perSmsValue) {
            // this.enableperSmsCost = true;
            this.perSmsesCostValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
            }
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue) {
            // this.enableUnlimitSms = true;
            this.unlimitedSmsesValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
              // this.schoolApprovalForm.controls['unlimitedSms'].setValidators([Validators.required]);
              // this.schoolApprovalForm.updateValueAndValidity();
            }
          }
          if (this.settingsArray.settings_name === this.smsValue) {
            this.enableSmsValue = true;
          }
          if (this.settingsArray.settings_name === this.voiceValue) {
            this.enableVoiceValue = true;
          }
          if (this.settingsArray.settings_name === this.erpValue) {
            this.enableErpValue = true;
          }
          if (this.settingsArray.settings_name === this.minuteValues) {
            this.unlimitedMinuteValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
              // this.schoolApprovalForm.controls['noOfMinutes'].setValidators([Validators.required]);
              // this.schoolApprovalForm.updateValueAndValidity();
            }
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue) {
            this.unlimitedCallsValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
              // this.schoolApprovalForm.controls['unlimitedcall'].setValidators([Validators.required]);
              // this.schoolApprovalForm.updateValueAndValidity();
            }
          }
          if (this.settingsArray.settings_name === this.costValue) {
            //   this.enablecostValue = true;
            this.perCallCostValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
            }
          }
          if (this.settingsArray.settings_name === this.minuteCostValue) {
           // this.enablecostValue = true;
            this.perminuteCostValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
            }
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === 'UL') {
            this.enableunlimitedNoOfCalls = true;
            this.isUnlimitedCallValue = true;
            this.schoolApprovalForm.controls['unlimitedcall'].disable();
            this.schoolApprovalForm.updateValueAndValidity();
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === '') {
            this.enableunlimitedNoOfCalls = false;
            this.unlimitedCallsValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === 'UL') {
            this.enableUnlimitSms = true;
            this.isUnlimitedSmsValues = true;
            this.schoolApprovalForm.controls['unlimitedSms'].disable();
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === '') {
            this.enableUnlimitSms = false;
            this.enableUnlimitSms = this.settingsArray.settings_value;

          }
          if (this.settingsArray.settings_name === this.numberSmsValue) {
            this.numberOfSmscostValue = this.settingsArray.settings_value;
            if (this.settingsArray.settings_value) {
            }
          }
          if (this.settingsArray.settings_name === this.numberOfsmsValue) {
            this.numberOfSMSValue = this.settingsArray.settings_value;
            // if (this.settingsArray.settings_value !== '' ) {
            //   this.schoolApprovalForm.controls['noOfSMs'].setValidators([Validators.required]);
            //   this.schoolApprovalForm.updateValueAndValidity();
            // }
          }
          // this.erpLists.forEach(erpList => {
          //   if (this.settingsArray.settings_name === erpList.id) {
          //     erpList.isChecked = true;
          //   }
          // });
        });

        this.goLiveDate = details.instituteData.subscriptionFrom ? details.instituteData.subscriptionFrom : '';

        this.schoolApprovalForm.controls['goLiveDate'].setValue(details.instituteData.go_live_date);
      }
    }));
  }

  // while close approved modal
  approvedClose() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.settingCheckList = [];
    this.perSmsesCostValue = '';
    this.unlimitedSmsesValue = '';
    this.perCallCostValue = '';
    this.unlimitedCallsValue = '';
    this.unlimitedMinuteValue = '';
  }

  // change postpaidprepaid
  public postpaidprepaid(event) {
    if (+event.value === 2) {
      this.isEnabledVoiceSms = false;
      // this.schoolApprovalForm.controls['noOfMinutes'].disable();
      // this.schoolApprovalForm.controls['noOfSMs'].disable();
      this.schoolApprovalForm.updateValueAndValidity();
      // this.schoolApprovalForm.controls['unlimitedcall'].enable();
      // this.schoolApprovalForm.controls['unlimitedSms'].enable();
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === 'unlimited_sms') {
          if (this.settingCheckList[i].settingValue === 'UL' ) {
            this.schoolApprovalForm.controls['unlimitedSms'].disable();
            this.isUnlimitedSmsValues = true;
          } else {
            this.schoolApprovalForm.controls['unlimitedSms'].enable();
          }
        }
        if (data.settingName === 'unlimited_calls') {
          if (this.settingCheckList[i].settingValue === 'UL' ) {
            this.schoolApprovalForm.controls['unlimitedcall'].disable();
            this.isUnlimitedCallValue = true;
          } else {
            this.schoolApprovalForm.controls['unlimitedcall'].enable();
          }
        }
      });
    }
    if (+event.value === 1) {
      // if (this.settingsArray.settings_name === this.minuteValues) {
      //   this.unlimitedMinuteValue = this.settingsArray.settings_value;
      //   if (this.settingsArray.settings_value !== '') {
      //     this.schoolApprovalForm.controls['noOfMinutes'].setValidators([Validators.required]);
      //     this.schoolApprovalForm.updateValueAndValidity();
      //   }
      // }
      // if (this.settingsArray.settings_name === this.numberOfsmsValue) {
      //   this.numberOfSMSValue = this.settingsArray.settings_value;
      //   if (this.settingsArray.settings_value !== '' ) {
      //     this.schoolApprovalForm.controls['noOfSMs'].setValidators([Validators.required]);
      //     this.schoolApprovalForm.updateValueAndValidity();
      //   }
      // }
      // this.schoolApprovalForm.controls['noOfMinutes'].setValidators([Validators.required]);
      // this.schoolApprovalForm.controls['noOfSMs'].setValidators([Validators.required]);
     // this.schoolApprovalForm.controls['noOfMinutes'].enable();
     // this.schoolApprovalForm.controls['noOfSMs'].enable();

      // this.schoolApprovalForm.controls['unlimitedcall'].disable();
      // this.schoolApprovalForm.controls['unlimitedSms'].disable();
      this.schoolApprovalForm.updateValueAndValidity();
    }
    // const prepaidPostpaid = +event.value;
    // if (prepaidPostpaid === 1) {
    //   this.settingCheckList.forEach((data, i) => {
    //     if (data.settingName === this.noOfCallsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.unlimitedsmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.costValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.perSmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //   });
    //   this.unlimitedMinuteValue = '';
    //   this.perCallCostValue = '';
    //   this.perSmsesCostValue = '';
    //   this.unlimitedSmsesValue = '';
    // } else {
    //   this.settingCheckList.forEach((data, i) => {
    //     if (data.settingName === this.minuteValues) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.numberOfsmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.minuteCostValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.numberSmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //   });
    //   this.unlimitedCallsValue = '';
    //   this.perminuteCostValue = '';
    //   this.numberOfSmscostValue = '';
    //   this.numberOfSMSValue = '';
    //   this.enableUnlimitSms = false;
    //   this.enableunlimitedNoOfCalls = false;
    // }
  }

  // change enable voice
  public changeEnableVoice(event, value) {
    if (event.target.checked === true) {
      this.schoolApprovalForm.controls['noOfMinutes'].enable();
      this.isEnabledVoiceSms = false;
      this.enableVoiceValue = true;
      this.settingCheckList.push(
          {settingName: value, settingValue: ''},
          {settingName: this.minuteValues, settingValue: ''},
          {settingName: this.noOfCallsValue, settingValue: ''},
          {settingName: this.minuteCostValue, settingValue: ''},
          {settingName: this.costValue, settingValue: ''});
    }
    if (event.target.checked === false) {
      this.enableVoiceValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableVoice => {
        return (enableVoice.settingName !== value && enableVoice.settingName !== this.minuteValues && enableVoice.settingName !== this.costValue && enableVoice.settingName !== this.noOfCallsValue && enableVoice.settingName !== this.minuteCostValue);
      });
      // clear text field
      this.unlimitedCallsValue = '';
      this.perCallCostValue = '';
      this.unlimitedMinuteValue = '';
      this.perminuteCostValue = '';
      this.enableunlimitedNoOfCalls = false;
      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unlimited call value
  public unlimitedCallValue(event, value) {
    if (value === this.minuteValues) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteValues;
        }
      });
    }
    if (value === this.noOfCallsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.noOfCallsValue;
        }
      });
    }
  }

  // change unlimitedCalls
  public changeUnlimitedCall(event, value) {
    // this.isUnlimitedCallValue = value;
    if (event.target.checked === true) {
      this.schoolApprovalForm.controls['unlimitedcall'].disable();
      this.isUnlimitedCallValue = true;
      this.enableunlimitedNoOfCalls = true;
      this.unlimitedCallsValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });
      // this.settingCheckList.push({settingName: value, settingValue: 'UL'});
    } else {
      this.enableunlimitedNoOfCalls = false;
      this.isUnlimitedCallValue = false;
      this.schoolApprovalForm.controls['unlimitedcall'].enable();
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      // this.settingCheckList = this.settingCheckList.filter((unLimted) => {
      //   return unLimted.settingValue !== 'UL';
      // });
      // this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited Call
      this.unlimitedCallsValue = '';
    }
  }

  // getting per call cost Value
  public perCallsCostValue(event, value) {
    if (value === this.costValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.costValue;
        }
      });
    }
    if (value === this.minuteCostValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteCostValue;
        }
      });
    }
  }

  // enable Sms change
  public changeEnableSms(event, value) {
    if (event.target.checked === true) {
      this.schoolApprovalForm.controls['noOfSMs'].enable();
      this.enableSmsValue = true;
      this.isEnabledVoiceSms = false;
      this.settingCheckList.push(
          {settingName: value, settingValue: ''},
          {settingName: this.unlimitedsmsValue, settingValue: ''},
          {settingName: this.numberOfsmsValue, settingValue: ''},
          {settingName: this.numberSmsValue, settingValue: ''},
          {settingName: this.perSmsValue, settingValue: ''});
    } else {
      this.enableSmsValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableSms => {
        return (enableSms.settingName !== value && enableSms.settingName !== this.unlimitedsmsValue && enableSms.settingName !== this.perSmsValue && enableSms.settingName !== this.numberOfsmsValue && enableSms.settingName !== this.numberSmsValue);
      });
      // clear text field
      this.unlimitedSmsesValue = '';
      this.perSmsesCostValue = '';
      this.enableUnlimitSms = false;
      this.numberOfSMSValue = '';
      this.numberOfSmscostValue = '';
      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unLimitedSms value
  public unLimitedSmsValue(event, value) {
    debugger;
    if (value === this.numberOfsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberOfsmsValue;
        }
      });
    }
    if (value === this.unlimitedsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.unlimitedsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.unlimitedsmsValue;
        }
      });
    }
  }
// change unlimited Sms checkbox
  public changeUnlimitedSms(event, value) {
    this.unlimitedSmsName = value;
    if (event.target.checked === true) {
      this.schoolApprovalForm.controls['unlimitedSms'].disable();
      this.isUnlimitedSmsValues = true;
      this.unlimitedSmsesValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });
    } else {
      this.schoolApprovalForm.controls['unlimitedSms'].enable();
      this.isUnlimitedSmsValues = false;
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      // this.settingCheckList = this.settingCheckList.filter((unlimitedSms) => {
      //   return unlimitedSms.settingName !== 'UL';
      // });
      // this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited sms
      this.unlimitedSmsesValue = '';
    }
  }
  // getting perSmsCostValue
  public perSmsCostValue(event, value) {
    if (value === this.numberSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberSmsValue;
        }
      });
    }
    if (value === this.perSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.perSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.perSmsValue;
        }
      });
    }
  }

  // enable erp base on show checkboxList
  public changEnableErp(event, value, additionalModule) {
    const isCheckedEvent = event.target.checked;
    if (event.target.checked === true) {
      this.instituteSandbox.isCheckedAdditionalModule(isCheckedEvent);
      this.enableErpValue = true;
      this.settingCheckList.push({settingName: value, settingValue: ''});
      // pushing the checked Data
      additionalModule.forEach(data => {
        this.settingCheckList.push({settingName: data.settingsName , settingValue: ''});
      });
    } else {
      this.instituteSandbox.isCheckedAdditionalModule(isCheckedEvent);
      this.enableErpValue = false;
      // checking parent checkBox
      this.settingCheckList = this.settingCheckList.filter(enableErp => {
        return enableErp.settingName !== value;
      });
      // removing the unchecked Data
      additionalModule.forEach(data => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (checkedValue.settingName === data.settingsName) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    console.log('settingCheckList', this.settingCheckList);
  }

  // storing the value of erpManagementCheckbox
  public erpManegementCheckbox(event, value) {
    debugger;
    if (event.target.checked === true) {
      // value.isChecked = true;
      this.settingCheckList.push({settingName: value.settingsName, settingValue: ''});
    }
    if (event.target.checked === false) {
      // value.isChecked = false;
      this.settingUncheckList.push({settingName: value.settingsName, settingValue: ''});
      this.settingUncheckList.forEach(uncheckedValue => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (uncheckedValue.settingName === checkedValue.settingName) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    this.settingUncheckList = [];
    console.log(this.settingCheckList, 'ch');
  }


  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
