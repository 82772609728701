export class PocLiveListModel {

    public instituteType: number;
    public limit: number;
    public offset: string;
    public keyword: string;


    constructor(pocLiveListModel: any) {
        this.instituteType = pocLiveListModel.instituteType || '';
        this.limit = pocLiveListModel.limit || '';
        this.offset = pocLiveListModel.offset || '';
        this.keyword = pocLiveListModel.keyword || '';
    }
}
