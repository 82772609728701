import * as actions from '../actions/notifications.actions';
import { NotificationsState, notificationsRecord } from '../reducers/notifications.state';

export const initialState: NotificationsState = new notificationsRecord() as unknown as NotificationsState;

export function  reducer(state = initialState, {type, payload}: any): NotificationsState {

  if (!type) {

    return state;
  }
  switch (type) {


// get wishlist
case actions.ActionTypes.GET_WISHLIST:
{
  return Object.assign({}, state, {
    wishlistLoading: true,
    wishlistLoaded: false,
    wishlistFailed: false,
  });
}
case actions.ActionTypes.GET_WISHLIST_SUCCESS:
{
  return Object.assign({}, state, {
    wishlist: payload.data,
    wishlistLoading: false,
    wishlistLoaded: true,
    wishlistFailed: false,
  });
}
case actions.ActionTypes.GET_WISHLIST_FAIL:
{
  return Object.assign({}, state, {
    wishlistLoading: false,
    wishlistLoaded: false,
    wishlistFailed: true,
  });
}


// get feedback list
case actions.ActionTypes.GET_FEEDBACK_LIST:
{
  return Object.assign({}, state, {
    // feedbackList: [],
    feedbackListLoading: true,
    feedbackListLoaded: false,
    feedbackListFailed: false,
  });
}
case actions.ActionTypes.GET_FEEDBACK_LIST_SUCCESS:
{
  return Object.assign({}, state, {
    feedbackList: payload.data,
    feedbackListLoading: false,
    feedbackListLoaded: true,
    feedbackListFailed: false,
  });
}
case actions.ActionTypes.GET_FEEDBACK_LIST_FAIL:
{
  return Object.assign({}, state, {
    feedbackListLoading: false,
    feedbackListLoaded: false,
    feedbackListFailed: true,

  });
}

// get feedback count
case actions.ActionTypes.FEEDBACK_COUNT:
{
  return Object.assign({}, state, {
    feedbackCountLoading: true,
    feedbackCountLoaded: false,
    feedbackCountFailed: false,
  });
}
case actions.ActionTypes.FEEDBACK_COUNT_SUCCESS:
{
  return Object.assign({}, state, {
    feedbackCount: payload.data,
    feedbackCountLoading: false,
    feedbackCountLoaded: true,
    feedbackCountFailed: false,
  });
}
case actions.ActionTypes.FEEDBACK_COUNT_FAIL:
{
  return Object.assign({}, state, {
    feedbackCountLoading: false,
    feedbackCountLoaded: false,
    feedbackCountFailed: true,

  });
}

// wishlist count
case actions.ActionTypes.WISHLIST_COUNT:
{
  return Object.assign({}, state, {
    wishlistCountLoading: true,
    wishlistCountLoaded: false,
    wishlistCountFailed: false,
  });
}
case actions.ActionTypes.WISHLIST_COUNT_SUCCESS:
{
  return Object.assign({}, state, {
    wishlistCount: payload.data,
    wishlistCountLoading: false,
    wishlistCountLoaded: true,
    wishlistCountFailed: false,
  });
}
case actions.ActionTypes.WISHLIST_COUNT_FAIL:
{
  return Object.assign({}, state, {
    wishlistCountLoading: false,
    wishlistCountLoaded: false,
    wishlistCountFailed: true,

  });
}

// wishlist count
case actions.ActionTypes.GET_DEACTIVATED_LIST:
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolList: [],
    GetDeactivatedSchoolListLoading: true,
  });
}
case actions.ActionTypes.GET_DEACTIVATED_LIST_SUCCESS:
  console.log('GET_DEACTIVATED_LIST_SUCCESS', payload.data)
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolList: payload.data,
    GetDeactivatedSchoolListLoading: false,
  });
}
case actions.ActionTypes.GET_DEACTIVATED_LIST_FAIL:
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolListLoading: false,
  });
}

// wishlist count
case actions.ActionTypes.GET_DEACTIVATED_LIST_COUNT:
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolListCount: 0,
    GetDeactivatedSchoolListCountLoading: true,
  });
}
case actions.ActionTypes.GET_DEACTIVATED_LIST_SUCCESS_COUNT:
  console.log('GET_DEACTIVATED_LIST_SUCCESS_COUNT', payload.data)
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolListCount: payload.data,
    GetDeactivatedSchoolListCountLoading: false,
  });
}
case actions.ActionTypes.GET_DEACTIVATED_LIST_FAIL_COUNT:
{
  return Object.assign({}, state, {
    GetDeactivatedSchoolListCountLoading: false,
  });
}


// wishlist count
case actions.ActionTypes.DEACTIVATE_SCHOOL:
{
  return Object.assign({}, state, {
    DeactivateSchool: {},
    DeactivateSchoolLoading: true,
  });
}
case actions.ActionTypes.DEACTIVATE_SCHOOL_SUCCESS:
{
  return Object.assign({}, state, {
    DeactivateSchool: payload,
    DeactivateSchoolLoading: false,
  });
}
case actions.ActionTypes.DEACTIVATE_SCHOOL_FAIL:
{
  return Object.assign({}, state, {
    DeactivateSchoolLoading: false,
  });
}

    default: {
      return state;
    }
  }
}

export const wishlist = (state: NotificationsState) => state.wishlist;
export const wishlistLoading = (state: NotificationsState) => state.wishlistLoading;
export const wishlistLoaded = (state: NotificationsState) => state.wishlistLoaded;
export const wishlistFailed = (state: NotificationsState) => state.wishlistFailed;

export const feedbackList = (state: NotificationsState) => state.feedbackList;
export const feedbackListLoading = (state: NotificationsState) => state.feedbackListLoading;
export const feedbackListLoaded = (state: NotificationsState) => state.feedbackListLoaded;
export const feedbackListFailed = (state: NotificationsState) => state.feedbackListFailed;

export const wishlistCount = (state: NotificationsState) => state.wishlistCount;
export const wishlistCountLoading = (state: NotificationsState) => state.wishlistCountLoading;
export const wishlistCountLoaded = (state: NotificationsState) => state.wishlistCountLoaded;
export const wishlistCountFailed = (state: NotificationsState) => state.wishlistCountFailed;
// get feedback count
export const feedbackCount = (state: NotificationsState) => state.feedbackCount;
export const feedbackCountLoading = (state: NotificationsState) => state.feedbackCountLoading;
export const feedbackCountLoaded = (state: NotificationsState) => state.feedbackCountLoaded;
export const feedbackCountFailed = (state: NotificationsState) => state.feedbackCountFailed;

export const GetDeactivatedSchoolList = (state: NotificationsState) => state.GetDeactivatedSchoolList;
export const GetDeactivatedSchoolListLoading = (state: NotificationsState) => state.GetDeactivatedSchoolListLoading;

export const GetDeactivatedSchoolListCount = (state: NotificationsState) => state.GetDeactivatedSchoolListCount;
export const GetDeactivatedSchoolListCountLoading = (state: NotificationsState) => state.GetDeactivatedSchoolListCountLoading;

export const DeactivateSchool = (state: NotificationsState) => state.DeactivateSchool;
export const DeactivateSchoolLoading = (state: NotificationsState) => state.DeactivateSchoolLoading;