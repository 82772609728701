import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
@Input('audioFileData') audioFileData: any;
@Input() Play: false;
  constructor() { }

  ngOnInit() {
    console.log('audioFileData',  this.audioFileData);
  }

}
