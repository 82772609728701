import * as actions from '../action/auth.action';
import {AuthState, AuthStateRecord} from './auth.state';
// Model

export const initialState: AuthState = new AuthStateRecord() as AuthState;
export function reducer(state = initialState, {type, payload}: any): AuthState {
    if (!type) {
        return state;
    }
    switch (type) {
        case actions.ActionTypes.DO_LOGIN: {
            return Object.assign({}, state, {
                loginSuccess: {},
                loginRequestLoading: true,
                loginRequestLoaded: false,
                loginRequestFailed: false,
            });
        }

        case actions.ActionTypes.DO_LOGIN_SUCCESS: {
            return Object.assign({}, state, {
                // loginSuccess: new LoginResponseModel(payload),
                loginSuccess: payload,
                loginRequestLoading: false,
                loginRequestLoaded: true,
                loginRequestFailed: false,
            });
        }


        case actions.ActionTypes.DO_LOGIN_FAIL: {
            return Object.assign({}, state, {
                loginSuccess: {},
                loginRequestLoading: false,
                loginRequestLoaded: true,
                loginRequestFailed: true,
            });
        }
        case actions.ActionTypes.DO_FORGOT_PASSWORD: {
            return Object.assign({}, state, {
                forgotPassword: {},
                forgotPasswordRequestLoading: true,
                forgotPasswordRequestLoaded: false,
                forgotPasswordRequestFailed: false,
            });
        }


        case actions.ActionTypes.DO_FORGOT_PASSWORD_SUCCESS: {
            return Object.assign({}, state, {
                forgotPassword: payload,
                forgotPasswordRequestLoading: false,
                forgotPasswordRequestLoaded: true,
                forgotPasswordRequestFailed: false,
            });
        }

        case actions.ActionTypes.DO_FORGOT_PASSWORD_FAIL: {
            return Object.assign({}, state, {
                forgotPassword: {},
                forgotPasswordRequestLoading: false,
                forgotPasswordRequestLoaded: true,
                forgotPasswordRequestFailed: true,
            });
        }
       // forgot password url
        case actions.ActionTypes.DO_FORGOT_PASSWORD_URL: {
            return Object.assign({}, state, {
                forgotPasswordUrl: {},
                forgotPasswordUrlLoading: true,
                forgotPasswordUrlLoaded: false,
                forgotPasswordUrlFailed: false,
            });
        }


        case actions.ActionTypes.DO_FORGOT_PASSWORD_URL_SUCCESS: {
            return Object.assign({}, state, {
                forgotPasswordUrl: payload,
                forgotPasswordUrlLoading: false,
                forgotPasswordUrlLoaded: true,
                forgotPasswordUrlFailed: false,
            });
        }

        case actions.ActionTypes.DO_FORGOT_PASSWORD_URL_FAIL: {
            return Object.assign({}, state, {
                forgotPasswordUrl: {},
                forgotPasswordUrlLoading: false,
                forgotPasswordUrlLoaded: false,
                forgotPasswordUrlFailed: true,
            });
        }
        // Reset password
        case actions.ActionTypes.DO_RESET_PASSWORD: {
            return Object.assign({}, state, {
                resetPassword: {},
                resetPasswordLoading: true,
                resetPasswordLoaded: false,
                resetPasswordFailed: false,
            });
        }


        case actions.ActionTypes.DO_RESET_PASSWORD_SUCCESS: {
            return Object.assign({}, state, {
                resetPassword: payload,
                resetPasswordLoading: false,
                resetPasswordLoaded: true,
                resetPasswordFailed: false,
            });
        }

        case actions.ActionTypes.DO_RESET_PASSWORD_FAIL: {
            return Object.assign({}, state, {
                resetPassword: {},
                resetPasswordLoading: false,
                resetPasswordLoaded: false,
                resetPasswordFailed: true,
            });
        }
        default: {
            return state;
        }
    }
}
export const getLoginSuccessResponse = (state: AuthState) => state.loginSuccess;
export const getLoginRequestLoading = (state: AuthState) => state.loginRequestLoading;
export const getLoginRequestLoaded = (state: AuthState) => state.loginRequestLoaded;
export const getLoginRequestFailed = (state: AuthState) => state.loginRequestFailed;

export const getForgotPassword = (state: AuthState) => state.forgotPassword;
export const getForgotPasswordRequestLoading = (state: AuthState) => state.forgotPasswordRequestLoading;
export const getForgotPasswordRequestLoaded = (state: AuthState) => state.forgotPasswordRequestLoaded;
export const getForgotPasswordRequestFailed = (state: AuthState) => state.forgotPasswordRequestFailed;

export const getForgotPasswordUrl = (state: AuthState) => state.forgotPasswordUrl;
export const getForgotPasswordUrlLoading = (state: AuthState) => state.forgotPasswordUrlLoading;
export const getForgotPasswordUrlLoaded = (state: AuthState) => state.forgotPasswordUrlLoaded;
export const getForgotPasswordUrlFailed = (state: AuthState) => state.forgotPasswordUrlFailed;

export const getResetPassword = (state: AuthState) => state.resetPassword;
export const getResetPasswordLoading = (state: AuthState) => state.resetPasswordLoading;
export const getResetPasswordLoaded = (state: AuthState) => state.resetPasswordLoaded;
export const getResetPasswordFailed = (state: AuthState) => state.resetPasswordFailed;



