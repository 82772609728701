import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ReportSandbox } from 'src/app/pages/reports/report.sandbox';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-renew-school',
  templateUrl: './view-renew-school.component.html',
  styleUrls: ['./view-renew-school.component.scss']
})
export class ViewRenewSchoolComponent implements OnInit {
  schoolDetails: any;
  renewReport: any;
  isPrintValue = false;
  sameReportDetail: any;
  isChecked = true;
  isSubscribe:any;
  isInterest:any;
  constructor(public activeModal: NgbActiveModal, public reportSandbox: ReportSandbox) { }

  currentDate = '';
  ngOnInit() {
    console.log("schoolDetails",this.schoolDetails)
    if(this.schoolDetails.is_subscription == 1){
      console.log("inn")
      this.isInterest = 'Yes'
    }
    if(this.isSubscribe !=1 ){
    this.currentDate = moment(this.schoolDetails.created_on).format('DD MMMM YYYY')
    this.isChecked = true;

    this.schoolDetails.erp_modules = JSON.parse(this.schoolDetails.erp_modules);

    this.schoolDetails.erp_modules = this.schoolDetails.erp_modules.filter(val => {
      return val.isEnabled == true;
    });

    this.schoolDetails.payment_cycle_details = JSON.parse(this.schoolDetails.payment_cycle_details);
  }
}
  printReport(data) {
    this.isPrintValue = !this.isPrintValue;
    this.renewReport = data;
    this.sameReportDetail = this.isPrintValue;
  }

  private subscriptions: Array<Subscription> = [];
  Verify() {
    this.reportSandbox.verifyRenewalList({ id: this.schoolDetails.id, isReject: 0 });
    this.subscriptions.push(this.reportSandbox.verifyRenewalForm$.subscribe(data => {
      if (data && data.status == 1) {
        this.activeModal.close('success');
      }
    }));
  }
  close() {
    this.activeModal.close();
  }
}
