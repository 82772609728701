import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Api} from '../../services/Api';

@Injectable()
export class UserProfileService extends Api {
    url = environment.baseUrl;

    // calling Change Password API
    public changePassword(params): Observable<any> {
        return this.http.post(this.url + '/auth/change-user-password', params);
    }
}
