import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as actions from '../actions/common.action';
import { CommonService } from '../common.service';



/**
 * Effects offer a way to isolate and easily test side-effects within your
 * application. StateUpdates is an observable of the latest state and
 * dispatched action. The `toPayload` helper function returns just
 * the payload of the currently dispatched action, useful in
 * instances where the current state is not necessary.
 *
 * If you are unfamiliar with the operators being used in these examples, please
 * check out the sources below:
 *
 * Official Docs: http://reactivex.io/rxjs/manual/overview.html#categories-of-operators
 * RxJS 5 Operators By Example: https://gist.github.com/btroncone/d6cf141d6f2c00dc6b35
 */

@Injectable()
export class CommonEffect {
  /**
   * subject list effect
   */
  @Effect()
  getNotificationCount$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.NOTIFICATION_COUNT),
    map((action: actions.NotificationCount) => action.payload),
    switchMap(state => {
      return this.api.getNotificationCount(state).pipe(
        map(user => new actions.NotificationCountSuccess(user)),
        catchError(error => of(new actions.NotificationCountFail(error.error)))
      );
    })
  );

  @Effect()
  notificationList$: Observable<Action> = this.action$.pipe(
    ofType(actions.ActionTypes.NOTIFICATION_LIST),
    map((action: actions.NotificationList) => action.payload),
    switchMap(state => {
      return this.api.getNotificationList(state).pipe(
        map(user => new actions.NotificationListSuccess(user)),
        catchError(error => of(new actions.NotificationListFail(error.error)))
      );
    })
  );
  constructor(
    private action$: Actions,
    protected api: CommonService,
    public router: Router
  ) {}
}
