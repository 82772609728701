import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InstituteManagementSandbox} from '../../../../pages/institute-mangements/institute-management.sandbox';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit, OnDestroy {
  public erpLists = [
    {label: 'Basic Settings', id: 'basic_settings', isChecked: false},
    {label: 'Certificates', id: 'certificates', isChecked: false},
    {label: 'Dashboard', id: 'dashboard', isChecked: false},
    {label: 'Exam Management', id: 'exam_management', isChecked: false},
    {label: 'Extra-Curricular Manage', id: 'extra_curricular_manage', isChecked: false},
    {label: 'Fee Management', id: 'fee_management', isChecked: false},
    {label: 'Fees Reports', id: 'fees_reports', isChecked: false},
    {label: 'Finance', id: 'finance', isChecked: false},
    {label: 'Hostel Management System', id: 'hostel_management_system', isChecked: false},
    {label: 'ID Card Generator', id: 'id_card_generator', isChecked: false},
    {label: 'Inventory', id: 'inventory', isChecked: false},
    {label: 'Library Management', id: 'library_management', isChecked: false},
    {label: 'Library Management System', id: 'library_management_system', isChecked: false},
    {label: 'Main Admissions', id: 'main_admissions', isChecked: false},
    {label: 'Other Staff', id: 'other_staff', isChecked: false},
    {label: 'Parents', id: 'parents', isChecked: false},
    {label: 'Payroll Management System', id: 'payroll_management_system', isChecked: false},
    {label: 'Reports / Export Datas', id: 'reports_export_datas', isChecked: false},
    {label: 'Sports Management', id: 'sports_management', isChecked: false},
    {label: 'Staff Management', id: 'staff_management', isChecked: false},
    {label: 'Standard and Section Management', id: 'standard_and_section_management', isChecked: false},
    {label: 'Students', id: 'students', isChecked: false},
    {label: 'Students Promotion', id: 'students_promotion', isChecked: false},
    {label: 'Today Birthday', id: 'today_birthday', isChecked: false},
    {label: 'User Permission', id: 'user_permission', isChecked: false},
    {label: 'Vehicle Manage Report', id: 'vehicle_manage_report', isChecked: false},
    {label: 'Vehicle management', id: 'vehicle_management', isChecked: false},
  ];
  // form Variable
  public schoolVerifyForm: FormGroup;
  // approval model variable
  public erpValue = 'enable_erp';
  public voiceValue = 'enable_voice';
  public smsValue = 'enable_sms';
  public unlimitedsmsValue = 'unlimited_sms';
  public perSmsValue = 'per_sms_cost';
  public costValue = 'cost';
  public noOfCallsValue = 'unlimited_calls';
  public minuteValues = 'number_of_minutes';
  public minuteCostValue = 'minutes_cost';
  numberOfsmsValue = 'number_of_sms';
  numberSmsValue = 'number_sms_cost';
  public settingCheckList: any = [];
  public settingUncheckList: any = [];
  public settingsArray: any = [];
  public enableVoiceValue = false;
  public enableSmsValue = false;
  public unlimitedMinuteValue: any;
  public unlimitedCallsValue: any;
  public perCallCostValue: any;
  public unlimitedSmsesValue: any;
  public perSmsesCostValue: any;
  public prepaidPostpaidValue = 1;
  public enableErpValue = false;
  public verifyId: any;
  public endDate: any;
  public perminuteCostValue: any;
  isUnlimitedCallValue = false;
  isUnlimitedSmsValues = false;
  numberOfSmscostValue: string;
  numberOfSMSValue: string;
  enableUnlimitSms = false;
  enableunlimitedNoOfCalls = false;
  unlimitedSmsName: any;
  userDetailsData: any = {};
  private subscriptions: Array<Subscription> = [];

  constructor(
      public activeModal: NgbActiveModal,
      public instituteSandbox: InstituteManagementSandbox,
      public fb: FormBuilder,
      public modals: NgbModal
  ) { }

  ngOnInit() {
    this.userDetailsData = JSON.parse(localStorage.getItem('userdetails'));
    this.UploadApprovalForm();
    if (this.verifyId) {
      this.editVerifyForm();
    }
  }

  // intial approvalForm
  public UploadApprovalForm() {
    this.schoolVerifyForm = this.fb.group({
      pocLive: [],
      prepaidPostPaid: [],
      validFrom: [],
      validTo: ['', Validators.required],
      remarks: []
    });
    this.subscriptions.push(this.schoolVerifyForm.controls['validFrom'].valueChanges.subscribe(fromDate => {
      this.endDate = fromDate;
    }));
  }

  // comPaire two date validation
  comparetwoDates(fromDate: string, toDate: string) {
    return (group: FormGroup) => {
      const statingDate = group.controls[fromDate];
      const endingDate = group.controls[toDate];
      if (statingDate.value > endingDate.value) {
        return endingDate.setErrors({dateError: true});
      }
    };
  }
  // submit the approved form
  public onSubmitVerify() {
    if (this.schoolVerifyForm.valid) {
      const params: any = {};
      if ( this.userDetailsData.user.roleSlugName === 'sup_admin') {
        params.verifyOrApprove = 1;
      }
      params.instituteId = this.verifyId;
      params.pocorlive = this.schoolVerifyForm.value.pocLive;
      params.prepaidorpostpaid = this.schoolVerifyForm.value.prepaidPostPaid;
      params.validFrom = this.schoolVerifyForm.value.validFrom;
      params.validTo = this.schoolVerifyForm.value.validTo;
      params.settings = this.settingCheckList;
      params.remark = this.schoolVerifyForm.value.remarks;
      console.log(params, 'para');
      this.instituteSandbox.approveInstitute(params);
      this.closePopup();
    }
  }

// subscribe the approve status and close popup
  closePopup() {
    this.subscriptions.push(this.instituteSandbox.instituteApproval$.subscribe(data => {
      if (data && data.status === 1) {
        this.activeModal.close();
      }
    }));
  }
  // setting schoolRenewalForm value
  public editVerifyForm() {
    this.subscriptions.push(this.instituteSandbox.instituteDetail$.subscribe((details) => {
      if (details) {
        details.settingsData.forEach(data => {
          this.settingCheckList.push({settingName: data.settings_name, settingValue: data.settings_value});
        });
        this.schoolVerifyForm.controls['pocLive'].setValue(details.instituteData.institutePocLive);
        this.schoolVerifyForm.controls['prepaidPostPaid'].setValue(details.instituteData.institutePrepaidOrPostpaid);
        this.schoolVerifyForm.controls['validFrom'].setValue(details.instituteData.subscriptionFrom);
        this.schoolVerifyForm.controls['validTo'].setValue(details.instituteData.subscriptionTo);
        this.endDate = new Date(details.instituteData.subscriptionFrom);
        details.settingsData.forEach(settingsData => {
          this.settingsArray = settingsData;
          if (this.settingsArray.settings_name === this.perSmsValue) {
            // this.enableperSmsCost = true;
            this.perSmsesCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue) {
            // this.enableUnlimitSms = true;
            this.unlimitedSmsesValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.smsValue) {
            this.enableSmsValue = true;
          }
          if (this.settingsArray.settings_name === this.voiceValue) {
            this.enableVoiceValue = true;
          }
          if (this.settingsArray.settings_name === this.erpValue) {
            this.enableErpValue = true;
          }
          if (this.settingsArray.settings_name === this.minuteValues) {
            this.unlimitedMinuteValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue) {
            this.unlimitedCallsValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.costValue) {
            //   this.enablecostValue = true;
            this.perCallCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.minuteCostValue) {
            // this.enablecostValue = true;
            this.perminuteCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === 'UL') {
            this.enableunlimitedNoOfCalls = true;
            this.isUnlimitedCallValue = true;
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === '') {
            this.enableunlimitedNoOfCalls = false;
            this.unlimitedCallsValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === 'UL') {
            this.enableUnlimitSms = true;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === '') {
            this.enableUnlimitSms = false;
            this.enableUnlimitSms = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.numberSmsValue) {
            this.numberOfSmscostValue = this.settingsArray.settings_value;
          }
          this.erpLists.forEach(erpList => {
            if (this.settingsArray.settings_name === erpList.id) {
              erpList.isChecked = true;
            }
          });
        });

      }
    }));
  }

  // while close approved modal
  approvedClose() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.settingCheckList = [];
    this.perSmsesCostValue = '';
    this.unlimitedSmsesValue = '';
    this.perCallCostValue = '';
    this.unlimitedCallsValue = '';
    this.unlimitedMinuteValue = '';
  }

  // change postpaidprepaid
  public postpaidprepaid(event) {
    const prepaidPostpaid = +event.value;
    if (prepaidPostpaid === 1) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.unlimitedsmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.perSmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      this.unlimitedMinuteValue = '';
      this.perCallCostValue = '';
      this.perSmsesCostValue = '';
    } else {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      this.unlimitedCallsValue = '';
      this.perminuteCostValue = '';
      this.numberOfSmscostValue = '';
      this.numberOfSMSValue = '';
      this.enableUnlimitSms = false;
      this.enableunlimitedNoOfCalls = false;
    }
  }

  // change enable voice
  public changeEnableVoice(event, value) {
    if (event.target.checked === true) {
      this.enableVoiceValue = true;
      this.settingCheckList.push(
          {settingName: value, settingValue: ''},
          {settingName: this.minuteValues, settingValue: ''},
          {settingName: this.noOfCallsValue, settingValue: ''},
          {settingName: this.minuteCostValue, settingValue: ''},
          {settingName: this.costValue, settingValue: ''});
    }
    if (event.target.checked === false) {
      this.enableVoiceValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableVoice => {
        return (enableVoice.settingName !== value && enableVoice.settingName !== this.minuteValues && enableVoice.settingName !== this.costValue && enableVoice.settingName !== this.noOfCallsValue && enableVoice.settingName !== this.minuteCostValue);
      });
      // clear text field
      this.unlimitedCallsValue = '';
      this.perCallCostValue = '';
      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unlimited call value
  public unlimitedCallValue(event, value) {
    if (value === this.minuteValues) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteValues;
        }
      });
    }
    if (value === this.noOfCallsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.noOfCallsValue;
        }
      });
    }
  }

  // change unlimitedCalls
  public changeUnlimitedCall(event, value) {
    // this.unlimitedCallName = value;
    if (event.target.checked === true) {
      this.isUnlimitedCallValue = true;
      this.unlimitedCallsValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });
      // this.settingCheckList.push({settingName: value, settingValue: 'UL'});
    } else {
      this.isUnlimitedCallValue = false;
      this.settingCheckList = this.settingCheckList.filter((unLimted) => {
        return unLimted.settingValue !== 'UL';
      });
      this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited Call
      this.unlimitedCallsValue = '';
    }
  }

  // getting per call cost Value
  public perCallsCostValue(event, value) {
    if (value === this.costValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.costValue;
        }
      });
    }
    if (value === this.minuteCostValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteCostValue;
        }
      });
    }
  }

  // enable Sms change
  public changeEnableSms(event, value) {
    if (event.target.checked === true) {
      this.enableSmsValue = true;
      this.settingCheckList.push(
          {settingName: value, settingValue: ''},
          {settingName: this.unlimitedsmsValue, settingValue: ''},
          {settingName: this.numberOfsmsValue, settingValue: ''},
          {settingName: this.numberSmsValue, settingValue: ''},
          {settingName: this.perSmsValue, settingValue: ''});
    } else {
      this.enableSmsValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableSms => {
        return (enableSms.settingName !== value && enableSms.settingName !== this.unlimitedsmsValue && enableSms.settingName !== this.perSmsValue && enableSms.settingName !== this.numberOfsmsValue && enableSms.settingName !== this.numberSmsValue);
      });
      // clear text field
      this.unlimitedSmsesValue = '';
      this.perSmsesCostValue = '';
      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unLimitedSms value
  public unLimitedSmsValue(event, value) {
    if (value === this.numberOfsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberOfsmsValue;
        }
      });
    }
    if (value === this.unlimitedsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.unlimitedsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.unlimitedsmsValue;
        }
      });
    }
  }
// change unlimited Sms checkbox
  public changeUnlimitedSms(event, value) {
    this.unlimitedSmsName = value;
    if (event.target.checked === true) {
      this.isUnlimitedSmsValues = true;
      this.unlimitedSmsesValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });        } else {
      this.isUnlimitedSmsValues = false;
      this.settingCheckList = this.settingCheckList.filter((unlimitedSms) => {
        return unlimitedSms.settingName !== 'UL';
      });
      this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited sms
      this.unlimitedSmsesValue = '';
    }
  }
  // getting perSmsCostValue
  public perSmsCostValue(event, value) {
    if (value === this.numberSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberSmsValue;
        }
      });
    }
    if (value === this.perSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.perSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.perSmsValue;
        }
      });
    }
  }

  // enable erp base on show checkboxList
  public changEnableErp(event, value) {
    if (event.target.checked === true) {
      this.enableErpValue = true;
      this.settingCheckList.push({settingName: value, settingValue: ''});
    } else {
      this.enableErpValue = false;
      // checking parent checkBox
      this.settingCheckList = this.settingCheckList.filter(enableErp => {
        return enableErp.settingName !== value;
      });
      // checking child data
      this.erpLists.forEach(erpList => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (checkedValue.settingName === erpList.id) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    console.log('settingCheckList', this.settingCheckList);
  }

  // storing the value of erpManagementCheckbox
  public erpManagementCheckbox(event, value) {
    if (event.target.checked === true) {
      // value.isChecked = true;
      this.settingCheckList.push({settingName: value.id, settingValue: ''});
    }
    if (event.target.checked === false) {
      // value.isChecked = false;
      this.settingUncheckList.push({settingName: value.id, settingValue: ''});
      this.settingUncheckList.forEach(uncheckedValue => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (uncheckedValue.settingName === checkedValue.settingName) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    this.settingUncheckList = [];
    console.log(this.settingCheckList, 'ch');
  }


  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
