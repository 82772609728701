import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility/utilityHelpers';
import { ResponsInterface } from '../../../response-model/response-interface';

export const ActionTypes = {
  
    SERVER_DETAIL_LIST: type('[SERVER] SERVER_DETAIL_LIST'),
    SERVER_DETAIL_LIST_SUCCESS: type('[SERVER] SERVER_DETAIL_LIST Success'),
    SERVER_DETAIL_LIST_FAIL: type('[SERVER] SERVER_DETAIL_LIST Fail'),

    SERVER_ACTIVITY_LOG: type('[SERVER] SERVER_ACTIVITY_LOG'),
    SERVER_ACTIVITY_LOG_SUCCESS: type('[SERVER] SERVER_ACTIVITY_LOG Success'),
    SERVER_ACTIVITY_LOG_FAIL: type('[SERVER] SERVER_ACTIVITY_LOG Fail'),



};
// report ACTION
export class ServerDetailListAction implements Action {
    type = ActionTypes.SERVER_DETAIL_LIST;

    constructor(public payload = null) {
    }
}
export class ServerDetailListSuccess implements Action {
    type = ActionTypes.SERVER_DETAIL_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class ServerDetailListFail implements Action {
    type = ActionTypes.SERVER_DETAIL_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}

export class ServerActivityLog implements Action {
    type = ActionTypes.SERVER_ACTIVITY_LOG;

    constructor(public payload :any) {
    }
}
export class ServerActivityLogSuccess implements Action {
    type = ActionTypes.SERVER_ACTIVITY_LOG_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class ServerActivityLogFail implements Action {
    type = ActionTypes.SERVER_ACTIVITY_LOG_FAIL;

    constructor(public payload: any = null) {
    }
}



export type Actions
    = ServerDetailListAction
    | ServerDetailListSuccess
    | ServerDetailListFail
     
    | ServerActivityLog
    | ServerActivityLogSuccess
    | ServerActivityLogFail


