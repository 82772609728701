export class UpdateUserModel {
    public firstName: string;
    public lastName: string;
    public loginId: string;
    public password?: string;
    public status: Number;
    public roleName: string;
    public contactNo: number;
    public id: number;
    public image: any;

    constructor( updateUser: any) {
        this.firstName = updateUser.firstName || '';
        this.lastName = updateUser.lastName || '';
        this.loginId = updateUser.userId || '';
        this.password = updateUser.password || '';
        this.status = updateUser.status   || 0;
        this.roleName = updateUser.role   || '';
        this.id = updateUser.id   || '';
        this.contactNo = updateUser.contactNo   || '';
        this.image = updateUser.image   || '';


    }
}
