import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility/utilityHelpers';
import { ResponsInterface } from '../../../response-model/response-interface';



export const ActionTypes = {

    GET_USER_ROLE: type('[GetUserRole] GetUserRole'),
    GET_USER_ROLE_SUCCESS: type('[GetUserRole] GetUserRole success'),
    GET_USER_ROLE_FAIL: type('[GetUserRole] GetUserRole fail'),

    CREATE_USER: type('[CreateUser] CreateUser'),
    CREATE_USER_SUCCESS: type('[CreateUser] CreateUser success'),
    CREATE_USER_FAIL: type('[CreateUser] CreateUser fail'),

    GET_USER_LIST: type('[GetUserList] GetUserList'),
    GET_USER_LIST_SUCCESS: type('[GetUserList] GetUserList success'),
    GET_USER_LIST_FAIL: type('[GetUserList] GetUserList fail'),


    DELETE_USER: type('[DeleteUser] DeleteUser'),
    DELETE_USER_SUCCESS: type('[DeleteUser] DeleteUser success'),
    DELETE_USER_FAIL: type('[DeleteUser] DeleteUser fail'),

  USER_DETAILS: type('[UserDetails] UserDetails'),
  USER_DETAILS_SUCCESS: type('[UserDetails] UserDetails success'),
  USER_DETAILS_FAIL: type('[UserDetails] UserDetails fail'),

  UPDATE_USER: type('[UpdateUser] UpdateUser'),
  UPDATE_USER_SUCCESS: type('[UpdateUser] UpdateUser success'),
  UPDATE_USER_FAIL: type('[UpdateUser] UpdateUser fail'),

  UNSUBSCRIBE_DETAILS: type('[Unsubscribe] Unsubscribe ')


};
  // GET USER ROLE
  export class GetUserRole implements Action {
    type = ActionTypes.GET_USER_ROLE;
    constructor(public payload: any = null) {
    }
  }
  export class GetUserRoleSuccess implements Action {
    type = ActionTypes.GET_USER_ROLE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
  }
  export class GetUserRoleFail implements Action {
    type = ActionTypes.GET_USER_ROLE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
  }


  // CREATE USER
  export class CreateUser implements Action {
    type = ActionTypes.CREATE_USER;
    constructor(public payload: any ) {
    }
  }
  export class CreateUserSuccess implements Action {
    type = ActionTypes.CREATE_USER_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
  }
  export class CreateUserFail implements Action {
    type = ActionTypes.CREATE_USER_FAIL;
    constructor(public payload: ResponsInterface) {
    }
  }

  // GET USER LIST
  export class GetUserList implements Action {
    type = ActionTypes.GET_USER_LIST;
    constructor(public payload: any ) {
    }
  }
  export class GetUserListSuccess implements Action {
    type = ActionTypes.GET_USER_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
  }
  export class GetUserListFail implements Action {
    type = ActionTypes.GET_USER_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
  }

  // DELETE USER
  export class DeleteUser implements Action {
    type = ActionTypes.DELETE_USER;
    constructor(public payload: any ) {
    }
  }
  export class DeleteUserSuccess implements Action {
    type = ActionTypes.DELETE_USER_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
  }
  export class DeleteUserFail implements Action {
    type = ActionTypes.DELETE_USER_FAIL;
    constructor(public payload: ResponsInterface) {
    }
  }
  // getting user Details
export class UserDetailsAction implements Action {
  type = ActionTypes.USER_DETAILS;
  constructor(public payload: any ) {
  }
}
export class UserDetailsSuccess implements Action {
  type = ActionTypes.USER_DETAILS_SUCCESS
  constructor(public payload: ResponsInterface) {
  }
}
export class UserDetailsFail implements Action {
  type = ActionTypes.USER_DETAILS_FAIL
  constructor(public payload: ResponsInterface) {
  }
}

// updating user Details
export class UserUpdateAction implements Action {
  type = ActionTypes.UPDATE_USER;
  constructor(public payload: any ) {
  }
}
export class UserUpdateSuccess implements Action {
  type = ActionTypes.UPDATE_USER_SUCCESS;
  constructor(public payload: ResponsInterface) {
  }
}
export class UserUpdateFail implements Action {
  type = ActionTypes.UPDATE_USER_FAIL;
  constructor(public payload: ResponsInterface) {
  }
}
// UNSUBSCRIBE delete
export class UnSubscribeAction implements Action {
  type = ActionTypes.UNSUBSCRIBE_DETAILS;
  constructor(public payload: any) {
  }
}
  export type Actions
    = GetUserRole
    |GetUserRoleSuccess
    |GetUserRoleFail

    |CreateUser
    |CreateUserSuccess
    |CreateUserFail

    |GetUserList
    |GetUserListSuccess
    |GetUserListFail

    |DeleteUser
    |DeleteUserSuccess
    |DeleteUserFail
    |UserDetailsSuccess
    |UserDetailsFail
      |UserUpdateAction
      |UserUpdateFail
      |UserUpdateSuccess
      |UnSubscribeAction
      |UserDetailsAction ;
