import {Map, Record} from 'immutable';

export interface CommonState extends Map<string, any> {
    notificationCount: any;
    notificationList: any;


    notificationListLoading: boolean;
    notificationListLoaded: boolean;
    notificationListFailed: boolean;

    notificationCountLoading: boolean;
    notificationCountLoaded: boolean;
    notificationCountFailed: boolean;
}

export const commonStateRecord = Record({
    notificationCount: [],
    notificationList: [],


    notificationListLoading: false,
    notificationListLoaded: false,
    notificationListFailed: false,

    notificationCountLoading: false,
    notificationCountLoaded: false,
    notificationCountFailed: false,
});
