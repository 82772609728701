
import { NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from './layout/layout.component';
import {SharedModule} from '../shared/shared.module';
import { ComponentsModule} from '../shared/components';
import {EffectsModule} from '@ngrx/effects';
import {CommonEffect} from './effects/common.effect';
import {CommonSandbox} from './common.sandbox';
import {CommonService} from './common.service';





export const CONTAINERS = {
    LayoutComponent,
};
@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        SharedModule,
        EffectsModule.forFeature([CommonEffect])
    ],
    declarations: [],
    providers: [ CommonSandbox, CommonService],
   // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContainerModule {
}
