import { ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {AppState as State} from './state.interface';
import * as fromAuth from './pages/auth/reducer/auth.reducer';
// tslint:disable-next-line:import-spacing
import * as fromUserProfile  from  './pages/user-profile/reducer/user.profile.reducer';
import * as fromUserManagement from './pages/user-managements/reducers/user-management.reducer';
import * as fromInstituteManagement from './pages/institute-mangements/reducer/institute-management.reducer';
import {environment} from '../environments/environment';
import * as fromNotifications from './pages/notifications/reducers/notifications.reducers';
import * as fromReports from './pages/reports/reducer/report.reducer';
import * as fromcommon from './common/reducer/common.reducer';
import * as fromServer from './pages/server/reducer/server.reducer'
import { fromJS } from 'immutable';


export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    userProfile: fromUserProfile.reducer,
    userMangement: fromUserManagement.reducer,
    instituteManagement: fromInstituteManagement.reducer,
    notifications: fromNotifications.reducer,
    reports: fromReports.reducer,
    common: fromcommon.reducer,
    server:fromServer.reducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
    return function (state: State, action: any): State {
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [ logger ]
    : [];
