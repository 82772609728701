
import {HttpClientModule} from '@angular/common/http';
import {
    HttpRequest,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
import {Api} from './Api';


export {
    Api,
    HttpRequest,
    HttpClientModule,
    HttpErrorResponse,
    HttpInterceptor,
    HttpResponse,
    HttpHandler,
    HttpEvent
};
