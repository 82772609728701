import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'app-print-renewal-report',
  templateUrl: './print-renewal-report.component.html',
  styleUrls: ['./print-renewal-report.component.scss']
})
export class PrintRenewalReportComponent implements  OnChanges {
  @Input() renewalReportData: any;
  @Input() sameReportDetails: any;
  constructor() {
  }

  ngOnChanges() {
    if (this.renewalReportData) {
      setTimeout(() => {
        let printContents, popupWin;
        printContents = document.getElementById('print').innerHTML;
        popupWin = window.open(
            '',
            '_blank',
            'top=0,left=0,height=100%,width=auto'
        );
        popupWin.document.open();
        popupWin.window.focus();
        popupWin.document.write(`
          <html>
            <head>
              <style>
              </style>
            </head>
            <body onload="window.print();window.close()">${printContents}</body>
          </html>`);
        popupWin.document.close();
      }, 400);
    }
  }
}
