export class CreateUserModel {
    public firstName: string;
    public lastName: string;
    public loginId: string;
    public password: string;
    public status: Number;
    public roleName: string;
    public createdBy: Number;
    public contactNo: number;
    public image: File;


    constructor( createUser: any) {
        this.firstName = createUser.firstName || '';
        this.lastName = createUser.lastName || '';
        this.loginId = createUser.userId || '';
        this.password = createUser.password || '';
        this.status = createUser.status   || 0;
        this.roleName = createUser.role   || '';
        this.createdBy = createUser.createdBy   || '';
        this.contactNo = createUser.contactNo   || '';
        this.image = createUser.image   || '';

    }
}
