import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RevertStudentComponent } from '../../popup/revert-student/revert-student.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deleted-student-list',
  templateUrl: './deleted-student-list.component.html',
  styleUrls: ['./deleted-student-list.component.scss']
})
export class DeletedStudentListComponent implements OnInit {

  constructor(public modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  revertStudent() {
    const modalRef = this.modalService.open(RevertStudentComponent, {
      windowClass: 'revertStudent', centered: true});
      modalRef.componentInstance.standardDetails = '';
      modalRef.result.then((result) => {
        if (result) {
          // -----
        }
      });
  }
  close() {
    this.activeModal.close();
  }

}
