import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpResponse
} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastrManager} from 'ng6-toastr-notifications';
import {Router} from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    protected userTokenDetail: any = {};

    constructor(protected toastr: ToastrManager, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        this.userTokenDetail = localStorage.getItem('userdetails') ? JSON.parse(localStorage.getItem('userdetails')) : {};
        if (this.userTokenDetail) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.userTokenDetail.token}`
                }
            });
        }

        return next.handle(req).pipe(
            map((user: any) => {
                if (user instanceof HttpResponse) {
                    const response = user.body;
                    if (response.message && response.message !== '' && req.method !== 'GET' && response.message !== 'Thank you, password reset link is sent to your registered mailId') {
                        this.showSuccess(user.body.message);
                    }

                }
                return user;
            }),
            catchError(response => {
                if (response.status === 200 || response.status === 201) {
                    return response;
                }
                switch (response.status) {
                    case 400:
                        this.handleBadRequest(response);
                        break;
                    case 401:
                        this.handleUnAuthorized();
                        break;
                    case 500:
                        this.handleServerError();
                        break;
                    case 503:
                        this.ServerUnavilableError();
                        break;
                    default:
                        break;
                }
                return throwError(response);
            })
        );

    }


    /**
     * Shows notification errors when server response status is 401
     *
     * @params error
     */
    private handleBadRequest(responseBody: any): void {
        if (responseBody.error) {
            try {
                const bodyParsed = responseBody.error;
                this.handleErrorMessages(bodyParsed);
            } catch (error) {

            }
        }
        // else this.handleServerError();
    }
    private handleServerError(): void {
        const message = 'Internal Server Error',
            title = '500';

        this.toastr.errorToastr(message, '' , {
            showCloseButton: true,
        });
    }
    private ServerUnavilableError(): void {
        const message = 'Service Unavailable',
            title = '500';
        this.toastr.errorToastr(message, '' , {
            showCloseButton: true,
        });
    }
    private handleUnAuthorized() {
       // this.showNotificationError('unauthorized');
        this.router.navigate(['/auth/login']);
        localStorage.clear();
    }
    private handleErrorMessages(response: any): void {
        if (!response) {
            return;
        }
        if (!response.message) {
            return;
        }
        this.showNotificationError(response.message);
    }
   // showing success message
    private showSuccess(message) {
        this.toastr.successToastr(message, '', {
            showCloseButton: true,
            toastTimeout: 4000
        });
    }
    private showNotificationError(message: string): void {
        this.toastr.errorToastr(message, '', {
            showCloseButton: true,
            toastTimeout: 4000
        });
    }
}
