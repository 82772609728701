import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as store from '../state.interface';
import * as commonActions from './actions/common.action';
import { Subscription } from 'rxjs/index';
import {notificationCount, notificationCountLoading, notificationList, notificationListLoading} from './reducer/common.selector';

@Injectable()
export class CommonSandbox {
  public notificationCount$ = this.appState.select(notificationCount);
  public notificationCountLoading$ = this.appState.select(notificationCountLoading);

  public notificationList$ = this.appState.select(notificationList);
  public notificationListLoading$ = this.appState.select(notificationListLoading);

  private subscriptions: Array<Subscription> = [];

  constructor(protected appState: Store<store.AppState>) {
  }
  public getNotificationCount(params) {
    this.appState.dispatch(new commonActions.NotificationCount(params));
  }
  public getNotificationList(params) {
    this.appState.dispatch(new commonActions.NotificationList(params));
  }
}
