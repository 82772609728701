import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as store from '../../state.interface';
import * as userProfileActions from './action/user.profile.action';


@Injectable()

export class UserProfileSandbox {

    constructor(protected appState: Store<store.AppState>) {}

    // calling forgot action
    changePassword(params) {
        this.appState.dispatch(new userProfileActions.DoChangePasswordAction(params));
    }
}
