import { Map, Record } from 'immutable';

export interface AuthState extends Map<string, any> {
    loginSuccess: any;
    loginRequestLoading: boolean;
    loginRequestLoaded: boolean;
    loginRequestFailed: boolean;

    forgotPassword: any;
    forgotPasswordRequestLoading: boolean;
    forgotPasswordRequestLoaded: boolean;
    forgotPasswordRequestFailed: boolean;

    forgotPasswordUrl: any;
    forgotPasswordUrlLoading: boolean;
    forgotPasswordUrlLoaded: boolean;
    forgotPasswordUrlFailed: boolean;

    resetPassword: any;
    resetPasswordLoading: boolean;
    resetPasswordLoaded: boolean;
    resetPasswordFailed: boolean;
}
export const AuthStateRecord = Record({
    loginSuccess: {},
    loginRequestLoading: false,
    loginRequestLoaded: false,
    loginRequestFailed: false,

    forgotPassword: {},
    forgotPasswordRequestLoading: false,
    forgotPasswordRequestLoaded: false,
    forgotPasswordRequestFailed: false,

    forgotPasswordUrl: {},
    forgotPasswordUrlLoading: false,
    forgotPasswordUrlLoaded: false,
    forgotPasswordUrlFailed: false,

    resetPassword: {},
    resetPasswordLoading: false,
    resetPasswordLoaded: false,
    resetPasswordFailed: false,
});
