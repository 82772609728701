import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {CONTAINERS} from './common';
import {NotFoundComponent} from './pages/page-not-found/not-found.component';
import {AuthGuard} from './services/auth.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: CONTAINERS.LayoutComponent,
        children: [
             {path: '', redirectTo: 'institute-mangements', pathMatch: 'full'},
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: './pages/dashboard/dashboardModule#DashModuleModule'
            },
            {
                path: 'user-managements',
                canActivate: [AuthGuard],
                loadChildren: './pages/user-managements/userManagement.Module#UserManagementModule',
            },
            {
                path: 'institute-mangements',
                canActivate: [AuthGuard] ,
                loadChildren: './pages/institute-mangements/institute.Management.module#InstituteManagementModule',
            },
            {
                path: 'change-password',
                canActivate: [AuthGuard],
                loadChildren: './pages/user-profile/user.profile.module#UserProfileModule'
            },
            {
                path: 'notifications',
                canActivate: [AuthGuard],
                loadChildren: './pages/notifications/notifications.module#NotificationsModule',
            },
            {
                path: 'reports',
                canActivate: [AuthGuard],
                loadChildren: './pages/reports/reports.module#ReportsModule',
            },
            {
                path: 'server',
                canActivate: [AuthGuard],
                loadChildren: './pages/server/server.module#ServerModule',
            },
        ]
    },
    {
        path: 'auth',
        loadChildren: './pages/auth/auth.module#AuthModule'
    },
    // {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
      // RouterModule.forRoot(routes)
      RouterModule.forRoot(routes, {
              preloadingStrategy: PreloadAllModules,
              scrollPositionRestoration: 'enabled'
          }
      ),

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
