export class UploadInstituteFileModel {

    public instituteFile: File;
    public instituteId: string;
    public instituteFileType: Number;

    constructor(uploadInstituteLogoModel: any) {
        this.instituteFile = uploadInstituteLogoModel.instituteFile || '';
        this.instituteId = uploadInstituteLogoModel.instituteId || '';
        this.instituteFileType = uploadInstituteLogoModel.instituteFileType || '';


    }
}
