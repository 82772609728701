import {Action} from '@ngrx/store';
import {type} from '../../../shared/utility/utilityHelpers';
import { ResponsInterface } from '../../../response-model/response-interface';


export const ActionTypes = {
    GET_WISHLIST: type('[notifications] get wishlist'),
    GET_WISHLIST_SUCCESS: type('[notifications] get wishlist Success'),
    GET_WISHLIST_FAIL: type('[notifications] get wishlist Fail'),

    GET_FEEDBACK_LIST: type('[notifications] get feedback list'),
    GET_FEEDBACK_LIST_SUCCESS: type('[notifications] get feedback list Success'),
    GET_FEEDBACK_LIST_FAIL: type('[notifications] get feedback list Fail'),

    WISHLIST_COUNT: type('[notifications] get wishlist count'),
    WISHLIST_COUNT_SUCCESS: type('[notifications] get wishlist count Success'),
    WISHLIST_COUNT_FAIL: type('[notifications] get wishlist count Fail'),

    FEEDBACK_COUNT: type('[notifications] get FeedBack count'),
    FEEDBACK_COUNT_SUCCESS: type('[notifications] get FeedBack count Success'),
    FEEDBACK_COUNT_FAIL: type('[notifications] get FeedBack count Fail'),

    GET_DEACTIVATED_LIST: type('[notifications] GET_DEACTIVATED_LIST'),
    GET_DEACTIVATED_LIST_SUCCESS: type('[notifications] GET_DEACTIVATED_LIST_SUCCESS'),
    GET_DEACTIVATED_LIST_FAIL: type('[notifications] GET_DEACTIVATED_LIST_FAIL'),
    
    GET_DEACTIVATED_LIST_COUNT: type('[notifications] GET_DEACTIVATED_LIST_COUNT'),
    GET_DEACTIVATED_LIST_SUCCESS_COUNT: type('[notifications] GET_DEACTIVATED_LIST_SUCCESS_COUNT'),
    GET_DEACTIVATED_LIST_FAIL_COUNT: type('[notifications] GET_DEACTIVATED_LIST_FAIL_COUNT'),

    DEACTIVATE_SCHOOL: type('[notifications] DEACTIVATE_SCHOOL'),
    DEACTIVATE_SCHOOL_SUCCESS: type('[notifications] DEACTIVATE_SCHOOL_SUCCESS'),
    DEACTIVATE_SCHOOL_FAIL: type('[notifications] DEACTIVATE_SCHOOL_FAIL'),

    EXPORT_DEACTIVATED_LIST: type('[notifications] EXPORT_DEACTIVATED_LIST'),
    EXPORT_DEACTIVATED_LIST_SUCCESS: type('[notifications] EXPORT_DEACTIVATED_LIST_SUCCESS'),
    EXPORT_DEACTIVATED_LIST_FAIL: type('[notifications] EXPORT_DEACTIVATED_LIST_FAIL'),
};


// get wishlist
export class GetWishlistAction implements Action {
    type = ActionTypes.GET_WISHLIST;
    constructor(public payload: any) {
    }
}
export class GetWishlistSuccess implements Action {
    type = ActionTypes.GET_WISHLIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class GetWishlistFail implements Action {
    type = ActionTypes.GET_WISHLIST_FAIL;
    constructor(public payload: any = null) {

    }
}

// get feedback list
export class GetFeedbackListAction implements Action {
    type = ActionTypes.GET_FEEDBACK_LIST;
    constructor(public payload: any) {
    }
}
export class GetFeedbackListSuccess implements Action {
    type = ActionTypes.GET_FEEDBACK_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class GetFeedbackListFail implements Action {
    type = ActionTypes.GET_FEEDBACK_LIST_FAIL;
    constructor(public payload: any = null) {

    }
}
// get feedback Count
export class FeedbackCountAction implements Action {
    type = ActionTypes.FEEDBACK_COUNT;
    constructor(public payload: any) {
    }
}
export class FeedbackCountSuccess implements Action {
    type = ActionTypes.FEEDBACK_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class FeedbackCountFail implements Action {
    type = ActionTypes.FEEDBACK_COUNT_FAIL;
    constructor(public payload: any = null) {
    }
}



// get wishlist count
export class WishlistCountAction implements Action {
    type = ActionTypes.WISHLIST_COUNT;
    constructor(public payload: any) {
    }
}
export class WishlistCountSuccess implements Action {
    type = ActionTypes.WISHLIST_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class WishlistCountFail implements Action {
    type = ActionTypes.WISHLIST_COUNT_FAIL;
    constructor(public payload: any = null) {

    }
}

// get LIST
export class GetDeactivatedSchoolList implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST;
    constructor(public payload: any) {
    }
}
export class GetDeactivatedSchoolListSuccess implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class GetDeactivatedSchoolListFail implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST_FAIL;
    constructor(public payload: any = null) {
    }
}
// get COUNT 
export class GetDeactivatedSchoolListCount implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST_COUNT;
    constructor(public payload: any) {
    }
}
export class GetDeactivatedSchoolListCountSuccess implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST_SUCCESS_COUNT;
    constructor(public payload: ResponsInterface) {
    }
}
export class GetDeactivatedSchoolListCountFail implements Action {
    type = ActionTypes.GET_DEACTIVATED_LIST_FAIL_COUNT;
    constructor(public payload: any = null) {
    }
}

// get LIST
export class DeactivatedSchool implements Action {
    type = ActionTypes.DEACTIVATE_SCHOOL;
    constructor(public payload: any) {
    }
}
export class DeactivatedSchoolSuccess implements Action {
    type = ActionTypes.DEACTIVATE_SCHOOL_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class DeactivatedSchoolFail implements Action {
    type = ActionTypes.DEACTIVATE_SCHOOL_FAIL;
    constructor(public payload: any = null) {
    }
}

export class ExportDeactivatedSchoolList implements Action {
    type = ActionTypes.EXPORT_DEACTIVATED_LIST;
    constructor(public payload: any) {
    }
}
export class ExportDeactivatedSchoolListSuccess implements Action {
    type = ActionTypes.EXPORT_DEACTIVATED_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportDeactivatedSchoolListFail implements Action {
    type = ActionTypes.EXPORT_DEACTIVATED_LIST_FAIL;
    constructor(public payload: any = null) {
    }
}

export type Actions
    = GetWishlistAction
    | GetWishlistSuccess
    | GetWishlistFail
    | GetFeedbackListAction
    | GetFeedbackListSuccess
    | GetFeedbackListFail
    | FeedbackCountAction
    | FeedbackCountSuccess
    | FeedbackCountFail
    | GetDeactivatedSchoolList
    | GetDeactivatedSchoolListSuccess
    | GetDeactivatedSchoolListFail
    | GetDeactivatedSchoolListCount
    | GetDeactivatedSchoolListCountSuccess
    | GetDeactivatedSchoolListCountFail
    | DeactivatedSchool
    | DeactivatedSchoolSuccess
    | DeactivatedSchoolFail
    | ExportDeactivatedSchoolList
    | ExportDeactivatedSchoolListSuccess
    | ExportDeactivatedSchoolListFail;
