import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  userDetailsData: any;
  constructor() {
    this.userDetailsData = JSON.parse(localStorage.getItem('userdetails'));
  }

  ngOnInit() {
  }

}
