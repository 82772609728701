import * as actions from '../action/report.action';
import { ReportState, reportStateRecord } from './report.state';
import { SmsCreditModel } from '../models/smsCredit.model';
import { SchoolHistoryModel } from '../models/school-history.model';
// Model

export const initialState: ReportState = new reportStateRecord() as ReportState;
export function reducer(state = initialState, { type, payload }: any): ReportState {
    if (!type) {
        return state;
    }
    switch (type) {
        case actions.ActionTypes.REPORT_LIST: {
            return Object.assign({}, state, {
                reportListLoading: true,
                reportListLoaded: false,
                reportListFailed: false,
            });
        }
        case actions.ActionTypes.REPORT_LIST_SUCCESS: {
            return Object.assign({}, state, {
                reportList: payload,
                reportListLoading: false,
                reportListLoaded: true,
                reportListFailed: false,
            });
        }
        case actions.ActionTypes.REPORT_LIST_FAIL: {
            return Object.assign({}, state, {
                reportListLoading: false,
                reportListLoaded: false,
                reportListFailed: true,
            });
        }
        // sms credit List
        case actions.ActionTypes.SMS_CREDIT_LIST: {
            return Object.assign({}, state, {
                smsCreditListLoading: true,
                smsCreditListLoaded: false,
                smsCreditListFailed: false,
            });
        }
        case actions.ActionTypes.SMS_CREDIT_LIST_SUCCESS: {
            const tempSmsCreditList = payload.data.map(_list => new SmsCreditModel(_list));
            console.log('tempSmsCreditList', tempSmsCreditList)
            return Object.assign({}, state, {
                smsCreditList: tempSmsCreditList,
                smsCreditListLoading: false,
                smsCreditListLoaded: true,
                smsCreditListFailed: false,
            });
        }
        case actions.ActionTypes.SMS_CREDIT_LIST_FAIL: {
            return Object.assign({}, state, {
                smsCreditListLoading: false,
                smsCreditListLoaded: false,
                smsCreditListFailed: true,
            });
        }
        // invalid record
        case actions.ActionTypes.INVALID_RECORD_LIST: {
            return Object.assign({}, state, {
                invalidRecordListLoading: true,
                invalidRecordListLoaded: false,
                invalidRecordListFailed: false,
            });
        }
        case actions.ActionTypes.INVALID_RECORD_LIST_SUCCESS: {
            return Object.assign({}, state, {
                invalidRecordList: payload.data,
                invalidRecordListLoading: false,
                invalidRecordListLoaded: true,
                invalidRecordListFailed: false,
            });
        }
        case actions.ActionTypes.INVALID_RECORD_LIST_FAIL: {
            return Object.assign({}, state, {
                invalidRecordListLoading: false,
                invalidRecordListLoaded: false,
                invalidRecordListFailed: true,
            });
        }
        // invalid record count
        case actions.ActionTypes.INVALID_RECORD_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.INVALID_RECORD_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                invalidRecordCount: payload.data,
            });
        }
        case actions.ActionTypes.INVALID_RECORD_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        // sms credit count
        case actions.ActionTypes.SMS_CREDIT_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.SMS_CREDIT_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                smsCreditCount: payload.data,
            });
        }
        case actions.ActionTypes.SMS_CREDIT_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        // school history list
        case actions.ActionTypes.SCHOOL_HISTORY_LIST: {
            return Object.assign({}, state, {
                schoolHistoryListLoading: true,
                schoolHistoryListLoaded: false,
                schoolHistoryListFailed: false,
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_LIST_SUCCESS: {
            const tempSchoolHistoryList = payload.data.map(_list => new SchoolHistoryModel(_list));
            return Object.assign({}, state, {
                schoolHistoryList: tempSchoolHistoryList,
                schoolHistoryListLoading: false,
                schoolHistoryListLoaded: true,
                schoolHistoryListFailed: false,
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_LIST_FAIL: {
            return Object.assign({}, state, {
                schoolHistoryListLoading: false,
                schoolHistoryListLoaded: false,
                schoolHistoryListFailed: true,
            });
        }
        // school history count
        case actions.ActionTypes.SCHOOL_HISTORY_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                schoolHistoryCount: payload.data,
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        // stopped school list
        case actions.ActionTypes.STOPPED_SCHOOL_LIST: {
            return Object.assign({}, state, {
                stoppedSchoolListLoading: true,
                stoppedSchoolListLoaded: false,
                stoppedSchoolListFailed: false,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_LIST_SUCCESS: {
            console.log('stoppedSchoolList', payload.data)
            return Object.assign({}, state, {
                stoppedSchoolList: payload.data,
                stoppedSchoolListLoading: false,
                stoppedSchoolListLoaded: true,
                stoppedSchoolListFailed: false,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_LIST_FAIL: {
            return Object.assign({}, state, {
                stoppedSchoolListLoading: false,
                stoppedSchoolListLoaded: false,
                stoppedSchoolListFailed: true,
            });
        }
        // stopped school count
        case actions.ActionTypes.STOPPED_SCHOOL_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_COUNT_SUCCESS: {
            console.log('stoppedSchoolCount', payload.data)
            return Object.assign({}, state, {
                stoppedSchoolCount: payload.data,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        // stopped school enable
        case actions.ActionTypes.STOPPED_SCHOOL_ENABLE: {
            return Object.assign({}, state, {
                stoppedSchoolEnableLoading: true,
                stoppedSchoolEnableLoaded: false,
                stoppedSchoolEnableFailed: false,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_ENABLE_SUCCESS: {
            return Object.assign({}, state, {
                stoppedSchoolEnable: payload,
                stoppedSchoolEnableLoading: false,
                stoppedSchoolEnableLoaded: true,
                stoppedSchoolEnableFailed: false,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOL_ENABLE_FAIL: {
            return Object.assign({}, state, {
                stoppedSchoolEnableLoading: false,
                stoppedSchoolEnableLoaded: false,
                stoppedSchoolEnableFailed: true,
            });
        }
        // circular sms count
        case actions.ActionTypes.CIRCULAR_SMS_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.CIRCULAR_SMS_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                circularSmsCount: payload.data,
            });
        }
        case actions.ActionTypes.CIRCULAR_SMS_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        // renewal school list
        case actions.ActionTypes.RENEWAL_SCHOOL: {
            return Object.assign({}, state, {
                renewalSchoolLoading: true,
                renewalSchoolLoaded: false,
                renewalSchoolFailed: false,
            });
        }
        case actions.ActionTypes.RENEWAL_SCHOOL_SUCCESS: {
            return Object.assign({}, state, {
                renewalSchool: payload.data,
                temprenewalSchool: payload.data,
                renewalSchoolLoading: false,
                renewalSchoolLoaded: true,
                renewalSchoolFailed: false,
            });
        }
        case actions.ActionTypes.RENEWAL_SCHOOL_FAIL: {
            return Object.assign({}, state, {
                renewalSchoolLoading: false,
                renewalSchoolLoaded: false,
                renewalSchoolFailed: true,
            });
        }
        // Renewal school count
        case actions.ActionTypes.RENEWAL_SCHOOL_COUNT: {
            return Object.assign({}, state, {
                renewalSchoolCountLoading: true,
                renewalSchoolCountLoaded: false,
                renewalSchoolCountFailed: false,
            });
        }
        case actions.ActionTypes.RENEWAL_SCHOOL_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                renewalSchoolCount: payload.data,
                renewalSchoolCountLoading: false,
                renewalSchoolCountLoaded: true,
                renewalSchoolCountFailed: false,
            });
        }
        case actions.ActionTypes.RENEWAL_SCHOOL_COUNT_FAIL: {
            return Object.assign({}, state, {
                renewalSchoolCountLoading: false,
                renewalSchoolCountLoaded: false,
                renewalSchoolCountFailed: true,
            });
        }
        // circular sms list
        case actions.ActionTypes.CIRCULAR_SMS_LIST: {
            return Object.assign({}, state, {
                circularSmsListLoading: true,
                circularSmsListLoaded: false,
                circularSmsListFailed: false,
            });
        }
        case actions.ActionTypes.CIRCULAR_SMS_LIST_SUCCESS: {
            return Object.assign({}, state, {
                circularSmsList: payload.data,
                circularSmsListLoading: false,
                circularSmsListLoaded: true,
                circularSmsListFailed: false,
            });
        }
        case actions.ActionTypes.CIRCULAR_SMS_LIST_FAIL: {
            return Object.assign({}, state, {
                circularSmsListLoading: false,
                circularSmsListLoaded: false,
                circularSmsListFailed: true,
            });
        }

        case actions.ActionTypes.DELETED_SCHOOL_EXPORT: {
            return Object.assign({}, state, {
                deletedStudentReportLoading: true,
            });
        }
        case actions.ActionTypes.DELETED_SCHOOL_EXPORT_SUCCESS: {
            return Object.assign({}, state, {
                deletedStudentReport: payload.data,
                deletedStudentReportLoading: false,
            });
        }
        case actions.ActionTypes.DELETED_SCHOOL_EXPORT_FAIL: {
            return Object.assign({}, state, {
                deletedStudentReportLoading: false,
            });
        }

        case actions.ActionTypes.SCHOOL_HISTORY_EXPORT: {
            return Object.assign({}, state, {
                schoolHistoryReportLoading: true,
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_EXPORT_SUCCESS: {
            return Object.assign({}, state, {
                schoolHistoryReport: payload.data,
                schoolHistoryReportLoading: false,
            });
        }
        case actions.ActionTypes.SCHOOL_HISTORY_EXPORT_FAIL: {
            return Object.assign({}, state, {
                schoolHistoryReportLoading: false,
            });
        }

        case actions.ActionTypes.SMS_CIRCULAR_REPORT: {
            return Object.assign({}, state, {
                smsCircularReportLoading: true,
            });
        }
        case actions.ActionTypes.SMS_CIRCULAR_REPORT_SUCCESS: {
            return Object.assign({}, state, {
                smsCircularReport: payload.data,
                smsCircularReportLoading: false,
            });
        }
        case actions.ActionTypes.SMS_CIRCULAR_REPORT_FAIL: {
            return Object.assign({}, state, {
                smsCircularReportLoading: false,
            });
        }

        case actions.ActionTypes.Renewal_SCHOOL_REPORT: {
            return Object.assign({}, state, {
                renewalSchoolReportLoading: true,
            });
        }
        case actions.ActionTypes.Renewal_SCHOOL_REPORT_SUCCESS: {
            return Object.assign({}, state, {
                renewalSchoolReport: payload.data,
                renewalSchoolReportLoading: false,
            });
        }
        case actions.ActionTypes.Renewal_SCHOOL_REPORT_FAIL: {
            return Object.assign({}, state, {
                renewalSchoolReportLoading: false,
            });
        }

        case actions.ActionTypes.SMS_CREDIT_REPORTS: {
            return Object.assign({}, state, {
                smsCreditReportLoading: true,
            });
        }
        case actions.ActionTypes.SMS_CREDIT_REPORTS_SUCCESS: {
            return Object.assign({}, state, {
                smsCreditReport: payload.data,
                smsCreditReportLoading: false,
            });
        }
        case actions.ActionTypes.SMS_CREDIT_REPORTS_FAIL: {
            return Object.assign({}, state, {
                smsCreditReportLoading: false,
            });
        }

        case actions.ActionTypes.STOPPED_SCHOOLS_EXPORT: {
            return Object.assign({}, state, {
                stoppedSchoolReportLoading: true,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOLS_EXPORT_SUCCESS: {
            return Object.assign({}, state, {
                stoppedSchoolReport: payload.data,
                stoppedSchoolReportLoading: false,
            });
        }
        case actions.ActionTypes.STOPPED_SCHOOLS_EXPORT_FAIL: {
            return Object.assign({}, state, {
                stoppedSchoolReportLoading: false,
            });
        }

        case actions.ActionTypes.SCHOOLS_APP_USAGE_EXPORT: {
            return Object.assign({}, state, {
                SchoolAppUsageReportLoading: true,
            });
        }
        case actions.ActionTypes.SCHOOLS_APP_USAGE_EXPORT_SUCCESS: {
            return Object.assign({}, state, {
                SchoolAppUsageReport: payload.data,
                SchoolAppUsageReportLoading: false,
            });
        }
        case actions.ActionTypes.SCHOOLS_APP_USAGE_EXPORT_FAIL: {
            return Object.assign({}, state, {
                SchoolAppUsageReportLoading: false,
            });
        }

        case actions.ActionTypes.VOICE_CREDITS_LIST: {
            return Object.assign({}, state, {
                voiceCreditListLoading: true,
            });
        }
        case actions.ActionTypes.VOICE_CREDITS_LIST_SUCCESS: {
            return Object.assign({}, state, {
                voiceCreditList: payload.data,
                voiceCreditListLoading: false,
            });
        }
        case actions.ActionTypes.VOICE_CREDITS_LIST_FAIL: {
            return Object.assign({}, state, {
                voiceCreditListLoading: false,
            });
        }

        case actions.ActionTypes.VOICE_CREDITS_COUNT: {
            return Object.assign({}, state, {
                voiceCreditCountLoading: true,
            });
        }
        case actions.ActionTypes.VOICE_CREDITS_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                voiceCreditCount: payload.data,
                voiceCreditCountLoading: false,
            });
        }
        case actions.ActionTypes.VOICE_CREDITS_COUNT_FAIL: {
            return Object.assign({}, state, {
                voiceCreditCountLoading: false,
            });
        }

        // data
        case actions.ActionTypes.SCHOOL_APP_USAGE_LIST: {
            return Object.assign({}, state, {
                schoolAppUsageListLoading: true,
            });
        }
        case actions.ActionTypes.SCHOOL_APP_USAGE_LIST_SUCCESS: {
            return Object.assign({}, state, {
                schoolAppUsageList: payload.data,
                schoolAppUsageListLoading: false,
            });
        }
        case actions.ActionTypes.SCHOOL_APP_USAGE_LIST_FAIL: {
            return Object.assign({}, state, {
                schoolAppUsageListLoading: false,
            });
        }

        case actions.ActionTypes.SCHOOL_APP_USAGE_COUNT: {
            return Object.assign({}, state, {
                schoolAppUsageCountLoading: true,
            });
        }
        case actions.ActionTypes.SCHOOL_APP_USAGE_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                schoolAppUsageCount: payload.data,
                schoolAppUsageCountLoading: false,
            });
        }
        case actions.ActionTypes.SCHOOL_APP_USAGE_COUNT_FAIL: {
            return Object.assign({}, state, {
                schoolAppUsageCountLoading: false,
            });
        }
        // voice circular
        case actions.ActionTypes.VOICE_CIRCULAR_LIST: {
            return Object.assign({}, state, {
                circularVoiceListLoading: true,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_LIST_SUCCESS: {
            return Object.assign({}, state, {
                circularVoiceList: payload.data,
                circularVoiceListLoading: false,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_LIST_FAIL: {
            return Object.assign({}, state, {
                circularVoiceListLoading: false,
            });
        }

        case actions.ActionTypes.VOICE_CIRCULAR_COUNT: {
            return Object.assign({}, state, {
                circularVoiceCountLoading: true,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                circularVoiceCount: payload.data,
                circularVoiceCountLoading: false,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_COUNT_FAIL: {
            return Object.assign({}, state, {
                circularVoiceCountLoading: false,
            });
        }

        case actions.ActionTypes.VOICE_CIRCULAR_REPORT: {
            return Object.assign({}, state, {
                exportCircularVoiceReportLoading: true,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_REPORT_SUCCESS: {
            return Object.assign({}, state, {
                exportCircularVoiceReport: payload.data,
                exportCircularVoiceReportLoading: false,
            });
        }
        case actions.ActionTypes.VOICE_CIRCULAR_REPORT_FAIL: {
            return Object.assign({}, state, {
                exportCircularVoiceReportLoading: false,
            });
        }

        //

        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_LIST: {
            return Object.assign({}, state, {
                instituteVoiceUsageListLoading: true,
            });
        }
        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_LIST_SUCCESS: {
            return Object.assign({}, state, {
                instituteVoiceUsageList: payload.data,
                instituteVoiceUsageListLoading: false,
            });
        }
        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_LIST_FAIL: {
            return Object.assign({}, state, {
                instituteVoiceUsageListLoading: false,
            });
        }

        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_COUNT: {
            return Object.assign({}, state, {
                instituteVoiceUsageCountLoading: true,
            });
        }
        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                instituteVoiceUsageCount: payload.data,
                instituteVoiceUsageCountLoading: false,
            });
        }
        case actions.ActionTypes.INSTITUTE_VOICE_USAGE_COUNT_FAIL: {
            return Object.assign({}, state, {
                instituteVoiceUsageCountLoading: false,
            });
        }

        case actions.ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE: {
            return Object.assign({}, state, {
                exportInstituteVoiceUsageLoading: true,
            });
        }
        case actions.ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE_SUCCESS: {
            return Object.assign({}, state, {
                exportInstituteVoiceUsage: payload.data,
                exportInstituteVoiceUsageLoading: false,
            });
        }
        case actions.ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE_FAIL: {
            return Object.assign({}, state, {
                exportInstituteVoiceUsageLoading: false,
            });
        }

        /// get_school_details_list

        case actions.ActionTypes.GET_SCHOOL_DETAILS_LIST: {
            return Object.assign({}, state, {
                getSchoolDetails: payload,
                getSchoolDetailsLoaded: false,
                getSchoolDetailsFailed: false,
            })
        }

        case actions.ActionTypes.GET_SCHOOL_DETAILS_LIST_SUCCESS: {
            console.log('Get_school_profile', payload.data);
            return Object.assign({}, state, {
                getSchoolDetails: payload.data,
                getSchoolDetailsLoaded: true,
                getSchoolDetailsFailed: false,
            })

        }

        case actions.ActionTypes.GET_SCHOOL_DETAILS_LIST_FAIL: {
            return Object.assign({}, state, {
                getSchoolDetails: [],
                getSchoolDetailsLoaded: false,
                getSchoolDetailsFailed: true,
            })
        }

        /// school_profile_acknowledge

        case actions.ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE: {
            return Object.assign({}, state, {
                schoolProfileAcknowledge: payload,
                schoolProfileAcknowledgeSuccess: false,
                schoolProfileAcknowledgeFailed: false,
            })
        }

        case actions.ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE_SUCCESS: {
            return Object.assign({}, state, {
                schoolProfileAcknowledge: payload,
                schoolProfileAcknowledgeSuccess: true,
                schoolProfileAcknowledgeFailed: false,
            })
        }

        case actions.ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE_FAIL: {
            return Object.assign({}, state, {
                schoolProfileAcknowledge: payload,
                schoolProfileAcknowledgeSuccess: false,
                schoolProfileAcknowledgeFailed: true,
            })
        }

        /// redirect_url_to_school_profile

        case actions.ActionTypes.REDIRECT_TO_SCHOOL_PROFILE: {
            return Object.assign({}, state, {
                RedirectToSchoolProfile: [],
                RedirectToSchoolProfileLoaded: false,
                RedirectToSchoolProfileFailed: false,
            })
        }
        case actions.ActionTypes.REDIRECT_TO_SCHOOL_PROFILE_SUCCESS: {
            return Object.assign({}, state, {
                RedirectToSchoolProfile: payload,
                RedirectToSchoolProfileLoaded: true,
                RedirectToSchoolProfileFailed: false,
            })
        }
        case actions.ActionTypes.REDIRECT_TO_SCHOOL_PROFILE_FAIL: {
            return Object.assign({}, state, {
                RedirectToSchoolProfile: [],
                RedirectToSchoolProfileLoaded: false,
                RedirectToSchoolProfileFailed: true,
            })
        }

        //// school_profile_changes_popup

        case actions.ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP: {
            return Object.assign({}, state, {
                SchoolProfileChangesLoaded: false,
                SchoolProfileChangesFailed: false,
            })
        }
        case actions.ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP_SUCCESS: {
            console.log("tttttttt", payload.data)
            return Object.assign({}, state, {

                SchoolProfileChanges: payload.data,
                SchoolProfileChangesLoaded: true,
                SchoolProfileChangesFailed: false,
            })

        }
        case actions.ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP_FAIL: {
            return Object.assign({}, state, {
                SchoolProfileChanges: payload,
                SchoolProfileChangesLoaded: false,
                SchoolProfileChangesFailed: true,
            })
        }

        case actions.ActionTypes.SEARCH_RENEWAL_SCHOOL:
            debugger;
            let renewalSchoolData = state.renewalSchool;
            if (payload != '') {
                renewalSchoolData = renewalSchoolData.filter(val => {
                    return (val.institute_id === +payload);
                });
            }

            return Object.assign({}, state, {
                temprenewalSchool: renewalSchoolData
            });


        case actions.ActionTypes.VERIFY_RENEWAL_SCHOOL: {
            return Object.assign({}, state, {
                verifyRenewalForm: {},
                verifyRenewalFormLoading: true,
            })
        }
        case actions.ActionTypes.VERIFY_RENEWAL_SCHOOL_SUCCESS: {
            return Object.assign({}, state, {
                verifyRenewalForm: payload,
                verifyRenewalFormLoading: false,
            })

        }
        case actions.ActionTypes.VERIFY_RENEWAL_SCHOOL_FAIL: {
            return Object.assign({}, state, {
                verifyRenewalForm: payload,
                verifyRenewalFormLoading: false,
            })
        }


        case actions.ActionTypes.INTEREST_SCHOOL_LIST: {
            return Object.assign({}, state, {
                subscribeInterestSchoolListLoading: true,
                subscribeInterestSchoolListLoaded: false,
                subscribeInterestSchoolListFailed: false,
            });
        }
        case actions.ActionTypes.INTEREST_SCHOOL_LIST_SUCCESS: {
            return Object.assign({}, state, {
                subscribeInterestSchoolList: payload.data,
                subscribeInterestSchoolListLoading: false,
                invalidRecordListLoaded: true,
                subscribeInterestSchoolListFailed: false,
            });
        }
        case actions.ActionTypes.INTEREST_SCHOOL_LIST_FAIL: {
            return Object.assign({}, state, {
                subscribeInterestSchoolListLoading: false,
                subscribeInterestSchoolListLoaded: false,
                subscribeInterestSchoolListFailed: true,
            });
        }
        // invalid record count
        case actions.ActionTypes.INTEREST_SCHOOL_LIST_COUNT: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.INTEREST_SCHOOL_LIST_COUNT_SUCCESS: {
            return Object.assign({}, state, {
                subscribeInterestSchoolCount: payload.data,
            });
        }
        case actions.ActionTypes.INTEREST_SCHOOL_LIST_COUNT_FAIL: {
            return Object.assign({}, state, {
            });
        }
        case actions.ActionTypes.CLEAR_LIST: {
            return Object.assign({}, state, {
                subscribeInterestSchoolCount:[],
                subscribeInterestSchoolList:[]
            });
        }
        
        case actions.ActionTypes.LIVE_SCHOOL_EXPORT: {
            return Object.assign({}, state, {
                liveSchoolExportLoading: true,
            });
        }
        case actions.ActionTypes.LIVE_SCHOOL_EXPORT_SUCCESS: {
            return Object.assign({}, state, {
                liveSchoolExport: payload.data,
                liveSchoolExportLoading: false,
            });
        }
        case actions.ActionTypes.LIVE_SCHOOL_EXPORT_FAIL: {
            return Object.assign({}, state, {
                liveSchoolExportLoading: false,
            });
        }

        default: {
            return state;
        }
    }
}
export const reportList = (state: ReportState) => state.reportList;
export const reportListLoading = (state: ReportState) => state.reportListLoading;
export const reportListLoaded = (state: ReportState) => state.reportListLoaded;
export const reportListFailed = (state: ReportState) => state.reportListFailed;
// sms credit list
export const smsCreditList = (state: ReportState) => state.smsCreditList;
export const smsCreditListLoading = (state: ReportState) => state.smsCreditListLoading;
export const smsCreditListLoaded = (state: ReportState) => state.smsCreditListLoaded;
export const smsCreditListFailed = (state: ReportState) => state.smsCreditListFailed;
// invalid record
export const invalidRecordList = (state: ReportState) => state.invalidRecordList;
export const invalidRecordListLoading = (state: ReportState) => state.invalidRecordListLoading;
export const invalidRecordListLoaded = (state: ReportState) => state.invalidRecordListLoaded;
export const invalidRecordListFailed = (state: ReportState) => state.invalidRecordListFailed;
// invalid record Count
export const invalidRecordCount = (state: ReportState) => state.invalidRecordCount;
// sms credit count
export const smsCreditCount = (state: ReportState) => state.smsCreditCount;
// school history list
export const schoolHistoryList = (state: ReportState) => state.schoolHistoryList;
export const schoolHistoryListLoading = (state: ReportState) => state.schoolHistoryListLoading;
export const schoolHistoryListLoaded = (state: ReportState) => state.schoolHistoryListLoaded;
export const schoolHistoryListFailed = (state: ReportState) => state.schoolHistoryListFailed;
// institute stop enable
export const stoppedSchoolEnable = (state: ReportState) => state.stoppedSchoolEnable;
export const stoppedSchoolEnableLoading = (state: ReportState) => state.stoppedSchoolEnableLoading;
export const stoppedSchoolEnableLoaded = (state: ReportState) => state.stoppedSchoolEnableLoaded;
export const stoppedSchoolEnableFailed = (state: ReportState) => state.stoppedSchoolEnableFailed;
// school history count
export const schoolHistoryCount = (state: ReportState) => state.schoolHistoryCount;
// stopped school list
export const stoppedSchoolList = (state: ReportState) => state.stoppedSchoolList;
export const stoppedSchoolListLoading = (state: ReportState) => state.stoppedSchoolListLoading;
export const stoppedSchoolListLoaded = (state: ReportState) => state.stoppedSchoolListLoaded;
export const stoppedSchoolListFailed = (state: ReportState) => state.stoppedSchoolListFailed;
// stopped school count
export const stoppedSchoolCount = (state: ReportState) => state.stoppedSchoolCount;
// circular sms list
export const circularSmsList = (state: ReportState) => state.circularSmsList;
export const circularSmsListLoading = (state: ReportState) => state.circularSmsListLoading;
export const circularSmsListLoaded = (state: ReportState) => state.circularSmsListLoaded;
export const circularSmsListFailed = (state: ReportState) => state.circularSmsListFailed;
// circular sms count
export const circularSmsCount = (state: ReportState) => state.circularSmsCount;
// renewal school list
export const renewalSchool = (state: ReportState) => state.renewalSchool;
export const temprenewalSchool = (state: ReportState) => state.temprenewalSchool;
export const renewalSchoolLoading = (state: ReportState) => state.renewalSchoolLoading;
export const renewalSchoolLoaded = (state: ReportState) => state.renewalSchoolLoaded;
export const renewalSchoolFailed = (state: ReportState) => state.renewalSchoolFailed;
// renewal school count
export const renewalSchoolCount = (state: ReportState) => state.renewalSchoolCount;

export const deletedStudentReport = (state: ReportState) => state.deletedStudentReport;
export const deletedStudentReportLoading = (state: ReportState) => state.deletedStudentReportLoading;

export const schoolHistoryReport = (state: ReportState) => state.schoolHistoryReport;
export const schoolHistoryReportLoading = (state: ReportState) => state.schoolHistoryReportLoading;

export const smsCircularReport = (state: ReportState) => state.smsCircularReport;
export const smsCircularReportLoading = (state: ReportState) => state.smsCircularReportLoading;

export const renewalSchoolReport = (state: ReportState) => state.renewalSchoolReport;
export const renewalSchoolReportLoading = (state: ReportState) => state.renewalSchoolReportLoading;

export const smsCreditReport = (state: ReportState) => state.smsCreditReport;
export const smsCreditReportLoading = (state: ReportState) => state.smsCreditReportLoading;

export const stoppedSchoolReport = (state: ReportState) => state.stoppedSchoolReport;
export const stoppedSchoolReportLoading = (state: ReportState) => state.stoppedSchoolReportLoading;

export const voiceCreditList = (state: ReportState) => state.voiceCreditList;
export const voiceCreditListLoading = (state: ReportState) => state.voiceCreditListLoading;

export const voiceCreditCount = (state: ReportState) => state.voiceCreditCount;
export const voiceCreditCountLoading = (state: ReportState) => state.voiceCreditCountLoading;

export const schoolAppUsageList = (state: ReportState) => state.schoolAppUsageList;
export const schoolAppUsageListLoading = (state: ReportState) => state.schoolAppUsageListLoading;

export const schoolAppUsageCount = (state: ReportState) => state.schoolAppUsageCount;
export const schoolAppUsageCountLoading = (state: ReportState) => state.schoolAppUsageCountLoading;

export const circularVoiceList = (state: ReportState) => state.circularVoiceList;
export const circularVoiceListLoading = (state: ReportState) => state.circularVoiceListLoading;

export const circularVoiceCount = (state: ReportState) => state.circularVoiceCount;
export const circularVoiceCountLoading = (state: ReportState) => state.circularVoiceCountLoading;

export const exportCircularVoiceReport = (state: ReportState) => state.exportCircularVoiceReport;
export const exportCircularVoiceReportLoading = (state: ReportState) => state.exportCircularVoiceReportLoading;

export const instituteVoiceUsageList = (state: ReportState) => state.instituteVoiceUsageList;
export const instituteVoiceUsageListLoading = (state: ReportState) => state.instituteVoiceUsageListLoading;

export const instituteVoiceUsageCount = (state: ReportState) => state.instituteVoiceUsageCount;
export const instituteVoiceUsageCountLoading = (state: ReportState) => state.instituteVoiceUsageCountLoading;

export const exportInstituteVoiceUsage = (state: ReportState) => state.exportInstituteVoiceUsage;
export const exportInstituteVoiceUsageLoading = (state: ReportState) => state.exportInstituteVoiceUsageLoading;


export const SchoolAppUsageReport = (state: ReportState) => state.SchoolAppUsageReport;
export const SchoolAppUsageReportLoading = (state: ReportState) => state.SchoolAppUsageReportLoading;

export const getSchoolDetails = (state: ReportState) => state.getSchoolDetails;
export const getSchoolDetailsLoaded = (state: ReportState) => state.getSchoolDetailsLoaded;
export const getSchoolDetailsFailed = (state: ReportState) => state.getSchoolDetailsFailed;

export const SchoolProfileAcknowledge = (state: ReportState) => state.schoolProfileAcknowledge;
export const schoolProfileAcknowledgeSuccess = (state: ReportState) => state.schoolProfileAcknowledgeSuccess;
export const schoolProfileAcknowledgeFailed = (state: ReportState) => state.schoolProfileAcknowledgeFailed;

/// redirect_url_to_school_profile
export const RedirectToSchoolProfile = (state: ReportState) => state.RedirectToSchoolProfile;
export const RedirectToSchoolProfileLoaded = (state: ReportState) => state.RedirectToSchoolProfileLoaded;
export const RedirectToSchoolProfileFailed = (state: ReportState) => state.RedirectToSchoolProfileFailed;

//// school_profile_changes_popup

export const SchoolProfileChanges = (state: ReportState) => state.SchoolProfileChanges;
export const SchoolProfileChangesLoaded = (state: ReportState) => state.SchoolProfileChangesLoaded;
export const SchoolProfileChangesFailed = (state: ReportState) => state.SchoolProfileChangesFailed;

export const verifyRenewalForm = (state: ReportState) => state.verifyRenewalForm;
export const verifyRenewalFormLoading = (state: ReportState) => state.verifyRenewalFormLoading;


export const subscribeInterestSchoolList = (state: ReportState) => state.subscribeInterestSchoolList;
export const subscribeInterestSchoolListLoading = (state: ReportState) => state.subscribeInterestSchoolListLoading;
export const subscribeInterestSchoolListLoaded = (state: ReportState) => state.subscribeInterestSchoolListLoaded;
export const subscribeInterestSchoolListFailed = (state: ReportState) => state.subscribeInterestSchoolListFailed;

export const subscribeInterestSchoolCount = (state: ReportState) => state.subscribeInterestSchoolCount;

export const liveSchoolExport = (state: ReportState) => state.liveSchoolExport;
export const liveSchoolExportLoading = (state: ReportState) => state.liveSchoolExportLoading;
export const liveSchoolExportLoaded = (state: ReportState) => state.liveSchoolExportLoaded;
export const liveSchoolExportFailed = (state: ReportState) => state.liveSchoolExportFailed;