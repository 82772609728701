import {get, has} from 'lodash';
export class InstituteListResponse {
    // Declare Default Params
    public createdBy: any;
    public createdOn: any;
    public created_by: any;
    public institudeAbbrevation: any;
    public institudeCode: any;
    public instituteCity: any;
    public instituteContactNumber1: any;
    public instituteContactNumber2: any;
    public instituteContactPerson1: any;
    public instituteContactPerson2: any;
    public instituteEmail: any;
    public instituteLogo: any;
    public instituteLogoPath: any;
    public instituteType: any;
    public instituteStatus: any;
    public instituteVerifyApproveStatus: any;
    public institute_city: any;
    public institute_salesman: any;
    public isVerified: any;
    public modifiedBy: any;
    public modifiedOn: any;
    public verifiedBy: any;
    public verifiedOn: any;
    public files: any;
    public institudeId: any;
    public institudeName: any;
    public instituteAddress: any;
    public languageId: any;
    public salesMan: any;
    public password: any;
    public userName: any;
    public poNumber: any;
    public poOldNumber: any;
    public staffCount: any;
    public studentCount: any;
    public renewalDate: any;
    public isRenewal: any;
    public validityDaysLeft: any;
    public studentOldCount: any;
    public studentNewCount: any;
    public instituteIdPostQl: any;
    public institudeIntId: any;
    public verify_on: any;
    public verify_person: any;


    constructor(institutelistResponse: any) {
        this.studentOldCount = institutelistResponse.studentOldCount || 0;
        this.studentNewCount = institutelistResponse.studentNewCount || 0;
        this.validityDaysLeft = institutelistResponse.validityDaysLeft || 0;
        this.isRenewal = institutelistResponse.isRenewal || 0;
        this.staffCount = institutelistResponse.staffCount || 0;
        this.studentCount = institutelistResponse.studentCount || 0;
        this.renewalDate = institutelistResponse.renewalDate || '';
        this.poOldNumber = institutelistResponse.poOldNumber || '';
        this.poNumber = institutelistResponse.poNumber || '';
        this.password = institutelistResponse.password || '';
        this.userName = institutelistResponse.userName || '';
        this.createdBy = institutelistResponse.createdBy || '';
        this.createdOn = institutelistResponse.createdOn || '';
        this.created_by = institutelistResponse.created_by || '';
        this.institudeAbbrevation = institutelistResponse.institudeAbbrevation || '';
        this.institudeCode = institutelistResponse.institudeCode || '';
        this.instituteCity = institutelistResponse.instituteCity || '';
        this.instituteContactNumber1 = institutelistResponse.instituteContactNumber1 || '';
        this.instituteContactNumber2 = institutelistResponse.instituteContactNumber2 || '';
        this.instituteContactPerson1 = institutelistResponse.instituteContactPerson1 || '';
        this.instituteContactPerson2 = institutelistResponse.instituteContactPerson2 || '';
        this.instituteEmail = institutelistResponse.instituteEmail || '';
        this.instituteLogo = institutelistResponse.instituteLogo || '';
        this.instituteLogoPath = institutelistResponse.instituteLogoPath || '';
        this.instituteType = institutelistResponse.instituteType || '';
        this.instituteStatus = institutelistResponse.instituteStatus || 0;
        this.instituteVerifyApproveStatus = institutelistResponse.instituteVerifyApproveStatus || 0;
        this.institute_city = institutelistResponse.institute_city || '';
        this.institute_salesman = institutelistResponse.institute_salesman || '';
        this.isVerified = institutelistResponse.isVerified || '';
        this.modifiedBy = institutelistResponse.modifiedBy || '';
        this.modifiedOn = institutelistResponse.modifiedOn || '';
        this.verifiedBy = institutelistResponse.verifiedBy || '';
        this.verifiedOn = institutelistResponse.verifiedOn || '';
        this.files = institutelistResponse.files || [];
        this.institudeId = institutelistResponse.institudeId || '';
        this.institudeName = institutelistResponse.institudeName || '';
        this.instituteAddress = institutelistResponse.instituteAddress || '';
        this.languageId = institutelistResponse.languageId || '';
        this.salesMan = institutelistResponse.salesMan || '';
        this.instituteIdPostQl = institutelistResponse.instituteIdPostQl || '';
        this.institudeIntId = institutelistResponse.institudeIntId || '';
        this.verify_on = institutelistResponse.verify_on || '';
        this.verify_person = institutelistResponse.verify_person || '--';

    }
}
