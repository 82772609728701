export class UserListModel {
    public count: number;
    public keyword: string;
    public limit: number;

    constructor( userList: any) {
        this.count = userList.count || '';
        this.keyword = userList.keyword || '';
        this.limit = userList.limit || '';
    }
}
