import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {CommonSandbox} from '../../../common/common.sandbox';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationComponent} from '../popup/notification/notification.component';

@Component({
  selector: 'app-count-list-notification',
  templateUrl: './count-list-notification.component.html',
  styleUrls: ['./count-list-notification.component.scss']
})
export class CountListNotificationComponent implements OnInit {

  constructor(public router: Router,
              public modalService: NgbModal,
              public commonSandbox: CommonSandbox) { }

  ngOnInit() {
    this.commonSandbox.getNotificationCount({});
  }

  show_WhishList() {
    this.router.navigate(['notifications/wishlist']);
  }
  show_feedBack() {
    this.router.navigate(['notifications/feedback']);
  }
  showDeactivatedSchool () {
    this.router.navigate(['notifications/deactivated-school']);
  }
  openNotification() {
    const params: any = {};
    params.limit = '';
    params.offset = '';
    params.orderBy = 2;
    params.count = '';
    this.commonSandbox.getNotificationList(params);
      const modalRef = this.modalService.open(NotificationComponent, {windowClass: 'textDetailsReport',
        size: 'lg', backdrop: 'static'
      });
     // modalRef.componentInstance.studentId = id;
      modalRef.result.then(result => {
        console.log('result', result);
        if (result) {
          // -----
        }
      });
  }
}
