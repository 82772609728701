import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { InstituteManagementSandbox } from '../../../../pages/institute-mangements/institute-management.sandbox';
import { Subscription } from 'rxjs';
import {get} from 'lodash';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {
  @ViewChild('filePath') filePath: ElementRef;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;

  public instituteId: any;
  public checkBoxValue: any;


  public files: any;
  public file: any;
  public base64String: any;
  public date: any;
  public is_true = false;
  fileSizeError: any;
  fileType: any;
  fileTypeError: any;
  instituteDetails: any;
  message: string;
  audioName = 'No audio File Selected';
  uploadAudioFileName;
  isBrowseImage = false;
  isPlay = [];
  isPause = [];
  // isPlay[i]: any = false;

  private subscriptions: Array<Subscription> = [];

  constructor(public activeModal: NgbActiveModal,
    public instituteManagementSandbox: InstituteManagementSandbox) { }

  ngOnInit() {
    console.log('instituteDetailsFILES', this.instituteDetails);
    this.is_true = false;
    this.fileSizeError = '';
    if (get(this.instituteDetails.files, 'length') > 0) {
     this.instituteDetails.files.forEach((data, i) => {
         this.isPlay[i] = true;
         this.isPause[i] = false;
        if (data.file_type === 1) {
          data['message'] =  'Welcome Message';
        }
        if (data.file_type === 2) {
          data['message'] =  'Birth Day wishes';
        }
        if (data.file_type === 3) {
          data['message'] =  'Wake up file';
        }
        if (data.file_type === 4) {
          data['message'] =  'Absent file';
        }
        if (data.file_type === 5) {
          data['message'] =  'Fee Remainder';
        }
      });
    }
  }

  // upload audio file
  uploadChange(event) {
    console.log('event', event);
    this.fileSizeError = '';
    this.files = event.target.files;
    this.file = this.files[0];
    this.fileType = this.file.type;
    if (this.fileType === 'audio/wav') {

    if (this.files[0].size < 2146190) {
      this.isBrowseImage = true;
      this.uploadAudioFileName = this.file.name;
      this.date = new DatePipe('en-US').transform(this.file.lastModifiedDate, 'yyyy-MM-dd');
      if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (events: any) => {
          this.base64String = events.target.result;
         // this.upload();
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      this.fileSizeError = '(File size Should not exceed more than 2mb)';
    }
  } else {
    this.fileTypeError = '(Only audio/wav files are allowed)';

  }
  }

  uploadButtonClick() {
    const el: HTMLElement = this.filePath.nativeElement as HTMLElement;
    el.click();
  }

  checkboxCheck(event) {
    this.is_true = true;
    this.isBrowseImage = false;
    this.checkBoxValue = event.target.value;
  }
  upload() {
    const params: any = {};
    params.instituteFile = this.base64String;
    params.instituteId = this.instituteId;
    params.instituteFileType = this.checkBoxValue;
    this.instituteManagementSandbox.uploadInstituteFile(params);
    this.subscribe();
  }
  subscribe() {
    this.subscriptions.push(this.instituteManagementSandbox.uploadInstituteFile$.subscribe(data => {
      if (data && data.status === 1) {
        this.isBrowseImage = false;
        this.activeModal.close();
      }
    }));
  }
  unsubscibeValue() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }
  // play Audio
  playAudio(i) {
    this.isPlay.forEach((data, index) => {
      this.isPlay[index] = true;
      // console.log(data, 'data');
    });
   // this.audioPlayer.nativeElement.pause();
    this.isPlay[i] = false;
    this.isPause[i] = true;
   this.audioPlayer.nativeElement.play();
    console.log('hii22',  this.isPlay);

  }
  // pause Audio
  pauseAudio(i) {
    this.isPlay[i] = true;
    this.isPause[i] = false;
   // this.isPause = false;
    this.audioPlayer.nativeElement.pause();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    const params: any = {};
    this.instituteDetails = {};
   // this.instituteManagementSandbox.emptyUploadInstituteFile(params);
  }
}
