import {createSelector} from 'reselect';

import * as commonReducer from './common.reducer';
import {AppState} from '../../state.interface';

// *************************** PUBLIC API's ****************************
/**
 * Signup store functions
 */
export const getSubState = (state: AppState) => state.common;
export const notificationCount = createSelector(getSubState, commonReducer.notificationCount);
export const notificationCountLoading = createSelector(getSubState, commonReducer.notificationCountLoading);
export const notificationCountLoaded = createSelector(getSubState, commonReducer.notificationCountLoaded);
export const notificationCountFailed = createSelector(getSubState, commonReducer.notificationCountFailed);

export const notificationList = createSelector(getSubState, commonReducer.notificationList);
export const notificationListLoading = createSelector(getSubState, commonReducer.notificationListLoading);
export const notificationListLoaded = createSelector(getSubState, commonReducer.notificationListLoaded);
export const notificationListFailed = createSelector(getSubState, commonReducer.notificationListFailed);

