import { Map, Record } from 'immutable';

export interface ReportState extends Map<string, any> {
    reportList: any;
    smsCreditList: any;
    smsCreditCount: any;
    invalidRecordList: any;
    invalidRecordCount: any;
    schoolHistoryList: any;
    schoolHistoryCount: any;
    stoppedSchoolList: any;
    stoppedSchoolCount: any;
    stoppedSchoolEnable: any;
    circularSmsList: any;
    circularSmsCount: any;
    renewalSchool: any;
    temprenewalSchool: any;
    renewalSchoolCount: any;
    deletedStudentReport: any;
    schoolHistoryReport: any;
    smsCircularReport: any;
    renewalSchoolReport: any;
    smsCreditReport: any;
    stoppedSchoolReport: any;
    voiceCreditList: any;
    voiceCreditCount: any;
    voiceCreditExport: any;
    schoolAppUsageList: any;
    schoolAppUsageCount: any;
    circularVoiceList: any;
    circularVoiceCount: any;
    exportCircularVoiceReport: any;

    instituteVoiceUsageList: any,
    instituteVoiceUsageCount: any,
    exportInstituteVoiceUsage: any,

    exportCircularVoiceReportLoading: boolean;

    voiceCreditListLoading: boolean;
    voiceCreditCountLoading: boolean;

    circularVoiceListLoading: boolean;
    circularVoiceCountLoading: boolean;

    renewalSchoolReportLoading: boolean;
    schoolAppUsageListLoading: boolean;
    schoolAppUsageCountLoading: boolean;

    smsCreditReportLoading: boolean;
    stoppedSchoolReportLoading: boolean;

    SchoolAppUsageReport: any;
    SchoolAppUsageReportLoading: boolean;

    deletedStudentReportLoading: boolean;

    schoolHistoryReportLoading: boolean;

    smsCircularReportLoading: boolean;

    renewalSchoolLoading: boolean;
    renewalSchoolLoaded: boolean;
    renewalSchoolFailed: boolean;

    renewalSchoolCountLoading: boolean;
    renewalSchoolCountLoaded: boolean;
    renewalSchoolCountFailed: boolean;

    circularSmsListLoading: boolean;
    circularSmsListLoaded: boolean;
    circularSmsListFailed: boolean;

    stoppedSchoolEnableLoading: boolean;
    stoppedSchoolEnableLoaded: boolean;
    stoppedSchoolEnableFailed: boolean;

    stoppedSchoolListLoading: boolean;
    stoppedSchoolListLoaded: boolean;
    stoppedSchoolListFailed: boolean;

    schoolHistoryListLoading: boolean;
    schoolHistoryListLoaded: boolean;
    schoolHistoryListFailed: boolean;

    reportListLoading: boolean;
    reportListLoaded: boolean;
    reportListFailed: boolean;

    smsCreditListLoading: boolean;
    smsCreditListLoaded: boolean;
    smsCreditListFailed: boolean;

    invalidRecordListLoading: boolean;
    invalidRecordListLoaded: boolean;
    invalidRecordListFailed: boolean;
    instituteVoiceUsageListLoading: boolean,
    instituteVoiceUsageCountLoading: boolean,
    exportInstituteVoiceUsageLoading: boolean,

    getSchoolDetails: any;
    getSchoolDetailsLoaded: any;
    getSchoolDetailsFailed: any;

    schoolProfileAcknowledge: any;
    schoolProfileAcknowledgeSuccess: any;
    schoolProfileAcknowledgeFailed: any;

    RedirectToSchoolProfile: any;
    RedirectToSchoolProfileLoaded: any;
    RedirectToSchoolProfileFailed: any;

    SchoolProfileChanges: any;
    SchoolProfileChangesLoaded: any;
    SchoolProfileChangesFailed: any;

    verifyRenewalForm: any,
    verifyRenewalFormLoading: boolean,

    subscribeInterestSchoolListLoading: boolean;
    subscribeInterestSchoolListLoaded: boolean;
    subscribeInterestSchoolListFailed: boolean;
    subscribeInterestSchoolList: any;
    subscribeInterestSchoolCount: any;

    liveSchoolExport:any;
    liveSchoolExportLoading: boolean;
    liveSchoolExportLoaded: boolean;
    liveSchoolExportFailed: boolean;


}

export const reportStateRecord = Record({
    reportList: [],
    smsCreditList: [],
    invalidRecordList: [],
    invalidRecordCount: [],
    smsCreditCount: [],
    schoolHistoryList: [],
    schoolHistoryCount: [],
    stoppedSchoolList: [],
    stoppedSchoolCount: [],
    stoppedSchoolEnable: [],
    circularSmsList: [],
    circularSmsCount: [],
    renewalSchool: [],
    temprenewalSchool: [],
    renewalSchoolCount: [],
    deletedStudentReport: [],
    schoolHistoryReport: [],
    smsCircularReport: [],
    renewalSchoolReport: [],
    smsCreditReport: [],
    stoppedSchoolReport: [],
    voiceCreditList: [],
    voiceCreditCount: [],
    voiceCreditExport: [],
    schoolAppUsageList: [],
    schoolAppUsageCount: [],
    circularVoiceList: [],
    circularVoiceCount: [],
    exportCircularVoiceReport: [],
    instituteVoiceUsageList: [],
    instituteVoiceUsageCount: [],
    exportInstituteVoiceUsage: [],

    exportCircularVoiceReportLoading: false,

    circularVoiceListLoading: false,
    circularVoiceCountLoading: false,

    voiceCreditListLoading: false,
    voiceCreditCountLoading: false,

    schoolAppUsageListLoading: false,
    schoolAppUsageCountLoading: false,

    renewalSchoolReportLoading: false,
    smsCreditReportLoading: false,
    stoppedSchoolReportLoading: false,

    deletedStudentReportLoading: false,

    schoolHistoryReportLoading: false,

    smsCircularReportLoading: false,

    renewalSchoolLoading: false,
    renewalSchoolLoaded: false,
    renewalSchoolFailed: false,

    renewalSchoolCountLoading: false,
    renewalSchoolCountLoaded: false,
    renewalSchoolCountFailed: false,

    circularSmsListLoading: false,
    circularSmsListLoaded: false,
    circularSmsListFailed: false,

    stoppedSchoolEnableLoading: false,
    stoppedSchoolEnableLoaded: false,
    stoppedSchoolEnableFailed: false,

    stoppedSchoolListLoading: false,
    stoppedSchoolListLoaded: false,
    stoppedSchoolListFailed: false,

    schoolHistoryListLoading: false,
    schoolHistoryListLoaded: false,
    schoolHistoryListFailed: false,

    reportListLoading: false,
    reportListLoaded: false,
    reportListFailed: false,

    smsCreditListLoading: false,
    smsCreditListLoaded: false,
    smsCreditListFailed: false,

    invalidRecordListLoading: false,
    invalidRecordListLoaded: false,
    invalidRecordListFailed: false,

    SchoolAppUsageReport: [],
    SchoolAppUsageReportLoading: false,
    instituteVoiceUsageListLoading: false,
    instituteVoiceUsageCountLoading: false,
    exportInstituteVoiceUsageLoading: false,

    getSchoolDetails: [],
    getSchoolDetailsLoaded: false,
    getSchoolDetailsFailed: false,

    schoolProfileAcknowledge: {},
    schoolProfileAcknowledgeSuccess: false,
    schoolProfileAcknowledgeFailed: false,

    RedirectToSchoolProfile: [],
    RedirectToSchoolProfileLoaded: false,
    RedirectToSchoolProfileFailed: false,

    SchoolProfileChanges: [],
    SchoolProfileChangesLoaded: false,
    SchoolProfileChangesFailed: false,

    verifyRenewalForm: {},
    verifyRenewalFormLoading: false,

    subscribeInterestSchoolListLoading: false,
    subscribeInterestSchoolListLoaded: false,
    subscribeInterestSchoolListFailed: false,
    subscribeInterestSchoolList: [],
    subscribeInterestSchoolCount: [],
    
    liveSchoolExport:[],
    liveSchoolExportLoading: false,
    liveSchoolExportLoaded: false,
    liveSchoolExportFailed: false,

});
