import {UserProfileState, UserProfileRecordState} from './user.profile.state';
import * as actions from '../action/user.profile.action';


export const initialState: UserProfileState = new UserProfileRecordState() as UserProfileState;

export function reducer(state = initialState, {type, payload}: any): UserProfileState {
    if (!type) {
        return state;
    }

    switch (type) {
        case actions.ActionTypes.DO_CHANGE_PASSWORD: {
            return Object.assign({}, state, {
                changePasswordResponse: false,
                changePasswordRequestLoading: true,
                changePasswordRequestLoaded: false,
                changePasswordRequestFailed: false
            });
        }
        case actions.ActionTypes.DO_CHANGE_PASSWORD_SUCCESS: {
            return Object.assign({}, state, {
                changePassword: payload,
                changePasswordResponse: true,
                changePasswordRequestLoading: false,
                changePasswordRequestLoaded: true,
                changePasswordRequestFailed: false,
            });
        }

        case actions.ActionTypes.DO_CHANGE_PASSWORD_FAIL: {
            return Object.assign({}, initialState, {
                changePasswordResponse: false,
                changePasswordRequestLoading: false,
                changePasswordRequestLoaded: true,
                changePasswordRequestFailed: true,
            });
        }
        default: {
            return state;
        }
    }
}


export const getChangePassword = (state: UserProfileState) => state.changePassword;
export const getChangePasswordResponse = (state: UserProfileState) => state.changePasswordResponse;
export const getChangePasswordRequestLoading = (state: UserProfileState) => state.changePasswordRequestLoading;
export const getChangePasswordRequestLoaded = (state: UserProfileState) => state.changePasswordRequestLoaded;
export const getChangePasswordRequestFailed = (state: UserProfileState) => state.changePasswordRequestFailed;

