import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthSandbox} from '../../../pages/auth/auth.sandbox';

@Component({
  selector: 'app-commonheader',
  templateUrl: './commonheader.component.html',
  styleUrls: ['./commonheader.component.scss']
})
export class CommonheaderComponent implements OnInit {
  userDetailsData: any = {};
  defaultImage: any;
  constructor(public router: Router) {
    this.defaultImage = 'assets/imgs/profilelogo.jpg';
  }

  ngOnInit() {
    this.userDetailsData = JSON.parse(localStorage.getItem('userdetails'));
  }
// logout function
  logOut() {
    localStorage.removeItem('userdetails');
    this.router.navigate(['/auth/login']);
  }
}
