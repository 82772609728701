import { Component, OnInit } from '@angular/core';
import { ReportSandbox } from 'src/app/pages/reports/report.sandbox';
import { get } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-enable-stopped-school-detail',
  templateUrl: './enable-stopped-school-detail.component.html',
  styleUrls: ['./enable-stopped-school-detail.component.scss']
})
export class EnableStoppedSchoolDetailComponent implements OnInit {
  instituteDetails: any;
  private subscriptions: Array<Subscription> = [];
  constructor(public reportSandbox: ReportSandbox, public activeModal: NgbActiveModal,) { }

  ngOnInit() {
    console.log('enable', this.instituteDetails);
  }
        accept() {
          if (get(this.instituteDetails, 'instituteId')) {
            this.reportSandbox.StoppedSchoolEnable({instituteId: this.instituteDetails.instituteId});
            this.activeModal.close();
        }
        }
        dismiss() {
          this.activeModal.close();
         }
}
