import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[Decimalnumeric]'
})

export class NumericDecimalDirective {

    @Input('numericType') numericType: string; // number | decimal

    private regex = {
        number: new RegExp(/^\d+$/),
        decimal: new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g),
        letter: new RegExp(/^[a-zA-Z-]*$/),
        alpha: new RegExp(/^[a-zA-Z\s]*$/),
        range: new RegExp(/[0-5]/),
        numberLetter: new RegExp(/^[a-zA-Z0-9 ]*$/), // with spaces
        alphaNumeric: new RegExp( /^[a-zA-Z0-9]*$/), // without spaces
        // alphaNumeric: new RegExp( /^[a-zA-Z0-9-_]+$/),
        // allow consecutive decimals
       // decimal: new RegExp(/^[0-9]*\.?[0-9]*$/g)
       // decimal: new RegExp(/^[0-9]+\.[0-9]{2}$|[0-9]+\.[0-9]{2}[^0-9]/)
    };

    private specialKeys = {
        number: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
        decimal: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        letter: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'],
        range: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
        alphaNumeric: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' ],
        numberLetter: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'WhiteSpace'],
        alpha: [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight' , 'WhiteSpace'],
    };

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys[this.numericType].indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex[this.numericType])) {
            event.preventDefault();
        }
    }
}
