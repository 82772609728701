
export class UserListResponseModel {
    public contactNo: string;
    public firstName: string;
    public id: string;
    public lastName: string;
    public loginId: string;
    public roleId: string;
    public roleName: string;
    public roleNameCheck: string;
    public status: number;
    public userFullName: string;
    public imagePath: any;
    public imageName: any;




    constructor( userResponseList: any) {
        this.contactNo = userResponseList.contact_no || '';
        this.firstName = userResponseList.firstName || '';
        this.id = userResponseList.id || '';
        this.lastName = userResponseList.lastName || '';
        this.loginId = userResponseList.loginId || '';
        this.roleId = userResponseList.roleId || '';
        this.roleName = userResponseList.roleName || '';
        this.roleNameCheck = userResponseList.roleNameCheck || '';
        this.status = userResponseList.status || 0;
        this.userFullName = userResponseList.userFullName || '';
        this.imagePath = userResponseList.imagePath;
        this.imageName = userResponseList.imageName ;




    }
}

