import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {CommonSandbox} from '../common.sandbox';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  constructor(public commonSandbox: CommonSandbox) { }

  ngOnInit() {
    this.commonSandbox.getNotificationCount({});
  }
}
