export class InstituteListModel {

    public limit: number;
    public offset: number;
    public keyword: string;


    constructor(instituteListModel: any) {
        this.limit = instituteListModel.limit || '';
        this.offset = instituteListModel.offset || '';
        this.keyword = instituteListModel.keyword || '';
    }
}
