
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {CommonheaderComponent} from './commonheader/commonheader.component';
import {SharedModule} from '../shared.module';
import {CountListNotificationComponent} from './count-list-notification/count-list-notification.component';
import {MenuComponent} from './menu/menu.component';
import {UserProfileSandbox} from '../../pages/user-profile/user.profile.sandbox';
import {UserProfileService} from '../../pages/user-profile/user.profile.service';
import {EffectsModule} from '@ngrx/effects';
import {UserProfileEffect} from '../../pages/user-profile/effects/user.profile.effect';
import {SpinnerComponent} from './spinner/spinner.component';


export const COMPONENTS = [
    FooterComponent,
    CommonheaderComponent,
    CountListNotificationComponent,
    MenuComponent,

];

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SharedModule,
        EffectsModule.forFeature([UserProfileEffect]),
    ],
    declarations: [COMPONENTS],

    exports: [COMPONENTS],
    entryComponents: [],
    providers: [
        UserProfileSandbox,
        UserProfileService,
        ],
   // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule {
}
