import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsInterface } from '../response-model/response-interface';
import {Api} from '../services/Api';

@Injectable()

export class CommonService extends Api {

  private URL: string = this.getBaseUrl();

  getNotificationCount(val): Observable<ResponsInterface> {
    return this.http.get<ResponsInterface>(this.URL + '/report/notification-count');
  }

  getNotificationList(params): Observable<ResponsInterface> {
    return this.http.get<ResponsInterface>(this.URL + '/report/notification-list', {params});
  }
}
