import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as userManagementAction from './actions/user-management.action';
import * as store from '../../state.interface';
import { Subscription } from 'rxjs';
import { CreateUserModel } from '../user-managements/models/create-user.model';
import { UserListModel } from './models/user-list.model';
import {
  userRole,
  userList,
  userDetails,
  userUpdateDetails,
  deleteUser,
  userListLoading,
  createUserLoading,
  UserUpdateDetailsLoading
} from './reducers/user-management.selector';
import { UpdateUserModel } from './models/update.User.model';

@Injectable()
export class UserManagementSandbox {
  private subscriptions: Array<Subscription> = [];
  public userRole$ = this.appState$.select(userRole);
  public userList$ = this.appState$.select(userList);
  public userDetails$ = this.appState$.select(userDetails);
  public userUpdateDetails$ = this.appState$.select(userUpdateDetails);
  public deleteUser$ = this.appState$.select(deleteUser);
  public userListLoading$ = this.appState$.select(userListLoading);
  public createUserLoading$ = this.appState$.select(createUserLoading);
  public UserUpdateDetailsLoading$ = this.appState$.select(UserUpdateDetailsLoading);





  constructor(private router: Router,
    protected appState$: Store<store.AppState>) {
  }
  public getUserRole(): void {
    this.appState$.dispatch(new userManagementAction.GetUserRole());
  }

  public createUser(params): void {
    this.appState$.dispatch(new userManagementAction.CreateUser(new CreateUserModel(params)));
  }

  public userList(params): void {
    this.appState$.dispatch(new userManagementAction.GetUserList(new UserListModel(params)));
  }

  public deleteUser(params): void {
    this.appState$.dispatch(new userManagementAction.DeleteUser(params));
  }
  // getting userList Data
  public getUserListData(params): void {
    this.appState$.dispatch(new userManagementAction.UserDetailsAction(params));
  }
  // updating user
  public updateUser(params): void {
    this.appState$.dispatch(new userManagementAction.UserUpdateAction(new UpdateUserModel(params)));
  }
  // clear the delete response
  public unsubscribe(params): void {
    this.appState$.dispatch(new userManagementAction.UnSubscribeAction(params));
  }
}
