import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {CommonSandbox} from '../../../../common/common.sandbox';

@Component({
  selector: 'app-text-details-report',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  studentId: any;
  constructor(
      public activeModal: NgbActiveModal,
      public commonSandbox: CommonSandbox
      ) {
  }

  ngOnInit() {
  }
  close() {
    this.activeModal.close(false);
  }
}
