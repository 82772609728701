export class UploadInstituteLogoModel {

    public instituteLogo: File;
    public instituteId: string;

    constructor(uploadInstituteLogoModel: any) {
        this.instituteLogo = uploadInstituteLogoModel.instituteLogo || '';
        this.instituteId = uploadInstituteLogoModel.instituteId || '';

    }
}
