export class InstituteRenewalModel {

    public instituteId: string;
    public pocorlive: string;
    public prepaidorpostpaid: string;
    public validFrom: string;
    public validTo: string;
    public remarks: any;
    public settings: any = [];
    public renewtype :string;
    public goLiveDate: string;


    constructor(instituteRenewModel: any) {
        this.instituteId = instituteRenewModel.instituteId || '';
        this.pocorlive = instituteRenewModel.pocorlive || '';
        this.prepaidorpostpaid = instituteRenewModel.prepaidorpostpaid || '';
        this.validFrom = instituteRenewModel.validFrom || '';
        this.validTo = instituteRenewModel.validTo || '';
        this.settings = instituteRenewModel.settings || [];
        this.remarks = instituteRenewModel.remarks || '';
        this.renewtype = instituteRenewModel.renewtype || '';
        this.goLiveDate = instituteRenewModel.goLiveDate || '';
        
    }
}
