export class SmsCreditModel {
    // public Validity: any;
    public allocatedCount: any;
    public instituteId: any;
    public oldNewCount: any;
    public purchaseType: any;
    public remaining: any;
    public schoolName: any;
    public userCount: any;
    public ValidityFrom: any;
    public ValidityTo: any;
    constructor(creditList: any) {
        // this.Validity = creditList.Validity !== 'null - null' ? creditList.Validity ? creditList.Validity.split(' - ') || [] : [] : '';
        this.allocatedCount = creditList.allocatedCount || '';
        this.instituteId = creditList.instituteId || '';
        this.oldNewCount = creditList.oldNewCount || '';
        this.purchaseType = creditList.purchaseType || '';
        this.remaining = creditList.remaining ;
        this.schoolName = creditList.schoolName || '';
        this.userCount = creditList.userCount || '';
        this.ValidityFrom = creditList.ValidityFrom || '';
        this.ValidityTo = creditList.ValidityTo || '';
    }
}
