import { Map, Record } from 'immutable';

export interface ServerState extends Map<string, any> {
    getserverDetailList: any;
    getserverDetailListLoading: any;
    getserverDetailListLoaded: any;
    getserverDetailListFailed: any;

    serverActivityLog: any;
    serverActivityLogLoading: any;
    serverActivityLogLoaded: any;
    serverActivityLogFailed: any;
}
export const serverStateRecord = Record({

    getserverDetailList: [],
    getserverDetailListLoading:false,
    getserverDetailListLoaded: false,
    getserverDetailListFailed: false,

    serverActivityLog: [],
    serverActivityLogLoading:false,
    serverActivityLogLoaded: false,
    serverActivityLogFailed: false,

});
