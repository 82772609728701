import {get, has} from 'lodash';
export class CreateInstituteModel {
    // Declare Default Params
    public instituteName: any;
    public address: any;
    public pincode: any;
    public type: any;
    public email: any;
    public contactPerson1: any;
    public contactPerson1Number: any;
    public contactPerson2: any;
    public contactPerson2Number: any;
    public loginId: any;
    public password: any;
    public pocorlive: any;
    public prepaidorpostpaid: any;
    public validFrom: any;
    public validTo: any;
    public smsCaption: any;
    public schoolDid: any;
    public settings: any;
    public salesman: any;
    public institute_branch: any;
    public city: any;
    public region: any;
    public country: any;
    public instituteId: any;
    public instituteStatus: number;
    public isVerify: any;
    public languageId: any;
    public numberOfGroups: any;
    public poOldNumber: any;
    public poNumber: any;
    public isUniversalDid: any;
    public instituteSectorType: any;
    public smsProviderId: any;
    public instituteBoardId: any;
    public utcHours: any;
    public utcMinutes: any;
    public instituteNameRegional: any;


    constructor(instituteCreate: any) {
        if (+get(instituteCreate , 'pocorlive') === 2) {
            this.poNumber = instituteCreate.poNumber || '';
            this.poOldNumber = instituteCreate.poOldNumber || '';
        }
        this.instituteName = instituteCreate.instituteName || '';
        this.isUniversalDid = instituteCreate.isUniversalDid || '';
        this.address = instituteCreate.address || '';
        this.city = instituteCreate.city || '';
        this.pincode = instituteCreate.pincode || '';
        this.type = instituteCreate.type || '';
        this.email = instituteCreate.email || '';
        this.contactPerson1 = instituteCreate.contactPerson1 || '';
        this.contactPerson1Number = instituteCreate.contactPerson1Number || '';
        this.contactPerson2 = instituteCreate.contactPerson2 || '';
        this.contactPerson2Number = instituteCreate.contactPerson2Number || '';
        this.loginId = instituteCreate.loginId || '';
        this.password = instituteCreate.password || '';
        this.pocorlive = instituteCreate.pocorlive || '';
        this.prepaidorpostpaid = instituteCreate.prepaidorpostpaid || '';
        if (get(instituteCreate, 'salesman')) {
            this.salesman = instituteCreate.salesman || '';
        }
        this.validFrom = instituteCreate.validFrom || '';
        this.validTo = instituteCreate.validTo || '';
        this.smsCaption = instituteCreate.smsCaption || '';
        this.schoolDid = instituteCreate.schoolDid || '';
        if (get(instituteCreate, 'institute_branch')) {
            this.institute_branch = instituteCreate.institute_branch || '';
        }
        this.country = instituteCreate.country || '';
        this.region = instituteCreate.region || '';
        this.instituteStatus = instituteCreate.instituteStatus || 0;
        this.languageId = instituteCreate.languageId || '';
        this.numberOfGroups = instituteCreate.numberOfGroups || '';
        if (instituteCreate.instituteBoardId) {
            this.instituteBoardId = instituteCreate.instituteBoardId || '';
        }
        if (instituteCreate.smsProviderId) {
            this.smsProviderId = instituteCreate.smsProviderId || '';
        }
        this.settings = instituteCreate.settings || [];
        // for update passing institute Id
        if (instituteCreate.instituteId) {
            this.instituteId = instituteCreate.instituteId || '';
            this.isVerify = instituteCreate.isVerify || 0;
        }
        this.instituteSectorType = instituteCreate.instituteSectorType || '';
        this.utcHours = instituteCreate.utcHours || '';
        this.utcMinutes = instituteCreate.utcMinutes || '';
        this.instituteNameRegional = instituteCreate.instituteNameRegional || '';
    }
}
