import {Component, HostListener} from '@angular/core';
import { Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent {
  constructor(public router: Router, public location: Location) {
    router.events.subscribe(val => {
      if (location.path() !== '') {
        // localStorage.removeItem('reportId');
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
   localStorage.removeItem('reportId');
  }
}
