import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-revert-student',
  templateUrl: './revert-student.component.html',
  styleUrls: ['./revert-student.component.scss']
})
export class RevertStudentComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }
  close() {
    this.activeModal.close();
  }
}
