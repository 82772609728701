import {Injectable} from '@angular/core';
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {catchError} from 'rxjs/internal/operators';
import * as actions from '../action/user.profile.action';
import {UserProfileService} from '../user.profile.service';
import {get} from 'lodash';
import {Router} from '@angular/router';


@Injectable()
export class UserProfileEffect {

    constructor(private action$: Actions,
                private router: Router,
                private userProfileService: UserProfileService) {
    }

    @Effect()
    doChangePassword$: Observable<Action> = this.action$
        .pipe(
            ofType(actions.ActionTypes.DO_CHANGE_PASSWORD),
            map((action: actions.DoChangePasswordAction) => action.payload),
            switchMap((state) => {
                return this.userProfileService.changePassword(state)
                    .pipe(
                        tap((success) => {
                            if (get(success, 'status') === 1 ) {
                                this.router.navigate(['/institute-mangements']);
                            }
                        }),
                        switchMap((user) => [
                            new actions.DoChangePasswordSucessAction(user),
                        ]),
                        catchError(error => of(new actions.DoChangePasswordFailAction(error)))
                    );
            })
        );

}
