import {Component, OnInit, ElementRef, ViewChild, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {InstituteManagementSandbox} from '../../../../pages/institute-mangements/institute-management.sandbox';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-upload-logo',
    templateUrl: './upload-logo.component.html',
    styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent implements OnInit, OnDestroy {
    @ViewChild('filePath') filePath: ElementRef;

    instituteId: any;
    public files: any;
    public file: any;
    postUrl = 'assets/imgs/uploadImage.png';
    postImageUrl: any;
    isDefaultImage = true;
    fileType: any;
    fileTypeError: any;
    isSelected = false;
    instituteDetails: any;
    isUpload = true;
    fileSizeError = '';
    private subscriptions: Array<Subscription> = [];
    imageValidationArray = ['image/png' , 'image/jpeg' , 'image/jpg'];


    constructor(public activeModal: NgbActiveModal,
                public instituteManagementSandbox: InstituteManagementSandbox) {
    }

    ngOnInit() {
        // this.postImageUrl = 'assets/imgs/uploadImage.png';
        this.fileTypeError = '';
        this.instituteManagementSandbox.uploadInstituteLogoLoading$.subscribe(d => console.log('d', d));
    }

    // upload image using upload logo modal
    uploadChange(event) {
        console.log('event', event);
        this.fileTypeError = '';
        this.fileSizeError = '';
        this.files = event.target.files;
        this.file = this.files[0];
        this.fileType = this.file.type;
        if ( this.imageValidationArray.indexOf(this.fileType) !== -1) {
            if (this.files[0].size < 536650) {
                this.isDefaultImage = false;
                if (event.target.files && event.target.files[0]) {
                    const reader = new FileReader();
                    reader.onload = (events: any) => {
                        this.isSelected = true;
                        this.isUpload = false;
                        this.postImageUrl = events.target.result;
                        //  this.uploadInstituteLogo(this.postUrl);

                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
            } else {
                this.fileSizeError = '(File size Should not exceed more than 500KB)';
                this.isUpload = true;
            }
        } else {
            this.fileTypeError = 'Only (PNG, JPG, JPEG) image files allowed';
            this.isUpload = true;
        }
    }

    uploadButtonClick() {
        const el: HTMLElement = this.filePath.nativeElement as HTMLElement;
        el.click();
    }


// function for calling api
    upload() {
        const params: any = {};
        params.instituteLogo =  this.postImageUrl;
        params.instituteId = this.instituteId;
        this.instituteManagementSandbox.uploadInstituteLogo(params);
         this.closePopUp();
    }
    // calling upload logo
    closePopUp() {
        this.subscriptions.push(this.instituteManagementSandbox.uploadInstituteLogo$.subscribe(data => {
            if (data && data.status === 1) {
                this.activeModal.close();
            }
        }));
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(each => each.unsubscribe());
    }

}
