import { createSelector } from 'reselect';
import { AppState } from '../../../state.interface';
import * as fromInstitute from './institute-management.reducer';
import { InstituteManagementState } from './institute-management.state';

export const getInstituteState = (state: AppState) => state.instituteManagement;
export const createInstitute = createSelector(getInstituteState, fromInstitute.createInstitute);
export const createInstituteLoading = createSelector(getInstituteState, fromInstitute.createInstituteLoading);
export const createInstituteLoaded = createSelector(getInstituteState, fromInstitute.createInstituteLoaded);
export const createInstituteFailed = createSelector(getInstituteState, fromInstitute.createInstituteFailed);

export const instituteList = createSelector(getInstituteState, fromInstitute.instituteList);
export const instituteListLoading = createSelector(getInstituteState, fromInstitute.instituteListLoading);
export const instituteListLoaded = createSelector(getInstituteState, fromInstitute.instituteListLoaded);
export const instituteListFailed = createSelector(getInstituteState, fromInstitute.instituteListFailed);

// country list

export const countryList = createSelector(getInstituteState, fromInstitute.countryList);
export const countryListLoading = createSelector(getInstituteState, fromInstitute.countryListLoading);
export const countryListLoaded = createSelector(getInstituteState, fromInstitute.countryListLoaded);
export const countryListFailed = createSelector(getInstituteState, fromInstitute.countryListFailed);
// region list

export const regionList = createSelector(getInstituteState, fromInstitute.regionList);
export const regionListLoading = createSelector(getInstituteState, fromInstitute.regionListLoading);
export const regionListLoaded = createSelector(getInstituteState, fromInstitute.regionListLoaded);
export const regionListFailed = createSelector(getInstituteState, fromInstitute.regionListFailed);

// city list

export const cityList = createSelector(getInstituteState, fromInstitute.cityList);
export const cityListLoading = createSelector(getInstituteState, fromInstitute.cityListLoading);
export const cityListLoaded = createSelector(getInstituteState, fromInstitute.cityListLoaded);
export const cityListFailed = createSelector(getInstituteState, fromInstitute.cityListFailed);
// school branch type

export const schoolTypeBranch = createSelector(getInstituteState, fromInstitute.schoolTypeBranch);
export const schoolTypeBranchLoading = createSelector(getInstituteState, fromInstitute.schoolTypeBranchLoading);
export const schoolTypeBranchLoaded = createSelector(getInstituteState, fromInstitute.schoolTypeBranchLoaded);
export const schoolTypeBranchFailed = createSelector(getInstituteState, fromInstitute.schoolTypeBranchFailed);

// school sales man
export const instituteSalesMan = createSelector(getInstituteState, fromInstitute.instituteSalesMan);
export const instituteSalesManLoading = createSelector(getInstituteState, fromInstitute.instituteSalesManLoading);
export const instituteSalesManLoaded = createSelector(getInstituteState, fromInstitute.instituteSalesManLoaded);
export const instituteSalesManFailed = createSelector(getInstituteState, fromInstitute.instituteSalesManFailed);

// getting Institute Details
export const instituteDetails = createSelector(getInstituteState, fromInstitute.instituteDetail);
export const instituteDetailsLoading = createSelector(getInstituteState, fromInstitute.instituteDetailLoading);
export const instituteDetailsLoaded = createSelector(getInstituteState, fromInstitute.instituteDetailLoaded);
export const instituteDetailsFailed = createSelector(getInstituteState, fromInstitute.instituteDetailFailed);

// getting institute update

export const updateInstitute = createSelector(getInstituteState, fromInstitute.updateInstitute);
export const updateInstituteLoading = createSelector(getInstituteState, fromInstitute.updateInstituteLoading);
export const updateInstituteLoaded = createSelector(getInstituteState, fromInstitute.updateInstituteLoaded);
export const updateInstituteFailed = createSelector(getInstituteState, fromInstitute.updateInstituteFailed);

// getting pocLive list
export const pocLiveInstituteList = createSelector(getInstituteState, fromInstitute.pocLiveInstituteList);
export const pocLiveInstituteListLoading = createSelector(getInstituteState, fromInstitute.pocLiveInstituteListLoading);
export const pocLiveInstituteListLoaded = createSelector(getInstituteState, fromInstitute.pocLiveInstituteListLoaded);
export const pocLiveInstituteListFailed = createSelector(getInstituteState, fromInstitute.pocLiveInstituteListFailed);

// temp isSelect
export const isSelect = createSelector(getInstituteState, fromInstitute.isSelect);
// getting all services Count
export const instituteCount = createSelector(getInstituteState, fromInstitute.instituteCount);
export const pocCount = createSelector(getInstituteState, fromInstitute.pocCount);
export const liveCount = createSelector(getInstituteState, fromInstitute.liveCount);

// getting institute delete
export const deleteInstitute = createSelector(getInstituteState, fromInstitute.deleteInstitute);
export const deleteInstituteLoading = createSelector(getInstituteState, fromInstitute.deleteInstituteLoading);
export const deleteInstituteLoaded = createSelector(getInstituteState, fromInstitute.deleteInstituteLoaded);
export const deleteInstituteFailed = createSelector(getInstituteState, fromInstitute.deleteInstituteFailed);

// upload institute logo
export const uploadInstituteLogo = createSelector(getInstituteState, fromInstitute.uploadInstituteLogo);
export const uploadInstituteLogoLoading = createSelector(getInstituteState, fromInstitute.uploadInstituteLogoLoading);
export const uploadInstituteLogoLoaded = createSelector(getInstituteState, fromInstitute.uploadInstituteLogoLoaded);
export const uploadInstituteLogoFailed = createSelector(getInstituteState, fromInstitute.uploadInstituteLogoFailed);

// institute approval
export const instituteApproval = createSelector(getInstituteState, fromInstitute.instituteApproval);
export const instituteApprovalLoading = createSelector(getInstituteState, fromInstitute.instituteApprovalLoading);
export const instituteApprovalLoaded = createSelector(getInstituteState, fromInstitute.instituteApprovalLoaded);
export const instituteApprovalFailed = createSelector(getInstituteState, fromInstitute.instituteApprovalFailed);

// institute renewal
export const instituteRenewal = createSelector(getInstituteState, fromInstitute.instituteRenewal);
export const instituteRenewalLoading = createSelector(getInstituteState, fromInstitute.instituteRenewalLoading);
export const instituteRenewalLoaded = createSelector(getInstituteState, fromInstitute.instituteRenewalLoaded);
export const instituteRenewalFailed = createSelector(getInstituteState, fromInstitute.instituteRenewalFailed);

// upload institute file
export const uploadInstituteFile = createSelector(getInstituteState, fromInstitute.uploadInstituteFile);
export const uploadInstituteFileLoading = createSelector(getInstituteState, fromInstitute.uploadInstituteFileLoading);
export const uploadInstituteFileLoaded = createSelector(getInstituteState, fromInstitute.uploadInstituteFileLoaded);
export const uploadInstituteFileFailed = createSelector(getInstituteState, fromInstitute.uploadInstituteFileFailed);

// section list
export const standardList = createSelector(getInstituteState, fromInstitute.standardList);
export const standardListLoading = createSelector(getInstituteState, fromInstitute.standardListLoading);
export const standardListLoaded = createSelector(getInstituteState, fromInstitute.standardListLoaded);
export const standardListFailed = createSelector(getInstituteState, fromInstitute.standardListFailed);

// standard list
export const sectionList = createSelector(getInstituteState, fromInstitute.sectionList);
export const sectionListLoading = createSelector(getInstituteState, fromInstitute.sectionListLoading);
export const sectionListLoaded = createSelector(getInstituteState, fromInstitute.sectionListLoaded);
export const sectionListFailed = createSelector(getInstituteState, fromInstitute.sectionListFailed);

// section standard list
export const sectionStandardUpdate = createSelector(getInstituteState, fromInstitute.sectionStandardUpdate);
export const sectionStandardUpdateLoading = createSelector(getInstituteState, fromInstitute.sectionStandardUpdateLoading);
export const sectionStandardUpdateLoaded = createSelector(getInstituteState, fromInstitute.sectionStandardUpdateLoaded);
export const sectionStandardUpdateFailed = createSelector(getInstituteState, fromInstitute.sectionStandardUpdateFailed);

// get section standard list
export const getSectionStandardUpdate = createSelector(getInstituteState, fromInstitute.getSectionStandardUpdate);
export const getSectionStandardUpdateLoading = createSelector(getInstituteState, fromInstitute.getSectionStandardUpdateLoading);
export const getSectionStandardUpdateLoaded = createSelector(getInstituteState, fromInstitute.getSectionStandardUpdateLoaded);
export const getSectionStandardUpdateFailed = createSelector(getInstituteState, fromInstitute.getSectionStandardUpdateFailed);

// verify institute
export const verifyInstitute = createSelector(getInstituteState, fromInstitute.verifyInstitute);
export const verifyInstituteLoading = createSelector(getInstituteState, fromInstitute.verifyInstituteLoading);
export const verifyInstituteLoaded = createSelector(getInstituteState, fromInstitute.verifyInstituteLoaded);
export const verifyInstituteFailed = createSelector(getInstituteState, fromInstitute.verifyInstituteFailed);
// additional module
export const additionalModuleList = createSelector(getInstituteState, fromInstitute.additionalModuleList);
export const additionalModuleListLoading = createSelector(getInstituteState, fromInstitute.additionalModuleListLoading);
export const additionalModuleListLoaded = createSelector(getInstituteState, fromInstitute.additionalModuleListLoaded);
export const additionalModuleListFailed = createSelector(getInstituteState, fromInstitute.additionalModuleListFailed);
// get language list
export const languageList = createSelector(getInstituteState, fromInstitute.languageList);
export const languageListLoading = createSelector(getInstituteState, fromInstitute.languageListLoading);
export const languageListLoaded = createSelector(getInstituteState, fromInstitute.languageListLoaded);
export const languageListFailed = createSelector(getInstituteState, fromInstitute.languageListFailed);

// institute count

export const approvedCount = createSelector(getInstituteState, fromInstitute.approvedCount);
export const approvalPendingCount = createSelector(getInstituteState, fromInstitute.approvalPendingCount);
export const verificationPendingCount = createSelector(getInstituteState, fromInstitute.verificationPendingCount);
export const inactiveCount = createSelector(getInstituteState, fromInstitute.inactiveCount);
export const activeCount = createSelector(getInstituteState, fromInstitute.activeCount);
export const institutePaidCount = createSelector(getInstituteState, fromInstitute.institutePaidCount);
export const instituteCsrCount = createSelector(getInstituteState, fromInstitute.instituteCsrCount);
// institute approval verification

export const approvalVerificationList = createSelector(getInstituteState, fromInstitute.approvalVerificationList);
export const approvalVerificationListLoading = createSelector(getInstituteState, fromInstitute.approvalVerificationListLoading);
export const approvalVerificationListLoaded = createSelector(getInstituteState, fromInstitute.approvalVerificationListLoaded);
export const approvalVerificationListFailed = createSelector(getInstituteState, fromInstitute.approvalVerificationListFailed);

// institute active inactive list
export const activeInactiveList = createSelector(getInstituteState, fromInstitute.activeInactiveList);
export const activeInactiveListLoading = createSelector(getInstituteState, fromInstitute.activeInactiveListLoading);
export const activeInactiveListLoaded = createSelector(getInstituteState, fromInstitute.activeInactiveListLoaded);
export const activeInactiveListFailed = createSelector(getInstituteState, fromInstitute.activeInactiveListFailed);
// institute provider list
export const smsProviderList = createSelector(getInstituteState, fromInstitute.smsProviderList);
export const smsProviderListLoading = createSelector(getInstituteState, fromInstitute.smsProviderListLoading);
export const smsProviderListLoaded = createSelector(getInstituteState, fromInstitute.smsProviderListLoaded);
export const smsProviderListFailed = createSelector(getInstituteState, fromInstitute.smsProviderListFailed);
// institute board List
export const boardList = createSelector(getInstituteState, fromInstitute.boardList);
export const boardListLoading = createSelector(getInstituteState, fromInstitute.boardListLoading);
export const boardListLoaded = createSelector(getInstituteState, fromInstitute.boardListLoaded);
export const boardListFailed = createSelector(getInstituteState, fromInstitute.boardListFailed);
// all institute List
export const allInstituteList = createSelector(getInstituteState, fromInstitute.allInstituteList);
export const allInstituteListLoading = createSelector(getInstituteState, fromInstitute.allInstituteListLoading);
export const allInstituteListLoaded = createSelector(getInstituteState, fromInstitute.allInstituteListLoaded);
export const allInstituteListFailed = createSelector(getInstituteState, fromInstitute.allInstituteListFailed);
// institute search count
export const instituteSearchCount = createSelector(getInstituteState, fromInstitute.instituteSearchCount);

// all institute List
export const exportInstitute = createSelector(getInstituteState, fromInstitute.exportInstitute);
export const exportInstituteLoading = createSelector(getInstituteState, fromInstitute.exportInstituteLoading);
export const exportInstituteLoaded = createSelector(getInstituteState, fromInstitute.exportInstituteLoaded);
export const exportInstituteFailed = createSelector(getInstituteState, fromInstitute.exportInstituteFailed);

export const redirectUrl = createSelector(getInstituteState, fromInstitute.redirectUrl);
export const redirectUrlLoading = createSelector(getInstituteState, fromInstitute.redirectUrlLoading);
export const redirectUrlLoaded = createSelector(getInstituteState, fromInstitute.redirectUrlLoaded);

//
export const academicYearDetails = createSelector(getInstituteState, fromInstitute.academicYearDetails);

export const changeAcademicYearSetting = createSelector(getInstituteState, fromInstitute.changeAcademicYearSetting);
export const academicYearList = createSelector(getInstituteState, fromInstitute.academicYearList);

export const GetScheduledInstituteDetails = createSelector(getInstituteState, fromInstitute.GetScheduledInstituteDetails);
export const GetScheduledInstituteDetailsLoading = createSelector(getInstituteState, fromInstitute.GetScheduledInstituteDetailsLoading);
