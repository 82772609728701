import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {StoreModule} from '@ngrx/store';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ModalModule } from 'ngx-bootstrap';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ContainerModule, CONTAINERS} from './common';
import {ComponentsModule} from './shared/components';
import { NotFoundComponent } from './pages/page-not-found/not-found.component';
import {AuthGuard} from './services/auth.guard';
import {metaReducers, reducers} from './reducer.interface';
import {Api} from './services/providers';
import {EffectsModule} from '@ngrx/effects';
import {RequestInterceptor} from './services/request.interceptor';
import {ToastrModule} from 'ng6-toastr-notifications';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {DeleteComponent} from './shared/components/popup/delete/delete.component';
import {UserManagementSandbox} from './pages/user-managements/user-management.sandbox';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NumberAcceptModule} from './shared/validation-directives/onlyNumber.module';
import { InstituteManagementSandbox } from './pages/institute-mangements/institute-management.sandbox';
import {RenewComponent} from './shared/components/popup/renew/renew.component';
import {ApproveComponent} from './shared/components/popup/approve/approve.component';
import {UploadComponent} from './shared/components/popup/upload/upload.component';
import {UploadLogoComponent} from './shared/components/popup/upload-logo/upload-logo.component';
import {SpinnerComponent} from './shared/components/spinner/spinner.component';
import {VerifyComponent} from './shared/components/popup/veify/verify.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import {ViewRenewSchoolComponent} from './shared/components/popup/view-renew-school/view-renew-school.component';
import {PrintRenewalReportComponent} from './shared/components/print-renewal-report/print-renewal-report.component';
import {PlayerComponent} from './shared/components/player/player.component';
import {NotificationComponent} from './shared/components/popup/notification/notification.component';
import {SharedModule} from './shared/shared.module';



const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
};
@NgModule({
  declarations: [
      AppComponent,
      CONTAINERS.LayoutComponent,
      NotFoundComponent,
      DeleteComponent,
      RenewComponent,
      ApproveComponent,
      UploadComponent,
      UploadLogoComponent,
      VerifyComponent,
      ViewRenewSchoolComponent,
      PrintRenewalReportComponent,
      PlayerComponent,
      NotificationComponent
      // LayoutComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ComponentsModule,
        SwiperModule,
        AppRoutingModule,
        ContainerModule,
        ToastrModule.forRoot(),
        ModalModule.forRoot(),
        EffectsModule.forRoot([]),
        StoreModule.forRoot(reducers, {metaReducers}),
        ReactiveFormsModule,
        MaterialModule,
        NumberAcceptModule,
        SelectDropDownModule,
        NgbModule.forRoot(),
        NgxAudioPlayerModule,
        SharedModule

    ],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true
      },
      {
          provide: SWIPER_CONFIG,
          useValue: DEFAULT_SWIPER_CONFIG
      },
      {
          provide: LocationStrategy,
          useClass: HashLocationStrategy
      },
      AuthGuard,
      Api,
      UserManagementSandbox,
      InstituteManagementSandbox,
      NgbActiveModal
  ],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    entryComponents: [DeleteComponent , RenewComponent , ApproveComponent, UploadComponent, UploadLogoComponent, VerifyComponent, ViewRenewSchoolComponent, NotificationComponent]

})
export class AppModule { }
