import { ServerState, serverStateRecord } from './server.state';
import * as actions from '../action/server.action';
// Model

export const initialState: ServerState = new serverStateRecord() as ServerState;
export function reducer(state = initialState, { type, payload }: any): ServerState {
    if (!type) {
        return state;
    }
    switch (type) {

    
          case actions.ActionTypes.SERVER_DETAIL_LIST:
          return Object.assign({}, state, {
            getserverDetailList: [],
            getserverDetailListLoading: true,
            getserverDetailListLoaded: false,
            getserverDetailListFailed: false,
          });
        case actions.ActionTypes.SERVER_DETAIL_LIST_SUCCESS:
          return Object.assign({}, state, {
            getserverDetailList: payload.data,
            getserverDetailListLoading: false,
            getserverDetailListLoaded: true,
            getserverDetailListFailed: false,
          });
        case actions.ActionTypes.SERVER_DETAIL_LIST_FAIL:
          return Object.assign({}, state, {
            getserverDetailListLoading: false,
            getserverDetailListLoaded: false,
            getserverDetailListFailed: true,
          });

          case actions.ActionTypes.SERVER_ACTIVITY_LOG:
          return Object.assign({}, state, {
            serverActivityLog: [],
            serverActivityLogLoading: true,
            serverActivityLogLoaded: false,
            serverActivityLogFailed: false,
          });
        case actions.ActionTypes.SERVER_ACTIVITY_LOG_SUCCESS:
          return Object.assign({}, state, {
            serverActivityLog: payload.data,
            serverActivityLogLoading: false,
            serverActivityLogLoaded: true,
            serverActivityLogFailed: false,
          });
        case actions.ActionTypes.SERVER_ACTIVITY_LOG_FAIL:
          return Object.assign({}, state, {
            serverActivityLogLoading: false,
            serverActivityLogLoaded: false,
            serverActivityLogFailed: true,
          });

        default: {
          return state;
        }
      }
    }
    export const getserverDetailList = (state: ServerState) => state.getserverDetailList;
    export const getserverDetailListLoading = (state: ServerState) => state.getserverDetailListLoading;
    export const getserverDetailListLoaded = (state: ServerState) => state.getserverDetailListLoaded;
    export const getserverDetailListFailed = (state: ServerState) => state.getserverDetailListFailed;

    export const serverActivityLog = (state: ServerState) => state.serverActivityLog;
    export const serverActivityLogLoading = (state: ServerState) => state.serverActivityLogLoading;
    export const serverActivityLogLoaded = (state: ServerState) => state.serverActivityLogLoaded;
    export const serverActivityLogFailed = (state: ServerState) => state.serverActivityLogFailed;
    
