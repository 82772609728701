import {Action} from '@ngrx/store';
import {type} from '../../../shared/utility/utilityHelpers';

export const ActionTypes = {
    DO_CHANGE_PASSWORD: type('[change] Do Change Password'),
    DO_CHANGE_PASSWORD_SUCCESS: type('[change] Do Change Password Success'),
    DO_CHANGE_PASSWORD_FAIL: type('[change] Do Change Password Fail')
};

export class DoChangePasswordAction implements Action {
    type = ActionTypes.DO_CHANGE_PASSWORD;

    constructor(public payload: any) {
    }
}

export class DoChangePasswordSucessAction implements Action {
    type = ActionTypes.DO_CHANGE_PASSWORD_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DoChangePasswordFailAction implements Action {
    type = ActionTypes.DO_CHANGE_PASSWORD_FAIL;

    constructor(public payload: any = null) {

    }
}


export type Actions
    = DoChangePasswordAction
    | DoChangePasswordSucessAction
    | DoChangePasswordFailAction;
