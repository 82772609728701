import { Action } from '@ngrx/store';
import { type } from '../../../shared/utility/utilityHelpers';
import { ResponsInterface } from '../../../response-model/response-interface';

export const ActionTypes = {
    // report List
    REPORT_LIST: type('[REPORTS] REPORT_LIST'),
    REPORT_LIST_SUCCESS: type('[REPORTS] REPORT_LIST Success'),
    REPORT_LIST_FAIL: type('[REPORTS] REPORT_LIST Fail'),
    // Sms Credit
    SMS_CREDIT_LIST: type('[REPORTS] SMS_CREDIT_LIST'),
    SMS_CREDIT_LIST_SUCCESS: type('[REPORTS] SMS_CREDIT_LIST Success'),
    SMS_CREDIT_LIST_FAIL: type('[REPORTS] SMS_CREDIT_LIST Fail'),
    // Sms Credit count
    SMS_CREDIT_COUNT: type('[REPORTS] SMS_CREDIT_COUNT'),
    SMS_CREDIT_COUNT_SUCCESS: type('[REPORTS] SMS_CREDIT_COUNT Success'),
    SMS_CREDIT_COUNT_FAIL: type('[REPORTS] SMS_CREDIT_COUNT Fail'),
    // invalid record
    INVALID_RECORD_LIST: type('[REPORTS] INVALID_RECORD_LIST'),
    INVALID_RECORD_LIST_SUCCESS: type('[REPORTS] INVALID_RECORD_LIST Success'),
    INVALID_RECORD_LIST_FAIL: type('[REPORTS] INVALID_RECORD_LIST Fail'),
    // invalid record
    INVALID_RECORD_COUNT: type('[REPORTS] INVALID_RECORD_COUNT'),
    INVALID_RECORD_COUNT_SUCCESS: type('[REPORTS] INVALID_RECORD_COUNT Success'),
    INVALID_RECORD_COUNT_FAIL: type('[REPORTS] INVALID_RECORD_COUNT Fail'),
    // school History
    SCHOOL_HISTORY_LIST: type('[REPORTS]SCHOOL_HISTORY_LIST'),
    SCHOOL_HISTORY_LIST_SUCCESS: type('[REPORTS]SCHOOL_HISTORY_LIST Success'),
    SCHOOL_HISTORY_LIST_FAIL: type('[REPORTS]SCHOOL_HISTORY_LIST Fail'),
    // school History count
    SCHOOL_HISTORY_COUNT: type('[REPORTS] SCHOOL_HISTORY_COUNT'),
    SCHOOL_HISTORY_COUNT_SUCCESS: type('[REPORTS] SCHOOL_HISTORY_COUNT Success'),
    SCHOOL_HISTORY_COUNT_FAIL: type('[REPORTS] SCHOOL_HISTORY_COUNT Fail'),
    // school History count
    STOPPED_SCHOOL_LIST: type('[REPORTS] STOPPED_SCHOOL_LIST'),
    STOPPED_SCHOOL_LIST_SUCCESS: type('[REPORTS] STOPPED_SCHOOL_LIST Success'),
    STOPPED_SCHOOL_LIST_FAIL: type('[REPORTS] STOPPED_SCHOOL_LIST Fail'),
    // school History count
    STOPPED_SCHOOL_COUNT: type('[REPORTS] STOPPED_SCHOOL_COUNT'),
    STOPPED_SCHOOL_COUNT_SUCCESS: type('[REPORTS] STOPPED_SCHOOL_COUNT Success'),
    STOPPED_SCHOOL_COUNT_FAIL: type('[REPORTS] STOPPED_SCHOOL_COUNT Fail'),
    // school History count
    STOPPED_SCHOOL_ENABLE: type('[REPORTS] STOPPED_SCHOOL_ENABLE'),
    STOPPED_SCHOOL_ENABLE_SUCCESS: type('[REPORTS] STOPPED_SCHOOL_ENABLE Success'),
    STOPPED_SCHOOL_ENABLE_FAIL: type('[REPORTS] STOPPED_SCHOOL_ENABLE Fail'),
    // school History count
    CIRCULAR_SMS_LIST: type('[REPORTS] CIRCULAR_SMS_LIST'),
    CIRCULAR_SMS_LIST_SUCCESS: type('[REPORTS] CIRCULAR_SMS_LIST Success'),
    CIRCULAR_SMS_LIST_FAIL: type('[REPORTS] CIRCULAR_SMS_LIST Fail'),
    // school History count
    CIRCULAR_SMS_COUNT: type('[REPORTS] CIRCULAR_SMS_COUNT'),
    CIRCULAR_SMS_COUNT_SUCCESS: type('[REPORTS] CIRCULAR_SMS_COUNT Success'),
    CIRCULAR_SMS_COUNT_FAIL: type('[REPORTS] CIRCULAR_SMS_COUNT Fail'),
    // renewal schools
    RENEWAL_SCHOOL: type('[REPORTS] RENEWAL_SCHOOL'),
    RENEWAL_SCHOOL_SUCCESS: type('[REPORTS] RENEWAL_SCHOOL Success'),
    RENEWAL_SCHOOL_FAIL: type('[REPORTS] RENEWAL_SCHOOL Fail'),
    SEARCH_RENEWAL_SCHOOL: type('[REPORTS] SEARCH_RENEWAL_SCHOOL Success'),
    // renewal school count
    RENEWAL_SCHOOL_COUNT: type('[REPORTS] RENEWAL_SCHOOL_COUNT'),
    RENEWAL_SCHOOL_COUNT_SUCCESS: type('[REPORTS] RENEWAL_SCHOOL_COUNT Success'),
    RENEWAL_SCHOOL_COUNT_FAIL: type('[REPORTS] RENEWAL_SCHOOL_COUNT Fail'),

    DELETED_SCHOOL_EXPORT: type('[REPORTS] DELETED_SCHOOL_EXPORT'),
    DELETED_SCHOOL_EXPORT_SUCCESS: type('[REPORTS] DELETED_SCHOOL_EXPORT Success'),
    DELETED_SCHOOL_EXPORT_FAIL: type('[REPORTS] DELETED_SCHOOL_EXPORT Fail'),

    SCHOOL_HISTORY_EXPORT: type('[REPORTS] SCHOOL_HISTORY_EXPORT'),
    SCHOOL_HISTORY_EXPORT_SUCCESS: type('[REPORTS] SCHOOL_HISTORY_EXPORT Success'),
    SCHOOL_HISTORY_EXPORT_FAIL: type('[REPORTS] SCHOOL_HISTORY_EXPORT Fail'),

    SMS_CIRCULAR_REPORT: type('[REPORTS] SMS_CIRCULAR_REPORT'),
    SMS_CIRCULAR_REPORT_SUCCESS: type('[REPORTS] SMS_CIRCULAR_REPORT Success'),
    SMS_CIRCULAR_REPORT_FAIL: type('[REPORTS] SMS_CIRCULAR_REPORT Fail'),

    Renewal_SCHOOL_REPORT: type('[REPORTS] Renewal_SCHOOL_REPORT'),
    Renewal_SCHOOL_REPORT_SUCCESS: type('[REPORTS] Renewal_SCHOOL_REPORT Success'),
    Renewal_SCHOOL_REPORT_FAIL: type('[REPORTS] Renewal_SCHOOL_REPORT Fail'),

    SMS_CREDIT_REPORTS: type('[REPORTS] SMS_CREDIT_REPORTS'),
    SMS_CREDIT_REPORTS_SUCCESS: type('[REPORTS] SMS_CREDIT_REPORTS Success'),
    SMS_CREDIT_REPORTS_FAIL: type('[REPORTS] SMS_CREDIT_REPORTS Fail'),

    STOPPED_SCHOOLS_EXPORT: type('[REPORTS] STOPPED_SCHOOLS_EXPORT'),
    STOPPED_SCHOOLS_EXPORT_SUCCESS: type('[REPORTS] STOPPED_SCHOOLS_EXPORT Success'),
    STOPPED_SCHOOLS_EXPORT_FAIL: type('[REPORTS] STOPPED_SCHOOLS_EXPORT Fail'),

    SCHOOLS_APP_USAGE_EXPORT: type('[REPORTS] SCHOOLS_APP_USAGE_EXPORT'),
    SCHOOLS_APP_USAGE_EXPORT_SUCCESS: type('[REPORTS] SCHOOLS_APP_USAGE_EXPORT Success'),
    SCHOOLS_APP_USAGE_EXPORT_FAIL: type('[REPORTS] SCHOOLS_APP_USAGE_EXPORT Fail'),

    // redirect to institute url
    REDIRECT_TO_INSTITUTE: type('[REPORTS] REDIRECT_TO_INSTITUTE'),
    REDIRECT_TO_INSTITUTE_SUCCESS: type('[REPORTS] REDIRECT_TO_INSTITUTE SUCCESS'),
    REDIRECT_TO_INSTITUTE_FAIL: type('[REPORTS] REDIRECT_TO_INSTITUTE FAIL'),
    // voice credits list
    VOICE_CREDITS_LIST: type('[REPORTS] VOICE_CREDITS_LIST'),
    VOICE_CREDITS_LIST_SUCCESS: type('[REPORTS] VOICE_CREDITS_LIST Success'),
    VOICE_CREDITS_LIST_FAIL: type('[REPORTS] VOICE_CREDITS_LIST Fail'),
    // voice credits count
    VOICE_CREDITS_COUNT: type('[REPORTS] VOICE_CREDITS_COUNT'),
    VOICE_CREDITS_COUNT_SUCCESS: type('[REPORTS] VOICE_CREDITS_COUNT Success'),
    VOICE_CREDITS_COUNT_FAIL: type('[REPORTS] VOICE_CREDITS_COUNT Fail'),

    VOICE_CREDITS_Export: type('[REPORTS] VOICE_CREDITS_Export'),
    VOICE_CREDITS_Export_SUCCESS: type('[REPORTS] VOICE_CREDITS_Export Success'),
    VOICE_CREDITS_Export_FAIL: type('[REPORTS] VOICE_CREDITS_Export Fail'),

    // school App usage list
    SCHOOL_APP_USAGE_LIST: type('[REPORTS] SCHOOL_APP_USAGE_LIST'),
    SCHOOL_APP_USAGE_LIST_SUCCESS: type('[REPORTS] SCHOOL_APP_USAGE_LIST_SUCCESS Success'),
    SCHOOL_APP_USAGE_LIST_FAIL: type('[REPORTS] SCHOOL_APP_USAGE_LIST_FAIL Fail'),
    // voice credits count
    SCHOOL_APP_USAGE_COUNT: type('[REPORTS] SCHOOL_APP_USAGE_COUNT'),
    SCHOOL_APP_USAGE_COUNT_SUCCESS: type('[REPORTS] SCHOOL_APP_USAGE_COUNT_SUCCESS Success'),
    SCHOOL_APP_USAGE_COUNT_FAIL: type('[REPORTS] SCHOOL_APP_USAGE_COUNT_FAIL Fail'),
    // voice circular
    VOICE_CIRCULAR_LIST: type('[REPORTS] VOICE_CIRCULAR_LIST'),
    VOICE_CIRCULAR_LIST_SUCCESS: type('[REPORTS] VOICE_CIRCULAR_LIST_SUCCESS Success'),
    VOICE_CIRCULAR_LIST_FAIL: type('[REPORTS] VOICE_CIRCULAR_LIST_FAIL Fail'),

    VOICE_CIRCULAR_COUNT: type('[REPORTS] VOICE_CIRCULAR_COUNT'),
    VOICE_CIRCULAR_COUNT_SUCCESS: type('[REPORTS] VOICE_CIRCULAR_COUNT_SUCCESS Success'),
    VOICE_CIRCULAR_COUNT_FAIL: type('[REPORTS] VOICE_CIRCULAR_COUNT_FAIL Fail'),

    VOICE_CIRCULAR_REPORT: type('[REPORTS] VOICE_CIRCULAR_REPORT'),
    VOICE_CIRCULAR_REPORT_SUCCESS: type('[REPORTS] VOICE_CIRCULAR_REPORT_SUCCESS Success'),
    VOICE_CIRCULAR_REPORT_FAIL: type('[REPORTS] VOICE_CIRCULAR_REPORT_FAIL Fail'),

    INSTITUTE_VOICE_USAGE_LIST: type('[REPORTS] INSTITUTE_VOICE_USAGE_LIST'),
    INSTITUTE_VOICE_USAGE_LIST_SUCCESS: type('[REPORTS] INSTITUTE_VOICE_USAGE_LIST_SUCCESS Success'),
    INSTITUTE_VOICE_USAGE_LIST_FAIL: type('[REPORTS] INSTITUTE_VOICE_USAGE_LIST_FAIL Fail'),

    INSTITUTE_VOICE_USAGE_COUNT: type('[REPORTS] INSTITUTE_VOICE_USAGE_COUNT'),
    INSTITUTE_VOICE_USAGE_COUNT_SUCCESS: type('[REPORTS] INSTITUTE_VOICE_USAGE_COUNT_SUCCESS Success'),
    INSTITUTE_VOICE_USAGE_COUNT_FAIL: type('[REPORTS] INSTITUTE_VOICE_USAGE_COUNT_FAIL Fail'),

    EXPORT_INSTITUTE_VOICE_USAGE: type('[REPORTS] EXPORT_INSTITUTE_VOICE_USAGE'),
    EXPORT_INSTITUTE_VOICE_USAGE_SUCCESS: type('[REPORTS] EXPORT_INSTITUTE_VOICE_USAGE_SUCCESS Success'),
    EXPORT_INSTITUTE_VOICE_USAGE_FAIL: type('[REPORTS] EXPORT_INSTITUTE_VOICE_USAGE_FAIL Fail'),

    GET_SCHOOL_DETAILS_LIST: type('[REPORTS] get school details list action'),
    GET_SCHOOL_DETAILS_LIST_SUCCESS: type('[REPORTS] get school profile list success'),
    GET_SCHOOL_DETAILS_LIST_FAIL: type('[REPORTS] get school profile list fail'),

    SCHOOL_PROFILE_EXPORT: type('[REPORTS] school profile export'),
    SCHOOL_PROFILE_EXPORT_SUCCESS: type('[REPORTS] school profile export success'),
    SCHOOL_PROFILE_EXPORT_FAIL: type('[REPORTS] school profile export fail'),

    SCHOOL_PROFILE_ACKNOWLEDGE: type('[REPORTS] school profile is acknowledge'),
    SCHOOL_PROFILE_ACKNOWLEDGE_SUCCESS: type('[REPORTS] profile is acknowledge success'),
    SCHOOL_PROFILE_ACKNOWLEDGE_FAIL: type('[REPORTS] profile is acknowledge is fail'),

    REDIRECT_TO_SCHOOL_PROFILE: type('[REPORTS] redirect to school profile'),
    REDIRECT_TO_SCHOOL_PROFILE_SUCCESS: type('[REPORTS] redirect to school profile success'),
    REDIRECT_TO_SCHOOL_PROFILE_FAIL: type('[REPORTS] redirect to school profile fail'),


    SCHOOL_PROFILE_CHANGES_POPUP: type('[REPORTS] school profile changes popup'),
    SCHOOL_PROFILE_CHANGES_POPUP_SUCCESS: type('[REPORTS] school profile changes popup success'),
    SCHOOL_PROFILE_CHANGES_POPUP_FAIL: type('[REPORTS] school profile changes popup fail'),

    VERIFY_RENEWAL_SCHOOL: type('[REPORTS] VERIFY_RENEWAL_SCHOOL'),
    VERIFY_RENEWAL_SCHOOL_SUCCESS: type('[REPORTS] VERIFY_RENEWAL_SCHOOL_SUCCESS'),
    VERIFY_RENEWAL_SCHOOL_FAIL: type('[REPORTS] VERIFY_RENEWAL_SCHOOL_FAIL'),
   
    INTEREST_SCHOOL_LIST: type('[REPORTS] INTEREST_SCHOOL_LIST'),
    INTEREST_SCHOOL_LIST_SUCCESS: type('[REPORTS] INTEREST_SCHOOL_LIST_SUCCESS'),
    INTEREST_SCHOOL_LIST_FAIL: type('[REPORTS] INTEREST_SCHOOL_LIST_FAIL'),

    INTEREST_SCHOOL_LIST_COUNT: type('[REPORTS] INTEREST_SCHOOL_LIST_COUNT'),
    INTEREST_SCHOOL_LIST_COUNT_SUCCESS: type('[REPORTS] INTEREST_SCHOOL_LIST_COUNT_SUCCESS'),
    INTEREST_SCHOOL_LIST_COUNT_FAIL: type('[REPORTS] INTEREST_SCHOOL_LIST_COUNT_FAIL'),
    
    CLEAR_LIST: type('[REPORTS] CLEAR_LIST'),

    LIVE_SCHOOL_EXPORT:type('[REPORTS] LIVE_SCHOOL_EXPORT'),
    LIVE_SCHOOL_EXPORT_SUCCESS:type('[REPORTS] LIVE_SCHOOL_EXPORT_SUCCESS'),
    LIVE_SCHOOL_EXPORT_FAIL:type('[REPORTS] LIVE_SCHOOL_EXPORT_FAIL'),
};
// report ACTION
export class ReportListAction implements Action {
    type = ActionTypes.REPORT_LIST;

    constructor(public payload: any) {
    }
}
export class ReportListSuccess implements Action {
    type = ActionTypes.REPORT_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class ReportListFail implements Action {
    type = ActionTypes.REPORT_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}
// SMS Credit
export class SmsCreditListAction implements Action {
    type = ActionTypes.SMS_CREDIT_LIST;
    constructor(public payload: any) {
    }
}
export class SmsCreditListSuccess implements Action {
    type = ActionTypes.SMS_CREDIT_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class SmsCreditListFail implements Action {
    type = ActionTypes.SMS_CREDIT_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}
// SMS Credit count
export class SmsCreditCountAction implements Action {
    type = ActionTypes.SMS_CREDIT_COUNT;
    constructor(public payload: any) {
    }
}
export class SmsCreditCountSuccess implements Action {
    type = ActionTypes.SMS_CREDIT_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class SmsCreditCountFail implements Action {
    type = ActionTypes.SMS_CREDIT_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
// invalid record
export class InvalidRecordAction implements Action {
    type = ActionTypes.INVALID_RECORD_LIST;
    constructor(public payload: any) {
    }
}
export class InvalidRecordSuccess implements Action {
    type = ActionTypes.INVALID_RECORD_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class InvalidRecordFail implements Action {
    type = ActionTypes.INVALID_RECORD_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}
// invalid count record
export class InvalidRecordCountAction implements Action {
    type = ActionTypes.INVALID_RECORD_COUNT;
    constructor(public payload: any) {
    }
}
export class InvalidRecordCountSuccess implements Action {
    type = ActionTypes.INVALID_RECORD_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class InvalidRecordCountFail implements Action {
    type = ActionTypes.INVALID_RECORD_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
// school history list
export class SchoolHistoryAction implements Action {
    type = ActionTypes.SCHOOL_HISTORY_LIST;
    constructor(public payload: any) {
    }
}
export class SchoolHistorySuccess implements Action {
    type = ActionTypes.SCHOOL_HISTORY_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolHistoryFail implements Action {
    type = ActionTypes.SCHOOL_HISTORY_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}
// school history count
export class SchoolHistoryCountAction implements Action {
    type = ActionTypes.SCHOOL_HISTORY_COUNT;
    constructor(public payload: any) {
    }
}
export class SchoolHistoryCountSuccess implements Action {
    type = ActionTypes.SCHOOL_HISTORY_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolHistoryCountFail implements Action {
    type = ActionTypes.SCHOOL_HISTORY_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
// stopped school list
export class StoppedSchoolListAction implements Action {
    type = ActionTypes.STOPPED_SCHOOL_LIST;
    constructor(public payload: any) {
    }
}
export class StoppedSchoolListSuccess implements Action {
    type = ActionTypes.STOPPED_SCHOOL_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class StoppedSchoolListFail implements Action {
    type = ActionTypes.STOPPED_SCHOOL_LIST;

    constructor(public payload: any = null) {
    }
}
// stopped school count
export class StoppedSchoolCountAction implements Action {
    type = ActionTypes.STOPPED_SCHOOL_COUNT;
    constructor(public payload: any) {
    }
}
export class StoppedSchoolCountSuccess implements Action {
    type = ActionTypes.STOPPED_SCHOOL_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class StoppedSchoolCountFail implements Action {
    type = ActionTypes.STOPPED_SCHOOL_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
// stopped school enable
export class StoppedSchoolEnableAction implements Action {
    type = ActionTypes.STOPPED_SCHOOL_ENABLE;
    constructor(public payload: any) {
    }
}
export class StoppedSchoolEnableSuccess implements Action {
    type = ActionTypes.STOPPED_SCHOOL_ENABLE_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class StoppedSchoolEnableFail implements Action {
    type = ActionTypes.STOPPED_SCHOOL_ENABLE_FAIL;

    constructor(public payload: any = null) {
    }
}
// circular sms list
export class CircularSmsListAction implements Action {
    type = ActionTypes.CIRCULAR_SMS_LIST;
    constructor(public payload: any) {
    }
}
export class CircularSmsListSuccess implements Action {
    type = ActionTypes.CIRCULAR_SMS_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class CircularSmsListFail implements Action {
    type = ActionTypes.CIRCULAR_SMS_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}
// circular sms Count
export class CircularSmsCountAction implements Action {
    type = ActionTypes.CIRCULAR_SMS_COUNT;
    constructor(public payload: any) {
    }
}
export class CircularSmsCountSuccess implements Action {
    type = ActionTypes.CIRCULAR_SMS_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class CircularSmsCountFail implements Action {
    type = ActionTypes.CIRCULAR_SMS_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
// circular sms Count
export class RenewalSchoolAction implements Action {
    type = ActionTypes.RENEWAL_SCHOOL;
    constructor(public payload: any) {
    }
}
export class RenewalSchoolSuccess implements Action {
    type = ActionTypes.RENEWAL_SCHOOL_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class RenewalSchoolFail implements Action {
    type = ActionTypes.RENEWAL_SCHOOL_FAIL;
    constructor(public payload: any = null) {
    }
}
export class SearchRenewalSchoolAction implements Action {
    type = ActionTypes.SEARCH_RENEWAL_SCHOOL;
    constructor(public payload: any) {
    }
}
// circular sms Count
export class RenewalSchoolCountAction implements Action {
    type = ActionTypes.RENEWAL_SCHOOL_COUNT;
    constructor(public payload: any) {
    }
}
export class RenewalSchoolCountSuccess implements Action {
    type = ActionTypes.RENEWAL_SCHOOL_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class RenewalSchoolCountFail implements Action {
    type = ActionTypes.RENEWAL_SCHOOL_COUNT_FAIL;
    constructor(public payload: any = null) {
    }
}

export class DeletedSchoolExportAction implements Action {
    type = ActionTypes.DELETED_SCHOOL_EXPORT;
    constructor(public payload: any) {
    }
}
export class DeletedSchoolExportSuccess implements Action {
    type = ActionTypes.DELETED_SCHOOL_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class DeletedSchoolExportFail implements Action {
    type = ActionTypes.DELETED_SCHOOL_EXPORT_FAIL;
    constructor(public payload: any = null) {
    }
}

export class SchoolHistoryExportAction implements Action {
    type = ActionTypes.SCHOOL_HISTORY_EXPORT;
    constructor(public payload: any) {
    }
}
export class SchoolHistoryExportSuccess implements Action {
    type = ActionTypes.SCHOOL_HISTORY_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolHistoryExportFail implements Action {
    type = ActionTypes.SCHOOL_HISTORY_EXPORT_FAIL;
    constructor(public payload: any = null) {
    }
}
export class SmsCircularReportAction implements Action {
    type = ActionTypes.SMS_CIRCULAR_REPORT;
    constructor(public payload: any) {
    }
}
export class SmsCircularReportSuccess implements Action {
    type = ActionTypes.SMS_CIRCULAR_REPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SmsCircularReportFail implements Action {
    type = ActionTypes.SMS_CIRCULAR_REPORT_FAIL;
    constructor(public payload: any = null) {
    }
}

export class RenewalSchoolReportAction implements Action {
    type = ActionTypes.Renewal_SCHOOL_REPORT;
    constructor(public payload: any) {
    }
}
export class RenewalSchoolReportSuccess implements Action {
    type = ActionTypes.Renewal_SCHOOL_REPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class RenewalSchoolReportFail implements Action {
    type = ActionTypes.Renewal_SCHOOL_REPORT_FAIL;
    constructor(public payload: any = null) {
    }
}
export class SmsCreditReportAction implements Action {
    type = ActionTypes.SMS_CREDIT_REPORTS;
    constructor(public payload: any) {
    }
}
export class SmsCreditReportSuccess implements Action {
    type = ActionTypes.SMS_CREDIT_REPORTS_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SmsCreditReportFail implements Action {
    type = ActionTypes.SMS_CREDIT_REPORTS_FAIL;
    constructor(public payload: any = null) {
    }
}
export class StoppedSchoolExportAction implements Action {
    type = ActionTypes.STOPPED_SCHOOLS_EXPORT;
    constructor(public payload: any) {
    }
}
export class StoppedSchoolExportSuccess implements Action {
    type = ActionTypes.STOPPED_SCHOOLS_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class StoppedSchoolExportFail implements Action {
    type = ActionTypes.STOPPED_SCHOOLS_EXPORT_FAIL;
    constructor(public payload: any = null) {
    }
}
// App Usage Export
export class schoolAppUsageExportAction implements Action {
    type = ActionTypes.SCHOOLS_APP_USAGE_EXPORT;
    constructor(public payload: any) {
    }
}
export class schoolAppUsageExportSuccess implements Action {
    type = ActionTypes.SCHOOLS_APP_USAGE_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class schoolAppUsageExportFail implements Action {
    type = ActionTypes.SCHOOLS_APP_USAGE_EXPORT_FAIL;
    constructor(public payload: any = null) {
    }
}
// REDIRECT TO INSTITUTE URL
export class RedirectToInstituteAction implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE;
    constructor(public payload: any) {
    }
}
export class RedirectToInstituteSuccess implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class RedirectToInstituteFail implements Action {
    type = ActionTypes.REDIRECT_TO_INSTITUTE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}


export class VoiceCreditList implements Action {
    type = ActionTypes.VOICE_CREDITS_LIST;
    constructor(public payload: any) {
    }
}
export class VoiceCreditListSuccess implements Action {
    type = ActionTypes.VOICE_CREDITS_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCreditListFail implements Action {
    type = ActionTypes.VOICE_CREDITS_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VoiceCreditCountAction implements Action {
    type = ActionTypes.VOICE_CREDITS_COUNT;
    constructor(public payload: any) {
    }
}
export class VoiceCreditCountSuccess implements Action {
    type = ActionTypes.VOICE_CREDITS_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCreditCountFail implements Action {
    type = ActionTypes.VOICE_CREDITS_COUNT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VoiceCreditExportAction implements Action {
    type = ActionTypes.VOICE_CREDITS_Export;
    constructor(public payload: any) {
    }
}
export class VoiceCreditExportSuccess implements Action {
    type = ActionTypes.VOICE_CREDITS_Export_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCreditExportFail implements Action {
    type = ActionTypes.VOICE_CREDITS_Export_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolAppUsageList implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_LIST;
    constructor(public payload: any) {
    }
}
export class SchoolAppUsageListSuccess implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolAppUsageListFail implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class SchoolAppUsageListCount implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_COUNT;
    constructor(public payload: any) {
    }
}
export class SchoolAppUsageListCountSuccess implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class SchoolAppUsageListCountFail implements Action {
    type = ActionTypes.SCHOOL_APP_USAGE_COUNT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VoiceCircularList implements Action {
    type = ActionTypes.VOICE_CIRCULAR_LIST;
    constructor(public payload: any) {
    }
}
export class VoiceCircularListSuccess implements Action {
    type = ActionTypes.VOICE_CIRCULAR_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCircularListFail implements Action {
    type = ActionTypes.VOICE_CIRCULAR_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VoiceCircularCount implements Action {
    type = ActionTypes.VOICE_CIRCULAR_COUNT;
    constructor(public payload: any) {
    }
}
export class VoiceCircularCountSuccess implements Action {
    type = ActionTypes.VOICE_CIRCULAR_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCircularCountFail implements Action {
    type = ActionTypes.VOICE_CIRCULAR_COUNT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VoiceCircularReport implements Action {
    type = ActionTypes.VOICE_CIRCULAR_REPORT;
    constructor(public payload: any) {
    }
}
export class VoiceCircularReportSuccess implements Action {
    type = ActionTypes.VOICE_CIRCULAR_REPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VoiceCircularReportFail implements Action {
    type = ActionTypes.VOICE_CIRCULAR_REPORT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteVoiceUsageList implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_LIST;
    constructor(public payload: any) {
    }
}
export class InstituteVoiceUsageListSuccess implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class InstituteVoiceUsageListFail implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class InstituteVoiceUsageCount implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_COUNT;
    constructor(public payload: any) {
    }
}
export class InstituteVoiceUsageCountSuccess implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_COUNT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class InstituteVoiceUsageCountFail implements Action {
    type = ActionTypes.INSTITUTE_VOICE_USAGE_COUNT_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class ExportInstituteVoiceUsage implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE;
    constructor(public payload: any) {
    }
}
export class ExportInstituteVoiceUsageSuccess implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class ExportInstituteVoiceUsageFail implements Action {
    type = ActionTypes.EXPORT_INSTITUTE_VOICE_USAGE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

// get_school_details_list
export class getSchoolDetailList implements Action {
    type = ActionTypes.GET_SCHOOL_DETAILS_LIST;
    constructor(public payload: any) {
    }
}

export class getSchoolDetailListSuccess implements Action {
    type = ActionTypes.GET_SCHOOL_DETAILS_LIST_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}

export class getSchoolDetailListFail implements Action {
    type = ActionTypes.GET_SCHOOL_DETAILS_LIST_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

/// school_profile_export
export class schoolProfileExport implements Action {
    type = ActionTypes.SCHOOL_PROFILE_EXPORT;
    constructor(public payload: any) {
    }
}

export class schoolProfileExportSuccess implements Action {
    type = ActionTypes.SCHOOL_PROFILE_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}

export class schoolProfileExportFail implements Action {
    type = ActionTypes.SCHOOL_PROFILE_EXPORT_FAIL;
    constructor(public payload: any) {
    }
}

/// school profile is_Acknowledge_details

export class SchoolProfileIsAcknowledge implements Action {
    type = ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE;
    constructor(public payload: any) {
    }
}

export class SchoolProfileIsAcknowledgeSuccess implements Action {
    type = ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}

export class SchoolProfileIsAcknowledgeFail implements Action {
    type = ActionTypes.SCHOOL_PROFILE_ACKNOWLEDGE_FAIL;
    constructor(public payload: any) {
    }
}

//// redirect url to school profile
export class RedirectToSchoolProfileAction implements Action {
    type = ActionTypes.REDIRECT_TO_SCHOOL_PROFILE;
    constructor(public payload: any) {
    }
}

export class RedirectToSchoolProfileSuccess implements Action {
    type = ActionTypes.REDIRECT_TO_SCHOOL_PROFILE_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}

export class RedirectToSchoolProfileFail implements Action {
    type = ActionTypes.REDIRECT_TO_SCHOOL_PROFILE_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

///// school_profile_changes_popup

export class SchoolProfileChangesPopup implements Action {
    type = ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP;
    constructor(public payload: any) {
    }
}

export class SchoolProfileChangesPopupSuccess implements Action {
    type = ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}

export class SchoolProfileChangesPopupFail implements Action {
    type = ActionTypes.SCHOOL_PROFILE_CHANGES_POPUP_FAIL;
    constructor(public payload: ResponsInterface) {
    }
}

export class VerifyRenewalAction implements Action {
    type = ActionTypes.VERIFY_RENEWAL_SCHOOL;
    constructor(public payload: any) {
    }
}
export class VerifyRenewalSuccess implements Action {
    type = ActionTypes.VERIFY_RENEWAL_SCHOOL_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class VerifyRenewalFail implements Action {
    type = ActionTypes.VERIFY_RENEWAL_SCHOOL_FAIL;
    constructor(public payload: any = null) {
    }
}

export class subscribeInterestSchoolList implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST;
    constructor(public payload: any) {
    }
}
export class subscribeInterestSchoolListSuccess implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class subscribeInterestSchoolListFail implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST_FAIL;

    constructor(public payload: any = null) {
    }
}

export class subscribeInterestSchoolCount implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST_COUNT;
    constructor(public payload: any) {
    }
}
export class subscribeInterestSchoolCountSuccess implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponsInterface) {
    }
}
export class subscribeInterestSchoolCountFail implements Action {
    type = ActionTypes.INTEREST_SCHOOL_LIST_COUNT_FAIL;

    constructor(public payload: any = null) {
    }
}
export class clearInterestSchoolList implements Action {
    type = ActionTypes.CLEAR_LIST;

}

export class liveSchoolExportAction implements Action {
    type = ActionTypes.LIVE_SCHOOL_EXPORT;
    constructor(public payload: any) {
    }
}

export class liveSchoolExportSuccess implements Action {
    type = ActionTypes.LIVE_SCHOOL_EXPORT_SUCCESS;
    constructor(public payload: ResponsInterface) {
    }
}
export class liveSchoolExportFail implements Action {
    type = ActionTypes.LIVE_SCHOOL_EXPORT_FAIL;
    constructor(public payload: any = null) {
    }
}

export type Actions
    = ReportListAction
    | VoiceCircularList
    | VoiceCircularListSuccess
    | VoiceCircularListFail

    | VoiceCircularReport
    | VoiceCircularReportSuccess
    | VoiceCircularReportFail

    | VoiceCircularCount
    | VoiceCircularCountSuccess
    | VoiceCircularCountFail

    | VoiceCreditList
    | VoiceCreditListSuccess
    | VoiceCreditListFail
    | VoiceCreditExportAction
    | VoiceCreditExportSuccess
    | VoiceCreditExportFail
    | VoiceCreditCountAction
    | VoiceCreditCountSuccess
    | VoiceCreditCountFail
    | RedirectToInstituteAction
    | RedirectToInstituteSuccess
    | RedirectToInstituteFail
    | SmsCreditReportFail
    | StoppedSchoolExportAction
    | StoppedSchoolExportSuccess
    | StoppedSchoolExportFail
    | SmsCreditReportSuccess
    | DeletedSchoolExportAction
    | RenewalSchoolReportAction
    | RenewalSchoolReportSuccess
    | SmsCircularReportSuccess
    | DeletedSchoolExportSuccess
    | SchoolHistoryExportSuccess
    | SmsCircularReportFail
    | RenewalSchoolReportFail
    | DeletedSchoolExportFail
    | SchoolHistoryExportAction
    | ReportListSuccess
    | SmsCreditReportAction
    | ReportListFail
    | RenewalSchoolAction
    | RenewalSchoolSuccess
    | RenewalSchoolFail
    | RenewalSchoolCountAction
    | RenewalSchoolCountSuccess
    | RenewalSchoolCountFail
    | SmsCreditListAction
    | SmsCircularReportAction
    | SmsCreditListSuccess
    | SchoolHistoryExportFail
    | SmsCreditListFail
    | InvalidRecordAction
    | InvalidRecordSuccess
    | InvalidRecordFail
    | InvalidRecordCountAction
    | InvalidRecordCountSuccess
    | InvalidRecordCountFail
    | SmsCreditCountAction
    | SmsCreditCountSuccess
    | SmsCreditCountFail
    | SchoolHistoryAction
    | SchoolHistorySuccess
    | SchoolHistoryFail
    | SchoolHistoryCountAction
    | SchoolHistoryCountSuccess
    | SchoolHistoryCountFail
    | StoppedSchoolListAction
    | StoppedSchoolListSuccess
    | StoppedSchoolListFail
    | StoppedSchoolCountAction
    | StoppedSchoolCountSuccess
    | StoppedSchoolCountFail
    | StoppedSchoolEnableAction
    | StoppedSchoolEnableSuccess
    | StoppedSchoolEnableFail
    | CircularSmsListAction
    | CircularSmsListSuccess
    | CircularSmsListFail
    | CircularSmsCountAction
    | CircularSmsCountSuccess
    | CircularSmsCountFail
    | schoolAppUsageExportAction
    | schoolAppUsageExportSuccess
    | schoolAppUsageExportFail

    | InstituteVoiceUsageList
    | InstituteVoiceUsageListSuccess
    | InstituteVoiceUsageListFail
    | InstituteVoiceUsageCount
    | InstituteVoiceUsageCountSuccess
    | InstituteVoiceUsageCountFail
    | ExportInstituteVoiceUsage
    | ExportInstituteVoiceUsageSuccess
    | ExportInstituteVoiceUsageFail
    | getSchoolDetailList
    | getSchoolDetailListSuccess
    | getSchoolDetailListFail
    | schoolProfileExport
    | schoolProfileExportSuccess
    | schoolProfileExportFail
    | SchoolProfileIsAcknowledge
    | SchoolProfileIsAcknowledgeSuccess
    | SchoolProfileIsAcknowledgeFail
    | RedirectToSchoolProfileAction
    | RedirectToSchoolProfileSuccess
    | RedirectToSchoolProfileFail
    | SchoolProfileChangesPopupFail
    | SchoolProfileChangesPopupSuccess
    | SchoolProfileChangesPopup;
