import {Action} from '@ngrx/store';
import {type} from '../../../shared/utility/utilityHelpers';
import {LoginForm} from '../models/login.model';

export const ActionTypes = {
    // LOGIN ACTIONTYPES
    DO_LOGIN: type('[Auth] Do Login'),
    DO_LOGIN_SUCCESS: type('[Auth] Do Login Success'),
    DO_LOGIN_FAIL: type('[Auth] Do Login Fail'),
    // forgot password
    DO_FORGOT_PASSWORD: type('[Auth] Do Forgot Password'),
    DO_FORGOT_PASSWORD_SUCCESS: type('[Auth] Do Forgot Password Success'),
    DO_FORGOT_PASSWORD_FAIL: type('[Auth] Do Forgot Password Fail'),
    // forgot password url
    DO_FORGOT_PASSWORD_URL: type('[Auth] Do Forgot Password Url'),
    DO_FORGOT_PASSWORD_URL_SUCCESS: type('[Auth] Do Forgot Password Url Success'),
    DO_FORGOT_PASSWORD_URL_FAIL: type('[Auth] Do Forgot Password Url Fail'),
    // Reset password Url
    DO_RESET_PASSWORD: type('[Auth] Do ReSet Password '),
    DO_RESET_PASSWORD_SUCCESS: type('[Auth] Do ReSet Password  Success'),
    DO_RESET_PASSWORD_FAIL: type('[Auth] Do ReSet Password  Fail'),
}
// LOGIN ACTION
export class DoLoginAction implements Action {
    type = ActionTypes.DO_LOGIN;

    constructor(public payload: LoginForm) {
     }
}

export class DoLoginSuccessAction implements Action {
    type = ActionTypes.DO_LOGIN_SUCCESS;

   constructor(public payload: any) {
   }
}

export class DoLoginFailAction implements Action {
    type = ActionTypes.DO_LOGIN_FAIL;

    constructor(public payload: any = null) {
    }
}
// forgot Pasword
export class DoForgotPasswordAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD;

    constructor(public payload: any) {
    }
}

export class DoForgotPasswordSuccessAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DoForgotPasswordFailAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD_FAIL;

    constructor(public payload: any = null) {
    }
}
// forgot Password Url
export class DoForgotPasswordUrlAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD_URL;

    constructor(public payload: any) {
    }
}

export class DoForgotPasswordUrlSuccessAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD_URL_SUCCESS;

    constructor(public payload: any) {
        console.log(payload, 'success');
    }
}

export class DoForgotPasswordUrlFailAction implements Action {
    type = ActionTypes.DO_FORGOT_PASSWORD_URL_FAIL;

    constructor(public payload: any = null) {
    }
}
// Reset password Url
export class DoReSetPasswordAction implements Action {
    type = ActionTypes.DO_RESET_PASSWORD;

    constructor(public payload: any) {
    }
}

export class DoReSetPasswordSuccessAction implements Action {
    type = ActionTypes.DO_RESET_PASSWORD_SUCCESS;

    constructor(public payload: any) {
    }
}

export class DoReSetPasswordFailAction implements Action {
    type = ActionTypes.DO_RESET_PASSWORD_FAIL;

    constructor(public payload: any = null) {
    }
}
export type Actions
    = DoLoginAction
    | DoLoginSuccessAction
    | DoLoginFailAction
    | DoForgotPasswordAction
    | DoForgotPasswordSuccessAction
    | DoForgotPasswordFailAction
    | DoForgotPasswordUrlAction
    | DoForgotPasswordUrlSuccessAction
    | DoForgotPasswordUrlFailAction
    | DoReSetPasswordAction
    | DoReSetPasswordSuccessAction
    | DoReSetPasswordFailAction;
