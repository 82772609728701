import {Map, Record} from 'immutable';

export interface NotificationsState extends Map<string, any> {
    wishlist: any;
    feedbackList: any;
    wishlistCount: any;
    feedbackCount: any;

    wishlistLoading: boolean;
    wishlistLoaded: boolean;
    wishlistFailed: boolean;

    feedbackListLoading: boolean;
    feedbackListLoaded: boolean;
    feedbackListFailed: boolean;

    feedbackCountLoading: boolean;
    feedbackCountLoaded: boolean;
    feedbackCountFailed: boolean;

    wishlistCountLoading: boolean;
    wishlistCountLoaded: boolean;
    wishlistCountFailed: boolean;
    
    GetDeactivatedSchoolList: any;
    GetDeactivatedSchoolListCount: any;
    GetDeactivatedSchoolListLoading: boolean;
    GetDeactivatedSchoolListCountLoading: boolean;
    
    DeactivateSchool: any;
    DeactivateSchoolLoading: boolean;

}

export const notificationsRecord = Record({
    wishlist: [],
    feedbackList: [],
    wishlistCount: [],
    feedbackCount: [],

    wishlistLoading: false,
    wishlistLoaded: false,
    wishlistFailed: false,

    feedbackCountLoading: false,
    feedbackCountLoaded: false,
    feedbackCountFailed: false,

    feedbackListLoading: false,
    feedbackListLoaded: false,
    feedbackListFailed: false,

    wishlistCountLoading: false,
    wishlistCountLoaded: false,
    wishlistCountFailed: false,
    
    GetDeactivatedSchoolList: [],
    GetDeactivatedSchoolListCount: [],
    GetDeactivatedSchoolListLoading: false,
    GetDeactivatedSchoolListCountLoading: false,
    
    DeactivateSchool: {},
    DeactivateSchoolLoading: false,
    
});
