export class InstituteSalesManModel {

    public keyword: string;
    public limit: number;
    public count: number;
    public isSales: number;


    constructor(institutesalesManListModel: any) {
        this.keyword = institutesalesManListModel.keyword || '';
        this.limit = institutesalesManListModel.limit || '';
        this.count = institutesalesManListModel.count || '';
        this.isSales = institutesalesManListModel.isSales || '';
    }
}
