import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { DeletedStudentListComponent } from './components/popup/deleted-student-list/deleted-student-list.component';
import { RevertStudentComponent } from './components/popup/revert-student/revert-student.component';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { EnableStoppedSchoolDetailComponent } from './components/popup/enable-stopped-school-detail/enable-stopped-school-detail.component';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        NgxAudioPlayerModule,
        NgxPrintModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        SwiperModule,
        SpinnerComponent,
        NgxAudioPlayerModule,
        NgxPrintModule,
    ],
    entryComponents: [DeletedStudentListComponent, RevertStudentComponent],
    declarations: [SpinnerComponent, DeletedStudentListComponent, RevertStudentComponent, EnableStoppedSchoolDetailComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
