export class InstituteApprovalModel {

    public instituteId: string;
    public pocorlive: string;
    public prepaidorpostpaid: string;
    public validFrom: string;
    public validTo: string;
    public verifyOrApprove: number;
    public settings: any = [];
    public remarks: any;
    public goLiveDate: string;

    constructor(instituteApprovalModel: any) {
        this.instituteId = instituteApprovalModel.instituteId || '';
        this.pocorlive = instituteApprovalModel.pocorlive || '';
        this.prepaidorpostpaid = instituteApprovalModel.prepaidorpostpaid || '';
        this.validFrom = instituteApprovalModel.validFrom || '';
        this.verifyOrApprove = instituteApprovalModel.verifyOrApprove || '';
        this.validTo = instituteApprovalModel.validTo || '';
        this.remarks = instituteApprovalModel.remarks || '';
        this.settings = instituteApprovalModel.settings || [];
        this.goLiveDate = instituteApprovalModel.goLiveDate || '';
    }
}
