export class SchoolHistoryModel {
    public instituteId: any;
    public schoolName: any;
    public address: any;
    public email: any;
    public contactPerson: any;
    public createdBy: any;
    public pincode: any;
    public instituteCity: any;
    public contactNumber: any;
    public isUnlimitedCalls: any;
    public IsUnlimitedSMS: any;
    public purchaseType: any;
    public period: any;
    public callCount: any;
    public smsCount: any;
    public createdOn: any;
    public modules: any;
    constructor(schoolList: any) {
        this.instituteId = schoolList.instituteId || '';
        this.modules = schoolList.modules || '';
        this.schoolName = schoolList.schoolName || '';
        this.address = schoolList.address || '';
        this.email = schoolList.email || '';
        this.contactPerson = schoolList.contactPerson || '';
        this.createdBy = schoolList.createdBy || '';
        this.pincode = schoolList.pincode || '';
        this.instituteCity = schoolList.instituteCity || '';
        this.contactNumber = schoolList.contactNumber || '';
        this.isUnlimitedCalls = schoolList.isUnlimitedCalls || '';
        this.IsUnlimitedSMS = schoolList.IsUnlimitedSMS || '';
        this.purchaseType = schoolList.purchaseType || '';
        this.callCount = schoolList.callCount || '';
        this.smsCount = schoolList.smsCount || '';
        this.createdOn = schoolList.createdOn || '';
        this.period = schoolList.period !== 'null - null' ? schoolList.period.split(' - ') || [] : [];
    }
}
