import {Map, Record} from 'immutable';

export interface UserManagementState extends Map<string, any> {
  userRole: any;
  userList: any;
  userDetails: any;
  UserUpdateDetails: any;
  deleteUser: any;

  loading: boolean;
  loaded: boolean;
  failed: boolean;

  userDetailsLoading: boolean;
  userDetailsLoaded: boolean;
  userDetailsFailed: boolean;

  UserUpdateDetailsLoading: boolean;
  UserUpdateDetailsLoaded: boolean;
  UserUpdateDetailsFailed: boolean;

  userListLoading: boolean;
  userListLoaded: boolean;
  userListFailed: boolean;

  deleteUserLoading: boolean;
  deleteUserLoaded: boolean;
  deleteUserFailed: boolean;

  createUserLoading: boolean;
  createUserLoaded: boolean;
  createUserFailed: boolean;

  userRoleLoading: boolean;
  userRoleLoaded: boolean;
  userRoleFailed: boolean;
}

export const userManagementRecord = Record({
  userRole: [],
  userList: [],
  userDetails: [],
  UserUpdateDetails: [],
  deleteUser: [],

  loading: false,
  loaded: false,
  failed: false,

  userDetailsLoading: false,
  userDetailsLoaded: false,
  userDetailsFailed: false,

 UserUpdateDetailsLoading: false,
 UserUpdateDetailsLoaded: false,
 UserUpdateDetailsFailed: false,

 userListLoading: false,
 userListLoaded: false,
 userListFailed: false,

 deleteUserLoading: false,
 deleteUserLoaded: false,
 deleteUserFailed: false,

 createUserLoading: false,
 createUserLoaded: false,
 createUserFailed: false,

 userRoleLoading: false,
 userRoleLoaded: false,
 userRoleFailed: false,
});
