import { createSelector } from 'reselect';
import * as  fromUserManagement from './user-management.reducer';
import { AppState } from '../../../state.interface';



export const getState = (State: AppState) => State.userMangement;
export const userRole = createSelector(getState, fromUserManagement.userRoleSuccess);
export const userList = createSelector(getState, fromUserManagement.userListSuccess);
export const userDetails = createSelector(getState, fromUserManagement.userDetails);
export const userUpdateDetails = createSelector(getState, fromUserManagement.userUpdateDetails);
export const deleteUser = createSelector(getState, fromUserManagement.deleteUserSuccess);

export const createUserLoading = createSelector(getState, fromUserManagement.createUserLoading);
export const createUserLoaded = createSelector(getState, fromUserManagement.createUserLoaded);
export const createUserFailed = createSelector(getState, fromUserManagement.createUserFailed);

export const userListLoading = createSelector(getState, fromUserManagement.userListLoading);
export const userListLoaded = createSelector(getState, fromUserManagement.userListLoaded);
export const userListFailed = createSelector(getState, fromUserManagement.userListFailed);

export const deleteUserLoading = createSelector(getState, fromUserManagement.deleteUserLoading);
export const deleteUserLoaded = createSelector(getState, fromUserManagement.deleteUserLoaded);
export const deleteUserFailed = createSelector(getState, fromUserManagement.deleteUserFailed);


export const userRoleLoading = createSelector(getState, fromUserManagement.userRoleLoading);
export const userRoleLoaded = createSelector(getState, fromUserManagement.userRoleLoaded);
export const userRoleFailed = createSelector(getState, fromUserManagement.userRoleFailed);

export const UserUpdateDetailsLoading = createSelector(getState, fromUserManagement.UserUpdateDetailsLoading);

