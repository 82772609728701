import { Action } from '@ngrx/store';
import { type } from '../../shared/utility/utilityHelpers';
import { ResponsInterface } from '../../response-model/response-interface';

export const ActionTypes = {
  NOTIFICATION_COUNT: type('[common] NOTIFICATION_COUNT'),
  NOTIFICATION_COUNT_SUCCESS: type('[common] NOTIFICATION_COUNT Success'),
  NOTIFICATION_COUNT_FAIL: type('[common] NOTIFICATION_COUNT Fail'),

  NOTIFICATION_LIST: type('[common] NOTIFICATION_LIST'),
  NOTIFICATION_LIST_SUCCESS: type('[common] NOTIFICATION_LIST Success'),
  NOTIFICATION_LIST_FAIL: type('[common] NOTIFICATION_LIST Fail'),
};

/**
 *  get school detail actions.
 */

export class NotificationCount implements Action {
  type = ActionTypes.NOTIFICATION_COUNT;
  constructor(public payload: any) {}
}
export class NotificationCountSuccess implements Action {
  type = ActionTypes.NOTIFICATION_COUNT_SUCCESS;

  constructor(public payload: ResponsInterface) {}
}
export class NotificationCountFail implements Action {
  type = ActionTypes.NOTIFICATION_COUNT_FAIL;
  constructor(public payload: any) {}
}

export class NotificationList implements Action {
  type = ActionTypes.NOTIFICATION_LIST;
  constructor(public payload: any) {}
}
export class NotificationListSuccess implements Action {
  type = ActionTypes.NOTIFICATION_LIST_SUCCESS;

  constructor(public payload: ResponsInterface) {}
}
export class NotificationListFail implements Action {
  type = ActionTypes.NOTIFICATION_LIST_FAIL;
  constructor(public payload: any) {}
}
export type Actions =
  | NotificationCount
    | NotificationCountSuccess
    | NotificationCountFail;
