import {Injectable} from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
        return this.checkLogin(state.url);
    }
    // CheckLogin
    checkLogin(url: string): Promise<boolean> | boolean {
        let currentUser: any;
            currentUser = JSON.parse(localStorage.getItem('userdetails'));
        if (currentUser) {
            if (url === '/auth/login' || url === '/auth/forgot-password') {
                // Navigate to the login page with extras
                this.router.navigate(['/institute-mangements']);
                return false;
            }
            return true;
        } else {
            if (url === '/auth/login' || url === '/auth/forgot-password') {
                return true;
            }
        }
        // Navigate to the login page with extras
        this.router.navigate(['/auth/login']);
        return false;
    }

}
