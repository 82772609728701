import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { InstituteManagementSandbox } from '../../../../pages/institute-mangements/institute-management.sandbox';
import * as $ from 'jquery';


@Component({
  selector: 'app-renew',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.scss']
})
export class RenewComponent implements OnInit, OnDestroy {
  public erpLists = [
    { label: 'Basic Settings', id: 'basic_settings', isChecked: false },
    { label: 'Certificates', id: 'certificates', isChecked: false },
    { label: 'Dashboard', id: 'dashboard', isChecked: false },
    { label: 'Exam Management', id: 'exam_management', isChecked: false },
    { label: 'Extra-Curricular Manage', id: 'extra_curricular_manage', isChecked: false },
    { label: 'Fee Management', id: 'fee_management', isChecked: false },
    { label: 'Fees Reports', id: 'fees_reports', isChecked: false },
    { label: 'Finance', id: 'finance', isChecked: false },
    { label: 'Hostel Management System', id: 'hostel_management_system', isChecked: false },
    { label: 'ID Card Generator', id: 'id_card_generator', isChecked: false },
    { label: 'Inventory', id: 'inventory', isChecked: false },
    { label: 'Library Management', id: 'library_management', isChecked: false },
    { label: 'Library Management System', id: 'library_management_system', isChecked: false },
    { label: 'Main Admissions', id: 'main_admissions', isChecked: false },
    { label: 'Other Staff', id: 'other_staff', isChecked: false },
    { label: 'Parents', id: 'parents', isChecked: false },
    { label: 'Payroll Management System', id: 'payroll_management_system', isChecked: false },
    { label: 'Reports / Export Datas', id: 'reports_export_datas', isChecked: false },
    { label: 'Sports Management', id: 'sports_management', isChecked: false },
    { label: 'Staff Management', id: 'staff_management', isChecked: false },
    { label: 'Standard and Section Management', id: 'standard_and_section_management', isChecked: false },
    { label: 'Students', id: 'students', isChecked: false },
    { label: 'Students Promotion', id: 'students_promotion', isChecked: false },
    { label: 'Today Birthday', id: 'today_birthday', isChecked: false },
    { label: 'User Permission', id: 'user_permission', isChecked: false },
    { label: 'Vehicle Manage Report', id: 'vehicle_manage_report', isChecked: false },
    { label: 'Vehicle management', id: 'vehicle_management', isChecked: false },
  ];
  // form Variable
  public schoolRenewalForm: FormGroup;
  // approval model variable
  public erpValue = 'enable_additional_modules';
  public voiceValue = 'enable_voice';
  public smsValue = 'enable_sms';
  public unLimitedsmsValue = 'unlimited_sms';
  public perSmsValue = 'per_sms_cost';
  public costValue = 'cost';
  public noOfCallsValue = 'unlimited_calls';
  public minuteValues = 'number_of_minutes';
  public minuteCostValue = 'minutes_cost';
  public settingCheckList: any = [];
  public settingUncheckList: any = [];
  public settingsArray: any = [];
  public enableVoiceValue = false;
  public enableSmsValue = false;
  public unlimitedMinuteValue: any;
  public unlimitedCallsValue: any;
  public perCallCostValue: any;
  public unlimitedSmsesValue: any;
  public perSmsesCostValue: any;
  public prepaidPostpaidValue = 1;
  public enableErpValue = false;
  public renewId: any;
  public renewtype: any;
  public endDate: any;
  public goLiveDate: any;
  public perMinuteCostValue: any;
  numberOfsmsValue = 'number_of_sms';
  numberSmsValue = 'number_sms_cost';
  isUnlimitedCallValue = false;
  isUnlimitedSmsValues = false;
  numberOfSmscostValue: string;
  numberOfSMSValue: string;
  enableUnlimitSms = false;
  enableunlimitedNoOfCalls = false;
  unlimitedSmsName: any;
  unlimitedsmsValue = 'unlimited_sms';
  perminuteCostValue: any;
  isEnabledVoiceSms = false;

  private subscriptions: Array<Subscription> = [];
  constructor(private modalService: NgbModal,
    public instituteSandbox: InstituteManagementSandbox,
    public fb: FormBuilder,
    public modals: NgbModal,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.UploadRenewalForm();
    if (this.renewId) {
      setTimeout(() => {
        if (this.renewtype == 'schedule') {
          this.instituteSandbox.getScheduledInstituteDetails(this.renewId);
        } else {
          this.instituteSandbox.instituteDetail(this.renewId);
        }

      }, 1000);
      this.editApprovalForm();
    }
  }
  // intial approvalForm
  public UploadRenewalForm() {
    this.schoolRenewalForm = this.fb.group({
      pocLive: [],
      prepaidPostPaid: [],
      validFrom: [],
      validTo: ['', Validators.required],
      remarks: [],
      noOfMinutes: [''],
      noOfSMs: [''],

      unlimitedcall: [''],
      unlimitedSms: [''],
      goLiveDate: [''],
    });
    this.subscriptions.push(this.schoolRenewalForm.controls['validFrom'].valueChanges.subscribe(fromDate => {
      this.endDate = fromDate;
    }));
  }
  selectFromDate(event) {
    this.schoolRenewalForm.controls['goLiveDate'].setValue('');
    this.schoolRenewalForm.controls['validTo'].setValue('');
    this.goLiveDate = event.value;
    this.endDate = event.value;
  }
  selectGoLiveDate(event) {
    this.schoolRenewalForm.controls['validTo'].setValue('');
    this.endDate = event.value;
  }
  // submit the approved form
  public onSubmitRenew() {
    this.isEnabledVoiceSms = false;
    if (this.prepaidPostpaidValue === 2) {
      this.schoolRenewalForm.controls['noOfMinutes'].setValidators([]);
      this.schoolRenewalForm.get('noOfMinutes').updateValueAndValidity();
      this.schoolRenewalForm.controls['noOfSMs'].setValidators([]);
      this.schoolRenewalForm.get('noOfSMs').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableVoiceValue === true) {
      this.schoolRenewalForm.controls['noOfMinutes'].setValidators([Validators.required]);
      this.schoolRenewalForm.controls['noOfMinutes'].enable();
      this.schoolRenewalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolRenewalForm.controls['unlimitedSms'].setValidators([]);
      this.schoolRenewalForm.get('noOfMinutes').updateValueAndValidity();
      this.schoolRenewalForm.get('unlimitedcall').updateValueAndValidity();
      this.schoolRenewalForm.get('unlimitedSms').updateValueAndValidity();
      // this.schoolRenewalForm.updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableVoiceValue === false) {
      this.schoolRenewalForm.controls['noOfMinutes'].setValidators([]);
      this.schoolRenewalForm.controls['noOfMinutes'].disable();
      this.schoolRenewalForm.get('noOfMinutes').updateValueAndValidity();
      // this.schoolRenewalForm.removeControl('noOfMinutes');
    }
    if (this.prepaidPostpaidValue === 1 && this.enableSmsValue === true) {
      this.schoolRenewalForm.controls['noOfSMs'].setValidators([Validators.required]);
      this.schoolRenewalForm.controls['noOfSMs'].enable();
      this.schoolRenewalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolRenewalForm.controls['unlimitedSms'].setValidators([]);
      // this.schoolRenewalForm.updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 1 && this.enableSmsValue === false) {
      this.schoolRenewalForm.controls['noOfSMs'].setValidators([]);
      this.schoolRenewalForm.controls['noOfSMs'].disable();
      this.schoolRenewalForm.get('noOfSMs').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableVoiceValue === true && this.isUnlimitedCallValue === false) {
      this.schoolRenewalForm.controls['unlimitedcall'].setValidators([Validators.required]);
      this.schoolRenewalForm.controls['unlimitedcall'].enable();
      this.schoolRenewalForm.get('unlimitedcall').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableVoiceValue === true && this.isUnlimitedCallValue === true) {
      this.schoolRenewalForm.controls['unlimitedcall'].setValidators([]);
      this.schoolRenewalForm.controls['unlimitedcall'].disable();
      this.schoolRenewalForm.get('unlimitedcall').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableSmsValue === true && this.isUnlimitedSmsValues === false) {
      this.schoolRenewalForm.controls['unlimitedSms'].setValidators([Validators.required]);
      this.schoolRenewalForm.controls['unlimitedSms'].enable();
      this.schoolRenewalForm.get('unlimitedSms').updateValueAndValidity();
    }
    if (this.prepaidPostpaidValue === 2 && this.enableSmsValue === true && this.isUnlimitedSmsValues === true) {
      this.schoolRenewalForm.controls['unlimitedSms'].setValidators([]);
      this.schoolRenewalForm.controls['unlimitedSms'].disable();
      this.schoolRenewalForm.get('unlimitedSms').updateValueAndValidity();
    }
    if (!this.schoolRenewalForm.valid) {
      this.validateAllFormFields(this.schoolRenewalForm);
      let target;
      let target1;
      let target2;
      target = $('input.ng-invalid').first();
      target1 = $('ngx-select-dropdown.ng-invalid').first();
      target2 = $('select.ng-invalid').first();
      if (target['length'] === 1) {
        target = $('input.ng-invalid').first();
        $('ngb-modal-window').animate(
          { scrollTop: $(target).offset().top },
          'slow',
          () => {
            target.focus();
          }
        );
      } else if (target1['length'] === 1) {
        target = $('ngx-select-dropdown.ng-invalid').first();
        if (target) {
          $('ngb-modal-window').animate(
            { scrollTop: $(target).offset().top },
            'slow',
            () => {
              target.focus();
            }
          );
        }
      } else if (target2['length'] === 1) {
        target = $('select.ng-invalid').first();
        if (target) {
          $('ngb-modal-window').animate(
            { scrollTop: $(target).offset().top },
            'slow',
            () => {
              target.focus();
            }
          );
        }
      }
      if (+this.schoolRenewalForm.value.prepaidPostPaid === 1) {
        if (this.enableVoiceValue === true || this.enableSmsValue === true) {
          this.isEnabledVoiceSms = false;
        } else {
          this.isEnabledVoiceSms = true;
          let target3;
          target3 = $('.enableCondition');
          $('ngb-modal-window').animate(
            { scrollTop: $(target3).offset().top - 310 },
            'slow',
            () => {
              target3.focus();
            }
          );
        }
      }

      return;
    }
    if (+this.schoolRenewalForm.value.prepaidPostPaid === 1) {
      if (this.enableVoiceValue === true || this.enableSmsValue === true) {
        this.isEnabledVoiceSms = false;
      } else {
        this.isEnabledVoiceSms = true;
        let target;
        target = $('.enableCondition');
        $('ngb-modal-window').animate(
          { scrollTop: $(target).offset().top - 310 },
          'slow',
          () => {
            target.focus();
          }
        );
        return;
      }
    }
    this.selectedPocLive();
    const params: any = {};
    params.instituteId = this.renewId;
    params.renewtype = this.renewtype;
    params.pocorlive = +this.schoolRenewalForm.value.pocLive;
    params.prepaidorpostpaid = this.schoolRenewalForm.value.prepaidPostPaid;
    params.validFrom = this.schoolRenewalForm.value.validFrom;
    params.validTo = this.schoolRenewalForm.value.validTo;
    params.settings = this.settingCheckList;
    params.remarks = this.schoolRenewalForm.value.remarks;
    params.goLiveDate = this.schoolRenewalForm.value.goLiveDate;
    console.log('renewParams', params);
    this.instituteSandbox.renewalInstitute(params);
    this.closePopup();
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  closePopup() {
    this.subscriptions.push(this.instituteSandbox.instituteRenewal$.subscribe(data => {
      if (data && data.status === 1) {
        // const param: any = {};
        // param.limit = '';
        // param.offset = '';
        // param.keyword = '';
        // this.instituteSandbox.instituteListCount(param);
        this.activeModal.close();
      }
    }));
  }
  selectedPocLive() {
    if (+this.prepaidPostpaidValue === 1) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.unlimitedsmsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.perSmsValue) {
          // this.settingCheckList[i].settingValue = '';
        }
      });
    } else {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = '';
        }
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = '';
        }
      });
    }
  }
  // setting schoolRenewalForm value
  public editApprovalForm() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.subscriptions.push(this.instituteSandbox.instituteDetail$.subscribe((details) => {
      if (details) {
        console.log('renewdetails', details);
        this.settingCheckList = [];
        details.settingsData.forEach(data => {
          this.settingCheckList.push({ settingName: data.settings_name, settingValue: data.settings_value });
        });
        this.schoolRenewalForm.controls['pocLive'].setValue(details.instituteData.institutePocLive);
        this.schoolRenewalForm.controls['prepaidPostPaid'].setValue(details.instituteData.institutePrepaidOrPostpaid);
        this.schoolRenewalForm.controls['validFrom'].setValue(details.instituteData.subscriptionFrom);
        this.schoolRenewalForm.controls['validTo'].setValue(details.instituteData.subscriptionTo);
        this.schoolRenewalForm.controls['remarks'].setValue(details.instituteData.remarks);
        this.endDate = new Date(details.instituteData.subscriptionFrom);
        details.settingsData.forEach(settingsData => {
          this.settingsArray = settingsData;
          if (this.settingsArray.settings_name === this.perSmsValue) {
            // this.enableperSmsCost = true;
            this.perSmsesCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue) {
            // this.enableUnlimitSms = true;
            this.unlimitedSmsesValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.smsValue) {
            this.enableSmsValue = true;
          }
          if (this.settingsArray.settings_name === this.voiceValue) {
            this.enableVoiceValue = true;
          }
          if (this.settingsArray.settings_name === this.erpValue) {
            this.enableErpValue = true;
          }
          if (this.settingsArray.settings_name === this.minuteValues) {
            this.unlimitedMinuteValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue) {
            this.unlimitedCallsValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.costValue) {
            //   this.enablecostValue = true;
            this.perCallCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.minuteCostValue) {
            // this.enablecostValue = true;
            this.perminuteCostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === 'UL') {
            this.enableunlimitedNoOfCalls = true;
            this.isUnlimitedCallValue = true;
            this.schoolRenewalForm.controls['unlimitedcall'].disable();
            this.schoolRenewalForm.updateValueAndValidity();
          }
          if (this.settingsArray.settings_name === this.noOfCallsValue && this.settingsArray.settings_value === '') {
            this.enableunlimitedNoOfCalls = false;
            this.unlimitedCallsValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === 'UL') {
            this.enableUnlimitSms = true;
            this.schoolRenewalForm.controls['unlimitedSms'].disable();
            this.schoolRenewalForm.updateValueAndValidity();
          }
          if (this.settingsArray.settings_name === this.unlimitedsmsValue && this.settingsArray.settings_value === '') {
            this.enableUnlimitSms = false;
            this.enableUnlimitSms = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.numberSmsValue) {
            this.numberOfSmscostValue = this.settingsArray.settings_value;
          }
          if (this.settingsArray.settings_name === this.numberOfsmsValue) {
            this.numberOfSMSValue = this.settingsArray.settings_value;
          }
        });

        this.goLiveDate = details.instituteData.subscriptionFrom ? details.instituteData.subscriptionFrom : '';

        this.schoolRenewalForm.controls['goLiveDate'].setValue(details.instituteData.go_live_date);

      }
    }));
  }


  // while close approved modal
  approvedClose() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
    this.settingCheckList = [];
    this.perSmsesCostValue = '';
    this.unlimitedSmsesValue = '';
    this.perCallCostValue = '';
    this.unlimitedCallsValue = '';
    this.unlimitedMinuteValue = '';
  }

  // change postpaidprepaid
  public postpaidprepaid(event) {
    if (+event.value === 2) {
      this.isEnabledVoiceSms = false;
      this.schoolRenewalForm.updateValueAndValidity();
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === 'unlimited_sms') {
          if (this.settingCheckList[i].settingValue === 'UL') {
            this.schoolRenewalForm.controls['unlimitedSms'].disable();
            this.isUnlimitedSmsValues = true;
          } else {
            this.schoolRenewalForm.controls['unlimitedSms'].enable();
          }
        }
        if (data.settingName === 'unlimited_calls') {
          if (this.settingCheckList[i].settingValue === 'UL') {
            this.schoolRenewalForm.controls['unlimitedcall'].disable();
            this.isUnlimitedCallValue = true;
          } else {
            this.schoolRenewalForm.controls['unlimitedcall'].enable();
          }
        }
      });
    }
    if (+event.value === 1) {
      this.schoolRenewalForm.updateValueAndValidity();
    }
    // const prepaidPostpaid = +event.value;
    // if (prepaidPostpaid === 1) {
    //   this.settingCheckList.forEach((data, i) => {
    //     if (data.settingName === this.noOfCallsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.unlimitedsmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.costValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.perSmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //   });
    //   this.unlimitedMinuteValue = '';
    //   this.perCallCostValue = '';
    //   this.perSmsesCostValue = '';
    // } else {
    //   this.settingCheckList.forEach((data, i) => {
    //     if (data.settingName === this.minuteValues) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.numberOfsmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.minuteCostValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //     if (data.settingName === this.numberSmsValue) {
    //       this.settingCheckList[i].settingValue = '';
    //     }
    //   });
    //   this.unlimitedCallsValue = '';
    //   this.perminuteCostValue = '';
    //   this.numberOfSmscostValue = '';
    //   this.numberOfSMSValue = '';
    //   this.enableUnlimitSms = false;
    //   this.enableunlimitedNoOfCalls = false;
    // }
  }

  // change enable voice
  public changeEnableVoice(event, value) {
    if (event.target.checked === true) {
      this.schoolRenewalForm.controls['noOfMinutes'].enable();
      this.isEnabledVoiceSms = false;
      this.enableVoiceValue = true;
      this.settingCheckList.push(
        { settingName: value, settingValue: '' },
        { settingName: this.minuteValues, settingValue: '' },
        { settingName: this.noOfCallsValue, settingValue: '' },
        { settingName: this.minuteCostValue, settingValue: '' },
        { settingName: this.costValue, settingValue: '' });
    }
    if (event.target.checked === false) {
      this.enableVoiceValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableVoice => {
        return (enableVoice.settingName !== value && enableVoice.settingName !== this.minuteValues && enableVoice.settingName !== this.costValue && enableVoice.settingName !== this.noOfCallsValue && enableVoice.settingName !== this.minuteCostValue);
      });
      // clear text field
      this.unlimitedCallsValue = '';
      this.perCallCostValue = '';
      this.unlimitedMinuteValue = '';
      this.perminuteCostValue = '';
      this.enableunlimitedNoOfCalls = false;

      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unlimited call value
  public unlimitedCallValue(event, value) {
    if (value === this.minuteValues) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteValues) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteValues;
        }
      });
    }
    if (value === this.noOfCallsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.noOfCallsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.noOfCallsValue;
        }
      });
    }
  }

  // change unlimitedCalls
  public changeUnlimitedCall(event, value) {
    // this.unlimitedCallName = value;
    if (event.target.checked === true) {
      this.schoolRenewalForm.controls['unlimitedcall'].disable();
      this.isUnlimitedCallValue = true;
      this.enableunlimitedNoOfCalls = true;
      this.unlimitedCallsValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });
      // this.settingCheckList.push({settingName: value, settingValue: 'UL'});
    } else {
      this.schoolRenewalForm.controls['unlimitedcall'].enable();
      this.isUnlimitedCallValue = false;
      this.enableunlimitedNoOfCalls = false;
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      // this.settingCheckList = this.settingCheckList.filter((unLimted) => {
      //   return unLimted.settingValue !== 'UL';
      // });
      // this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited Call
      this.unlimitedCallsValue = '';
    }
  }

  // getting per call cost Value
  public perCallsCostValue(event, value) {
    if (value === this.costValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.costValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.costValue;
        }
      });
    }
    if (value === this.minuteCostValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.minuteCostValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.minuteCostValue;
        }
      });
    }
  }

  // enable Sms change
  public changeEnableSms(event, value) {
    if (event.target.checked === true) {
      this.enableSmsValue = true;
      this.isEnabledVoiceSms = false;
      this.schoolRenewalForm.controls['noOfSMs'].enable();
      this.settingCheckList.push(
        { settingName: value, settingValue: '' },
        { settingName: this.unlimitedsmsValue, settingValue: '' },
        { settingName: this.numberOfsmsValue, settingValue: '' },
        { settingName: this.numberSmsValue, settingValue: '' },
        { settingName: this.perSmsValue, settingValue: '' });
    } else {
      this.enableSmsValue = false;
      // remove enable voice
      this.settingCheckList = this.settingCheckList.filter(enableSms => {
        return (enableSms.settingName !== value && enableSms.settingName !== this.unlimitedsmsValue && enableSms.settingName !== this.perSmsValue && enableSms.settingName !== this.numberOfsmsValue && enableSms.settingName !== this.numberSmsValue);
      });
      // clear text field
      this.unlimitedSmsesValue = '';
      this.perSmsesCostValue = '';
      this.enableUnlimitSms = false;
      this.numberOfSMSValue = '';
      this.numberOfSmscostValue = '';
      console.log('settingName', this.settingCheckList);
    }
  }

  // getting unLimitedSms value
  public unLimitedSmsValue(event, value) {
    if (value === this.numberOfsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberOfsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberOfsmsValue;
        }
      });
    }
    if (value === this.unlimitedsmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.unlimitedsmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.unlimitedsmsValue;
        }
      });
    }
  }
  // change unlimited Sms checkbox
  public changeUnlimitedSms(event, value) {
    this.unlimitedSmsName = value;
    if (event.target.checked === true) {
      this.schoolRenewalForm.controls['unlimitedSms'].disable();
      this.isUnlimitedSmsValues = true;
      this.unlimitedSmsesValue = '';
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = 'UL';
        }
      });
    } else {
      this.schoolRenewalForm.controls['unlimitedSms'].enable();
      this.isUnlimitedSmsValues = false;
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === value) {
          this.settingCheckList[i].settingValue = '';
        }
      });
      // this.settingCheckList = this.settingCheckList.filter((unlimitedSms) => {
      //   return unlimitedSms.settingName !== 'UL';
      // });
      // this.settingCheckList.push({settingName: value, settingValue: ''});
      // clear the inputField for unlimited sms
      this.unlimitedSmsesValue = '';
    }
  }
  // getting perSmsCostValue
  public perSmsCostValue(event, value) {
    if (value === this.numberSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.numberSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.numberSmsValue;
        }
      });
    }
    if (value === this.perSmsValue) {
      this.settingCheckList.forEach((data, i) => {
        if (data.settingName === this.perSmsValue) {
          this.settingCheckList[i].settingValue = event;
          this.settingCheckList[i].settingName = this.perSmsValue;
        }
      });
    }
  }

  // enable erp base on show checkboxList
  public changEnableErp(event, value, additionalModule) {
    // console.log('additionalModule' , additionalModule);
    const isCheckedEvent = event.target.checked;
    if (event.target.checked === true) {
      this.instituteSandbox.isCheckedAdditionalModule(isCheckedEvent);
      this.enableErpValue = true;
      this.settingCheckList.push({ settingName: value, settingValue: '' });
      // pushing the checked Data
      additionalModule.forEach(data => {
        this.settingCheckList.push({ settingName: data.settingsName, settingValue: '' });
      });
    } else {
      this.instituteSandbox.isCheckedAdditionalModule(isCheckedEvent);
      this.enableErpValue = false;
      // checking parent checkBox
      this.settingCheckList = this.settingCheckList.filter(enableErp => {
        return enableErp.settingName !== value;
      });
      // removing the unchecked Data
      additionalModule.forEach(data => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (checkedValue.settingName === data.settingsName) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    console.log('settingCheckList', this.settingCheckList);
  }

  // storing the value of erpManagementCheckbox
  public erpManegementCheckbox(event, value) {
    debugger;
    if (event.target.checked === true) {
      // value.isChecked = true;
      this.settingCheckList.push({ settingName: value.settingsName, settingValue: '' });
    }
    if (event.target.checked === false) {
      // value.isChecked = false;
      this.settingUncheckList.push({ settingName: value.settingsName, settingValue: '' });
      this.settingUncheckList.forEach(uncheckedValue => {
        this.settingCheckList = this.settingCheckList.filter(checkedValue => {
          if (uncheckedValue.settingName === checkedValue.settingName) {
            return false;
          } else {
            return true;
          }
        });
      });
    }
    this.settingUncheckList = [];
    console.log(this.settingCheckList, 'ch');
  }


  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
