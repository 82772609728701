import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserManagementSandbox } from '../../../../pages/user-managements/user-management.sandbox';
import { InstituteManagementSandbox } from '../../../../pages/institute-mangements/institute-management.sandbox';


@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit, OnDestroy {
  limit: any;
  keyword: any;
  count: any;

  userId: any;
  instituteId: any;

  public head = false;
  public body = false;
  public subscriptions: Array<Subscription> = [];

  constructor(private activeModal: NgbActiveModal,
              public userManagementSandbox: UserManagementSandbox,
              public instituteManagementSandbox: InstituteManagementSandbox) {
    this.subscribeVal();
  }

  ngOnInit() {
    if (this.userId) {
      this.head = true;
      this.body = true;
    }
  }

  public accept() {
    if (this.userId) {
      const param: any = {};
      param.id = this.userId;
      this.userManagementSandbox.deleteUser(param);
    }

    if (this.instituteId) {
      const param: any = {};
      param.id = this.instituteId;
      this.instituteManagementSandbox.deleteInstitute(param);
    }
  }

  public dismiss() {
    this.activeModal.close();
  }
  subscribeVal() {
    this.subscriptions.push(this.userManagementSandbox.deleteUser$.subscribe(data => {
      if (data && data.status === 1) {
        this.activeModal.close('success');
      }
    }));

    this.subscriptions.push(this.instituteManagementSandbox.deleteInstitute$.subscribe(data => {
      if (data && data.status === 1) {
        this.activeModal.close('success');
      }
    }));
  }
  public cancel(result) {
    this.activeModal.close(result);
    this.subscriptions.forEach(sub => sub.unsubscribe());

  }
  ngOnDestroy() {
    const params: any = {};
    this.userManagementSandbox.unsubscribe(params);
    this.instituteManagementSandbox.emptyDeleteInstituteVar(params);
  }
}




