import * as actions from '../actions/user-management.action';
import { UserListResponseModel } from '../models/user-list-response.model';
import { UserManagementState, userManagementRecord } from '../reducers/user-management.state';



export const initialState: UserManagementState = new userManagementRecord() as unknown as UserManagementState;


export function  reducer(state = initialState, {type, payload}: any): UserManagementState {

  if (!type) {

    return state;
  }
  switch (type) {

// GET USER ROLE
case actions.ActionTypes.GET_USER_ROLE:
{
  return Object.assign({}, state, {
    userRoleLoading: true,
    userRoleLoaded: false,
    userRoleFailed: false,
  });
}
case actions.ActionTypes.GET_USER_ROLE_SUCCESS:
{
  return Object.assign({}, state, {
    userRole: payload.data,
    userRoleLoading: false,
    userRoleLoaded: true,
    userRoleFailed: false,
  });
}
case actions.ActionTypes.GET_USER_ROLE_FAIL:
{
  return Object.assign({}, state, {
    userRoleLoading: false,
    userRoleLoaded: false,
    userRoleFailed: true,
  });
}

// CREATE USER
case actions.ActionTypes.CREATE_USER:
{
  return Object.assign({}, state, {
    createUserLoading: true,
    createUserLoaded: false,
    createUserFailed: false,
  });
}
case actions.ActionTypes.CREATE_USER_SUCCESS:
{
  return Object.assign({}, state, {
    createUserLoading: false,
    createUserLoaded: true,
    createUserFailed: false,
  });
}
case actions.ActionTypes.CREATE_USER_FAIL:
{
  return Object.assign({}, state, {
    createUserLoading: false,
    createUserLoaded: false,
    createUserFailed: true,
  });
}

// DELETE USER
    case actions.ActionTypes.DELETE_USER:
    {
      return Object.assign({}, state, {
        deleteUserLoading: true,
        deleteUserLoaded: false,
        deleteUserFailed: false,
      });
    }
    case actions.ActionTypes.DELETE_USER_SUCCESS:
    {
      return Object.assign({}, state, {
        deleteUser: payload,
        deleteUserLoading: false,
        deleteUserLoaded: true,
        deleteUserFailed: false,
      });
    }
    case actions.ActionTypes.DELETE_USER_FAIL:
    {
      return Object.assign({}, state, {
      deleteUserLoading: false,
      deleteUserLoaded: false,
      deleteUserFailed: true,
      });
    }

// USER LIST
case actions.ActionTypes.GET_USER_LIST:
{
  return Object.assign({}, state, {
    userListLoading: true,
    userListLoaded: false,
    userListFailed: false,
  });
}
case actions.ActionTypes.GET_USER_LIST_SUCCESS:
{
  const userList = payload.data.map(data => {
  const tempuserList = new UserListResponseModel(data);
   return tempuserList;

});
  return Object.assign({}, state, {
    userList: userList,
    userListLoading: false,
    userListLoaded: true,
    userListFailed: false,
  });
}
case actions.ActionTypes.GET_USER_LIST_FAIL:
{
  return Object.assign({}, state, {
    userListLoading: false,
    userListLoaded: false,
    userListFailed: true,
  });
}
// getting user details
    case actions.ActionTypes.USER_DETAILS:
    {
      return Object.assign({}, state, {
        userDetailsLoading: true,
        userDetailsLoaded: false,
        userDetailsFailed: false,
      });
    }
    case actions.ActionTypes.USER_DETAILS_SUCCESS:
    {
      return Object.assign({}, state, {
        userDetails: payload,
        userDetailsLoading: false,
        userDetailsLoaded: true,
        userDetailsFailed: false,
      });
    }
    case actions.ActionTypes.USER_DETAILS_FAIL:
    {
      return Object.assign({}, state, {
        userDetailsLoading: false,
        userDetailsLoaded: false,
        userDetailsFailed: true,
      });
    }
    // update User Details
    case actions.ActionTypes.UPDATE_USER:
    {
      return Object.assign({}, state, {
        UserUpdateDetailsLoading: true,
        UserUpdateDetailsLoaded: false,
        UserUpdateDetailsFailed: false,
      });
    }
    case actions.ActionTypes.UPDATE_USER_SUCCESS:
    {
      return Object.assign({}, state, {
        UserUpdateDetails: payload,
        UserUpdateDetailsLoading: false,
        UserUpdateDetailsLoaded: true,
        UserUpdateDetailsFailed: false,
      });
    }
    case actions.ActionTypes.UPDATE_USER_FAIL:
    {
      return Object.assign({}, state, {
        UserUpdateDetailsLoading: false,
        UserUpdateDetailsLoaded: false,
        UserUpdateDetailsFailed: true,
      });
    }

// UNSUBSCRIBE delete
    case actions.ActionTypes.UNSUBSCRIBE_DETAILS:
    {
      return Object.assign({}, state, {
        deleteUser: undefined,
      });
    }
    default: {
      return state;
    }
  }
}

export const userRoleSuccess = (state: UserManagementState) => state.userRole;
export const userListSuccess = (state: UserManagementState) => state.userList;
export const userDetails = (state: UserManagementState) => state.userDetails;
export const userUpdateDetails = (state: UserManagementState) => state.UserUpdateDetails;
export const deleteUserSuccess = (state: UserManagementState) => state.deleteUser;

export const userListLoading = (state: UserManagementState) => state.userListLoading;
export const userListLoaded = (state: UserManagementState) => state.userListLoaded;
export const userListFailed = (state: UserManagementState) => state.userListFailed;

export const createUserLoading = (state: UserManagementState) => state.createUserLoading;
export const createUserLoaded = (state: UserManagementState) => state.createUserLoaded;
export const createUserFailed = (state: UserManagementState) => state.createUserFailed;

export const deleteUserLoading = (state: UserManagementState) => state.deleteUserLoading;
export const deleteUserLoaded = (state: UserManagementState) => state.deleteUserLoaded;
export const deleteUserFailed = (state: UserManagementState) => state.deleteUserFailed;

export const userRoleLoading = (state: UserManagementState) => state.userRoleLoading;
export const userRoleLoaded = (state: UserManagementState) => state.userRoleLoaded;
export const userRoleFailed = (state: UserManagementState) => state.userRoleFailed;

export const userDetailsLoading = (state: UserManagementState) => state.userDetailsLoading;
export const userDetailsLoaded = (state: UserManagementState) => state.userDetailsLoaded;
export const userDetailsFailed = (state: UserManagementState) => state.userDetailsFailed;

export const UserUpdateDetailsLoading = (state: UserManagementState) => state.UserUpdateDetailsLoading;
export const UserUpdateDetailsLoaded = (state: UserManagementState) => state.UserUpdateDetailsLoaded;
export const UserUpdateDetailsFailed = (state: UserManagementState) => state.UserUpdateDetailsFailed;




